import React, {Component} from "react";
import {Button, ButtonGroup, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import ApexCharts from "apexcharts";

import FileSaver from "file-saver";
import {dataURItoBlob, arrayObjectsToDisplay} from "../util";
import {dateRelativeValues} from "../../../routes/mail/report/dynamicFilter";
import {titleCase} from "../../../util/RenderUtils";

export default class TitleView extends Component {

    renderTableFilters() {
        if (!this.props.filters || Object.values(this.props.filters).length === 0) {
            return null
        }
        return Object.keys(this.props.filters).map(f => {
            const filterValue = this.props.filters[f]
            if (!filterValue) {
                return null
            }


            const mailTemplates = this.props.mailTemplates
            const tagNames = this.props.tagNames

            if (f === 'template_id') {
                const matchedTemplates = mailTemplates.length > 0 ?
                    mailTemplates.filter(
                        v => Array.isArray(filterValue) ? filterValue.indexOf(v.id) > -1 : v.id === parseInt(filterValue)
                    ) : []
                const templateName = matchedTemplates.length > 0 ?
                    (Array.isArray(filterValue) ? arrayObjectsToDisplay(matchedTemplates, 'subject_template') : matchedTemplates[0].subject_template) :
                    ''
                return templateName ? <>
                    Template{Array.isArray(filterValue) && " is any of the following"}: {templateName}<br/></> : null
            } else if (f.indexOf('tv__') === 0) {
                const tagGroupId = parseInt(f.replace('tv__', ''))
                const matchedTagNames = tagNames ? tagNames.filter(t => t.id === tagGroupId) : []
                const tagGroupName = matchedTagNames.length > 0 && matchedTagNames[0].name
                const matchedTagValues = matchedTagNames.length > 0 ?
                    matchedTagNames[0].values.filter(
                        v => Array.isArray(filterValue) ? filterValue.indexOf(v.id) > -1 : v.id === parseInt(filterValue)
                    ) :
                    []
                const tagValue = matchedTagValues.length > 0 && (
                    Array.isArray(filterValue) ? arrayObjectsToDisplay(matchedTagValues, 'value') : matchedTagValues[0].value
                )
                return <>
                    {tagGroupName}{Array.isArray(filterValue) && " is any of the following"}: {tagValue}
                    <br/>
                </>
            } else if (f === 'date_relative') {
                return <>
                    {
                        dateRelativeValues[filterValue]()
                    }
                    <br/>
                </>
            }
            return <>{titleCase(f.replace('__icontains', '').replace('_', ' '))}: {filterValue}<br/></>
        })
    }

    render() {
        return <Card>
            <CardBody>
                <CardTitle className="mb-0">
                    <Row>
                        <Col>
                            <h2>{this.props.title}</h2>
                        </Col>
                        <Col className="text-right col-auto">
                            <ButtonGroup size="sm">
                                <Button size="sm" onClick={() => {
                                    this.props.apexCharts.forEach(chart_config => {
                                        ApexCharts.exec(
                                            chart_config.id, "dataURI", {scale: 2}
                                        ).then(({imgURI, blob}) => {
                                            const file = new File(
                                                [dataURItoBlob(imgURI)],
                                                chart_config.title + ".png",
                                                {type: 'img/png'}
                                            );
                                            FileSaver.saveAs(file);
                                        });
                                    })
                                }}>
                                    <span className="fa fa-image"/> Download Charts
                                </Button>

                                <Button
                                    onClick={this.props.onEdit}>
                                    <span className="fa fa-pen"/> Edit
                                </Button>

                                {
                                    this.props.can_edit && <Button
                                        color="primary"
                                        onClick={() => {
                                            this.props.onCopy(this.props)
                                        }}>
                                        <span className="fa fa-copy"/> Duplicate
                                    </Button>
                                }

                            </ButtonGroup>

                        </Col>
                    </Row>
                </CardTitle>
                {this.renderTableFilters()}
            </CardBody>
        </Card>
    }
}