import React, { Component } from "react";
import { LineChart, Line, Tooltip, XAxis, Label, YAxis } from "recharts";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  Table,
  Button
} from "reactstrap";
const dataRPM = [
  { name: "RPM & Earnings", RPM: 5, Earnings: 0.7 },
  { name: "RPM & Earnings", RPM: 2, Earnings: 0.3 },
  { name: "RPM & Earnings", RPM: 7, Earnings: 4 },
  { name: "RPM & Earnings", RPM: 4, Earnings: 0.4 },
  { name: "RPM & Earnings", RPM: 3, Earnings: 0.7 },
  { name: "RPM & Earnings", RPM: 9, Earnings: 5 },
  { name: "RPM & Earnings", RPM: 2, Earnings: 0.6 }
];

class TinyLineChartRPM extends Component {
  state = {};
  render() {
    return (
      <LineChart width={130} height={70} data={dataRPM}>
        <Line
          type="monotone"
          dataKey="RPM"
          strokeWidth={2}
          dot={false}
          activeDot={false}
          name={NumberMask}
        />
        <XAxis dataKey="name" hide />
        <Tooltip formatter={NumberMask} />
        <Line
          type="monotone"
          dataKey="Earnings"
          stroke="#82ca9d"
          strokeWidth={2}
          dot={false}
          activeDot={false}
          name={NumberMask}
        />
      </LineChart>
    );
  }
}
const NumberMask = value => (
  <div>
    <div>
      <span className="fa fa-dollar-sign mr-1" />
      {value}
    </div>
  </div>
);

export default TinyLineChartRPM;
