// import appLocaleData from "react-intl/locale-data/es";
// import esMessages from "../locales/es_FR";

const EsLang = {
  messages: {
    // ...esMessages
  },
  locale: "es-FR",
  // data: appLocaleData
};
export default EsLang;
