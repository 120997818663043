import {setAuthTokens, applyAuthTokenInterceptor, clearAuthTokens, getAccessToken} from 'axios-jwt';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const BASE_URL = process.env.REACT_APP_API_URL
axios.defaults.baseURL = BASE_URL

export const authResponseToAuthTokens = (res) => {
    return {
        accessToken: res.access,
        refreshToken: res.refresh
    }
};

// login
export const login = async (params) => {
    const res = (await axios.post("/token/", params)).data;
    // save tokens to storage
    setAuthTokens(authResponseToAuthTokens(res));
};

const requestRefresh = (refresh) => {
    return new Promise((resolve, reject) => {
        // notice that this is the global axios instance.  <-- important
        axios.post('/token/refresh/', {
            refresh
        }).then(response => {
            if (response && response.data  && response.data.access) {
                resolve(response.data.access);
            } else {
                clearAuthTokens()
            }
        }, reject);
    });
};

export const testToken = () => {
    const profileApi = new ApiClient('profile', authenticationErrorHandler.bind(this))
    return profileApi.getItem('check')
}

const defaultAuthenticationErrorHandler = (error) => {
    return Promise.reject(error);
}

export function authenticationErrorHandler(error) {
    if (error && error.message && error.message.indexOf("Unable to refresh access token for request due to token refresh error") > -1) {
        console.log("Refresh failed")
        this.props.history.replace('/login/')
    } else if (error.response && (error.response.status === 403 || error.response.status === 401)) {
    // if (error.response && (error.response.status === 401)) {
        console.log(this.props)
        clearAuthTokens();
        this.props.history.replace('/login/')
    } else {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        return Promise.reject(error)
    }
    console.log(error.config);
}

export default class ApiClient {
    baseURL = '';
    constructor(objectName, authenticationErrorHandler) {
        const axiosSettings = {
            baseURL: BASE_URL.replace(/\/$/, "") + '/' + objectName
        }
        this.baseURL = BASE_URL.replace(/\/$/, "") + '/' + objectName;

        let apiClient = axios.create(axiosSettings);
        axiosRetry(apiClient, {retries: 3})

        if (!authenticationErrorHandler) {
            authenticationErrorHandler = defaultAuthenticationErrorHandler
        }

        apiClient.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, authenticationErrorHandler);

        applyAuthTokenInterceptor(apiClient, { requestRefresh });  // Notice that this uses the apiClient instance
        this.client = apiClient;
    }

    // GENERIC API METHODS
    get(url, conf = {}) {
        return this.client.get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    head(url, conf = {}) {
        if (!url) {
            url = '/'
        }
        return this.client.head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    options(conf = {}) {
        return this.client.options('/', conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    post(url, data = {}, conf = {}) {
        return this.client.post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    delete(url, conf = {}) {
        return this.client.delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }


    put(url, data = {}, conf = {}) {
        return this.client.put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    patch(url, data = {}, conf = {}) {
        return this.client.patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }


    list(conf= {}) {
        return this.get('/', conf);
    }

    getItem(id, conf = {}) {
        return this.get('/' + id + '/', conf);
    }

    deleteItem(id, conf = {}) {
        return this.delete('/' + id + '/', conf)
    }

    create(data = {}, conf = {}) {
        return this.client.post('/', data, conf);
    }

    updateItem(id, data = {}, conf = {}) {
        return this.put('/' + id + '/', data, conf)
    }

    patchItem(id, data = {}, conf = {}) {
        return this.patch('/' + id + '/', data, conf);
    }

    getItemAction(id, action, conf = {}) {
        return this.get('/' + id + '/' + action + '/', conf);
    }

    postItemAction(id, action, data, conf = {}) {
        return this.post('/' + id + '/' + action + '/', data, conf);
    }
    patchItemAction(id, action, data = {}, conf = {}) {
        return this.patch('/' + id + '/' + action + '/', data, conf);
    }
}
