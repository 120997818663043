import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle, Col, FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PointsFetch from "./mobileMonkeyPreview/pointsFetch";
import Loading from "../../components/loading";
import Switch from "rc-switch";


const canShowApiHook = (hookTypes, showEmail, showPromo) => {
    let show = true;
    if (!showEmail && hookTypes.indexOf('mail') > -1) {
        show = false;
    }
    if (!showPromo && hookTypes.indexOf('promo') > -1) {
        show = false
    }

    return show;
}


export default class ApiHookList extends Component {
    state = {
        apiHooks: [],
        isLoadingApiHooks: true,
        showEmails: true,
        showPromos: true,
        searchText: ''
    }

    apiHooksApi = new ApiClient('webhook', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    componentDidMount() {
        this.apiHooksApi.list().then(response => {
            const responseData = response.data
            this.setState({
                apiHooks: [...responseData],
                isLoadingApiHooks: false
            })
        })

    }

    renderApiHookList() {
        if (this.state.isLoadingApiHooks) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }

        if (!this.state.apiHooks || this.state.apiHooks.length <= 0) {
            return <Row>
                <Colxx className="text-center">
                    No API Hooks yet. Create one now!
                </Colxx>
            </Row>
        }

        const data = this.state.apiHooks
            .filter(row => canShowApiHook(row.hook_types, this.state.showEmails, this.state.showPromos))
            .filter(row => row.uid.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1
                || row.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1)

        if (data.length <= 0) {
            return <Row>
                <Colxx className="text-center">
                    No API hooks visible. Check the filters.
                </Colxx>
            </Row>
        }

        return data.map(apiHook => <>
                <Row key={"apiHook" + apiHook.uid}>

                    <Colxx className="p-0 my-auto text-center" lg={1} md={2} sm={2} xs={2}>
                        <ButtonGroup size="xs">
                            <Button color="primary" onClick={() => {
                                this.history.push('/app/apiHooks/' + apiHook.uid + '/')
                            }}>
                                <span className="fa fa-pen"/><br/>
                                edit
                            </Button>
                        </ButtonGroup>
                    </Colxx>
                    <Colxx className="my-auto" lg={5} md={10} sm={10} xs={2}>
                        <strong>{apiHook.name}</strong>
                        <br/>
                        {apiHook.hook_types.indexOf('mail') > -1 && <Badge color="info"><span className="fa fa-envelope"/> Mail</Badge>}{" "}
                        {apiHook.hook_types.indexOf('promo') > -1 && <Badge color="info"><span className="fa fa-trophy"/> Promo</Badge>}{" "}
                        {!!apiHook.tracking_parameter.length && <Badge color="info"><span className="fa fa-shoe-prints"/> Tracker</Badge>}{" "}
                        {
                            apiHook.points_fetch &&
                            <p className="text-small">* All Promo URLs require a mapping of <pre>
                                                <code>recipient_id</code> &lt;- <code>recipient id</code><br/>
                                            </pre></p>
                        }
                    </Colxx>
                    <Colxx className="my-auto" lg={6} md={12} sm={12}>

                        <Label>Receiver URL</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText className="text-small">POST</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text" readOnly={true} value={apiHook.receive_url}
                                size="lg" style={{textOverflow: "ellipsis"}}/>
                            <InputGroupAddon addonType="append">
                                <Button
                                    color="success" size="xs"
                                    onClick={() => {
                                        navigator.clipboard.writeText(apiHook.receive_url);
                                        toast("Receiver URL copied to clipboard")
                                    }}>
                                    <span className="fa fa-clipboard"/> copy
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                        { apiHook.points_fetch && <>
                            <br/>
                            <Label>Points Fetch URL - saving to <code>{apiHook.points_fetch.key}</code> attribute</Label>
                            <InputGroup>
                                <InputGroupText className="text-small">GET</InputGroupText>
                                <Input
                                    type="text" readOnly={true} value={apiHook.points_fetch.url}
                                    size="lg" style={{textOverflow: "ellipsis"}}/>
                                <InputGroupAddon addonType="append">
                                    <Button
                                        color="success" size="xs"
                                        onClick={() => {
                                            navigator.clipboard.writeText(apiHook.points_fetch.url);
                                            toast("Points Fetching URL copied to clipboard")
                                        }}>
                                        <span className="fa fa-clipboard"/> copy
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </>}


                        { apiHook.dispute && <>
                            <br/>
                            <Label>Dispute URL</Label>
                            <InputGroup>
                                <InputGroupText className="text-small">POST</InputGroupText>
                                <Input
                                    type="text" readOnly={true} value={apiHook.dispute.url}
                                    size="lg" style={{textOverflow: "ellipsis"}}/>
                                <InputGroupAddon addonType="append">
                                    <Button
                                        color="success" size="xs"
                                        onClick={() => {
                                            navigator.clipboard.writeText(apiHook.dispute.url);
                                            toast("Dispute URL copied to clipboard")
                                        }}>
                                        <span className="fa fa-clipboard"/> copy
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </>}
                    </Colxx>
                </Row>
                <hr/>

            </>)

    }

    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card className="mb-4">
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col>
                                    <h2>API Hooks</h2>
                                </Col>
                                <Col md={12} lg={8}>

                                    <div className="float-right form-inline">
                                        <span className="mr-2"> Show:</span>
                                        <FormGroup className="mr-2 my-0">
                                            <Switch
                                                className="mr-2 my-0"
                                                checked={this.state.showEmails}
                                                onChange={() => {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        showEmails: !prevState.showEmails
                                                    }))
                                                }}
                                                // checkedChildren={<span className="fa fa-envelope"/> }
                                                // unCheckedChildren={
                                                //
                                                //     <span className="fa-stack" style={{verticalAlign: "top", fontSize: "0.8em"}}>
                                                //         <i className="fa fa-envelope fa-stack-1x"/>
                                                //         <i className="fa fa-ban fa-stack-2x"/>
                                                //     </span>
                                                // }
                                            />
                                            <Label>Email</Label>
                                        </FormGroup>

                                        <FormGroup className="mr-4">
                                            <Switch
                                                className="mr-2"
                                                checked={this.state.showPromos}
                                                onChange={() => {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        showPromos: !prevState.showPromos
                                                    }))
                                                }}
                                            />
                                            <Label>Promo</Label>
                                        </FormGroup>


                                        <FormGroup className="mr-4">
                                            <Label>Search</Label>{" "}
                                            <Input
                                                type="text"
                                                placeholder="search here"
                                                value={this.state.searchText}
                                                onChange={e => this.setState({searchText: e.target.value})}
                                                className="mx-2"/>
                                        </FormGroup>


                                        <Button size="sm"  onClick={() => {
                                            this.history.push('/app/apiHooks/new/')
                                        }}>
                                            <span className="fa fa-plus"/> New API Hook
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardTitle>

                        {this.renderApiHookList()}
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }

}