import { ReactifyChartColors } from "../util/ReactifyChartColors";
import { chartTooltip } from "../components/Charts";

const colors = ReactifyChartColors();

export const chartjsBars = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};

export const lineChartConfig = {
  legend: {
    display: true
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: chartTooltip,
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: "rgba(0,0,0,0.1)",
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 5,
            min: 50,
            max: 70,
            padding: 20
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    }
  },
  data: {
    labels: [
      "2018-12-16",
      "2018-12-17",
      "2018-12-18",
      "2018-12-19",
      "2018-12-20",
      "2018-12-21",
      "2018-12-22"
    ],
    datasets: [
      {
        label: "Traffic",
        data: [54, 63, 60, 65, 60, 68, 60],
        borderColor: colors.ReactifyChartColor1,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.ReactifyChartColor1,
        pointHoverBackgroundColor: colors.ReactifyChartColor1,
        pointRadius: 6,
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        fill: true,
        displayLegend: true
      },
      {
        label: "Sales",
        data: [50, 53, 55, 60, 51, 51, 50],
        borderColor: colors.ReactifyChartColor2,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.ReactifyChartColor2,
        pointHoverBackgroundColor: colors.ReactifyChartColor2,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 6,
        pointBorderWidth: 2,
        pointHoverRadius: 8,
        fill: true
      }
    ]
  }
};

// Barchart Starts Here
export const barChartConfigSingle = {
  legend: {
    display: false
  },

  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          display: false
        }
      ],
      xAxes: [
        {
          display: false
        }
      ]
    },
  },
  data: {
    labels: [
      "Sales",
      "Sales",
      "Sales",
      "Sales",
      "Sales",
      "Sales",
      "Sales",
      "Sales",
      "Sales",
      "Sales"
    ],
    datasets: [
      {
        label: "Sales",
        data: [24, 92, 77, 90, 91, 78, 28, 49, 23, 81],
        backgroundColor: colors.foregroundColor,
        borderColor: colors.ReactifyChartColor1,
        borderWidth: 2
      }
    ]
  }
};

// BarChart Ends Here
// Ticketing Charts

export const ticketsSatusChart = {
  legend: {
    display: false
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: chartTooltip,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: false,
            stepSize: 5,
            min: 50,
            max: 70,
            padding: 20,
            display: false
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false
          }
        }
      ]
    }
  },
  data: {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    datasets: [
      {
        label: "Tickets",
        data: [50, 55, 65, 55, 52, 57, 60],
        borderColor: colors.ReactifyChartColor1,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.ReactifyChartColor1,
        pointHoverBackgroundColor: colors.ReactifyChartColor1,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        fill: true,
        borderWidth: 2,
        backgroundColor: colors.themeColor2_10
      }
    ]
  }
};
