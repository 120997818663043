import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  Table,
  Button
} from "reactstrap";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import "chartjs-plugin-datalabels";
import "react-circular-progressbar/dist/styles.css";
import recentComments from "../../../data/recentComments.json";
import popularPosts from "../../../data/popularPosts.json";

import { Sparklines, SparklinesLine, SparklinesBars } from "react-sparklines";
import subscribers from "../../../data/subcribers.json";

const sparklineData = [936, 968, 1025, 999, 998, 1014, 1017, 1010, 1010, 1007];
const sparklineSubs = [936, 968, 936, 930, 998, 1014, 1014, 1010, 900, 1007];

const recentComment = recentComments.data;
const subscriber = subscribers.data;
const popularPost = popularPosts.data;

export default class bloggingDashboard extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.blogging" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          {/* Blogging First Row starts here */}
          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card>
              <CardBody>
                <Sparklines data={sparklineData} margin={6}>
                  <SparklinesLine
                    style={{
                      strokeWidth: 3,
                      stroke: "#922c88",
                      fill: "none"
                    }}
                  />
                </Sparklines>
                <div className="ml-3">
                  <IntlMessages id="blogging.monthly-visits" />
                  <h3>134,908</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card>
              <CardBody>
                <Sparklines data={sparklineSubs} margin={6}>
                  <SparklinesLine
                    style={{
                      strokeWidth: 3,
                      stroke: "#922c88",
                      fill: "none"
                    }}
                  />
                </Sparklines>
                <div className="ml-3">
                  <IntlMessages id="blogging.subscribers" />
                  <h3>687,123</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card>
              <CardBody>
                <span className="far fa-clock ml-3 font40px text-danger mb-2" />
                <div className="ml-3">
                  <IntlMessages id="blogging.monthly-visits" />
                  <h3>134908</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card className="bg-background-color boxshadow-0">
              <div>
                <Button className="text-uppercase btn-lg">
                  <span className="fa fa-edit" /> Create Post
                </Button>
              </div>
            </Card>
          </Colxx>
          {/* Blogging First Row ends here */}

          {/* Chart starts here */}
          <Colxx sm="12" md="12" lg="12" xl="4" className="mb-4">
            <Card>
              <CardBody>
                <div>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.comments" />

                      <strong className="float-right">
                        <span className="fa fa-comments font-color-1" /> 19235
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.approved" />
                      <strong className="float-right">
                        {" "}
                        <span className="fa fa-check font-color-4" /> 9235
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.pending" />
                      <strong className="float-right">
                        {" "}
                        <span className="far fa-clock font-color-2" /> 1035
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.spam" />
                      <strong className="float-right">
                        {" "}
                        <span className="fa fa-exclamation-triangle font-color-6" />{" "}
                        47
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.posts" />
                      <strong className="float-right">
                        {" "}
                        <span className="fa fa-edit font-color-3" /> 1235
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.categories" />
                      <strong className="float-right">
                        {" "}
                        <span className="far fa-folder font-color-5" /> 735
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.pages" />
                      <strong className="float-right">
                        {" "}
                        <span className="far fa-file-alt font-color-1" /> 35
                      </strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="blogging.tags" />
                      <strong className="float-right">
                        {" "}
                        <span className="fa fa-tag font-color-2" /> 347
                      </strong>
                    </p>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Colxx>
          {/* Chart Ends here */}

          {/* Team messages start here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Card className="pt-4">
              <CardBody>
                <div>
                  <a href="#">
                    <p>
                      <span className="fab fa-twitter mr-2 twitter-color" />{" "}
                      <IntlMessages id="blogging.twitter" />
                      <strong className="float-right">1876</strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <span className="fab fa-facebook-f mr-2 font-color-2" />{" "}
                      <IntlMessages id="blogging.facebook" />
                      <strong className="float-right">776</strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <span className="fab fa-pinterest-p mr-2 font-color-5" />{" "}
                      <IntlMessages id="blogging.pinterest" />
                      <strong className="float-right">987</strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <span className="fa fa-rss mr-2 font-color-1" />{" "}
                      <IntlMessages id="blogging.rss" />
                      <strong className="float-right">501</strong>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <span className="fa fa-mail-bulk mr-2 font-color-3" />{" "}
                      <IntlMessages id="blogging.email" />
                      <strong className="float-right">2876</strong>
                    </p>
                  </a>
                  <div className="text-center">
                    <h3>
                      <IntlMessages id="blogging.traffic-source" />
                    </h3>
                    <Row className="justify-content-center">
                      {subscriber.map((subs, index) => {
                        return (
                          <div key={index}>
                            <img
                              className="w-20px mr-1 my-1"
                              src={`${subs.avatar}`}
                              id={`${subs.target}`}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`${subs.target}`}
                            >
                              {subs.name}
                            </UncontrolledTooltip>
                          </div>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Colxx>
          {/* Team Messages End here  */}

          {/* Team messages start here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Card className="pt-4 justify-content-center">
              <CardBody>
                <Table borderless>
                  <thead>
                    <tr>
                      <th className="text-uppercase">sites</th>
                      <th className="text-uppercase">visits</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <span className="fab fa-google mr-1 font-color-3" />{" "}
                          Google
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-danger">-8</sup>{" "}
                        <span>12,543</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            style={{
                              height: "calc(30% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesBars
                              style={{
                                stroke: "white",
                                strokeWidth: "1",
                                fill: "#922c88"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <img
                            className="w-15px mr-1"
                            src="/assets/img/bing.png"
                          />{" "}
                          Bing
                        </NavLink>
                      </th>

                      <td>
                        {" "}
                        <sup className="text-success">+5</sup>{" "}
                        <span>11,963</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                            height={20}
                          >
                            <SparklinesLine
                              style={{
                                stroke: "white",
                                strokeWidth: "1",
                                fill: "#922c88"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <img
                            className="w-15px mr-1"
                            src="/assets/img/envato.png"
                          />{" "}
                          Envato
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-success">+10</sup>{" "}
                        <span>9,965</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            margin={6}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesLine
                              style={{
                                strokeWidth: 3,
                                stroke: "#922c88",
                                fill: "none"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <span className="fab fa-facebook-f mr-1 font-color-2" />{" "}
                          Facebook
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-success">+7</sup>{" "}
                        <span>4,223</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            margin={6}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesLine
                              style={{
                                strokeWidth: 3,
                                stroke: "#922c88",
                                fill: "none"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <span className="fab fa-yahoo mr-1 font-color-1" />{" "}
                          Yahoo
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-danger">-3</sup> <span>3,437</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            margin={6}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesLine
                              style={{
                                strokeWidth: 3,
                                stroke: "#922c88",
                                fill: "none"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
          {/* Team Messages End here  */}
        </Row>

        <Row>
          <Colxx xxs="12" md="12" lg="6" xl="6" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="blogging.recent-comments" />
                </CardTitle>
                {recentComment.map((comment, index) => {
                  return (
                    <div key={index}>
                      {/* Message Dropdown starts here */}
                      <div className="float-right">
                        <UncontrolledDropdown>
                          <DropdownToggle color="empty">
                            <i className="fa fa-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu
                            className="position-absolute mt-3 scroll dropdown-2"
                            right
                            id="notificationDropdownMessage"
                          >
                            <div className="d-flex flex-row mb-3">
                              <div className="pl-3 pr-2 pt-3">
                                <p>
                                  {" "}
                                  <a className="py-3" href="#">
                                    <strong>
                                      <span className="fa fa-edit font-color-1 mr-1" />{" "}
                                      <IntlMessages id={`${comment.edit}`} />
                                    </strong>
                                  </a>
                                </p>
                                <p>
                                  {" "}
                                  <a className="py-3" href="#">
                                    <strong>
                                      <span className="far fa-window-close text-danger mr-1" />{" "}
                                      <IntlMessages id={`${comment.delete}`} />
                                    </strong>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="mt-2 py-3 px-3 d-flex">
                        <span className={`${comment.icon} mr-2 mt-1`} />
                        <span>
                          <strong>
                            {" "}
                            <a href="#">{comment.comment}</a>
                          </strong>
                          <p className="text-muted">
                            <IntlMessages id={`${comment.posted}`} />{" "}
                            {comment.date}{" "}
                          </p>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Colxx>
          <Colxx xxs="12" md="12" lg="6" xl="6" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="blogging.popular-posts" />
                </CardTitle>
                {popularPost.map((popular, index) => {
                  return (
                    <div key={index}>
                      {/* Message Dropdown starts here */}
                      <div className="float-right">
                        <UncontrolledDropdown>
                          <DropdownToggle color="empty">
                            <i className="fa fa-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu
                            className="position-absolute mt-3 scroll dropdown-2"
                            right
                            id="notificationDropdownMessage"
                          >
                            <div className="d-flex flex-row mb-3">
                              <div className="pl-3 pr-2 pt-3">
                                <p>
                                  {" "}
                                  <a className="py-3" href="#">
                                    <strong>
                                      <span className="fa fa-edit font-color-1 mr-1" />{" "}
                                      <IntlMessages id={`${popular.edit}`} />
                                    </strong>
                                  </a>
                                </p>
                                <p>
                                  {" "}
                                  <a className="py-3" href="#">
                                    <strong>
                                      <span className="far fa-window-close text-danger mr-1" />{" "}
                                      <IntlMessages id={`${popular.delete}`} />
                                    </strong>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="mt-2 py-3 px-3 d-flex">
                        <span className={`${popular.icon} mr-2 mt-1`} />
                        <span>
                          <strong>
                            {" "}
                            <a href="#">{popular.comment}</a>
                          </strong>
                          <p className="text-muted">
                            <span className="fa fa-eye mr-1" />
                            {popular.viewsCount}
                            <IntlMessages id={`${popular.views}`} />{" "}
                            <span className="fa fa-comments mx-1" />
                            {popular.commentsCount}{" "}
                            <IntlMessages id={`${popular.comments}`} />
                          </p>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
