import React, {Component} from "react";
import {dateFixedOption, dateRelativeOption, DynamicFilter} from "./dynamicFilter";
import {Button, Col, Row} from "reactstrap";

export class DynamicFilterSet extends Component {
    render() {
        const tagNames = this.props.tagNames ? this.props.tagNames : []
        const filterOptions = [
            ...(!this.props.hideEmailFilters ? [
                {value: "template_id", label: "Template", filterType: "template", group: "System"},
                {value: "sender__icontains", label: "Sender", filterType: "search", group: "System"},
                {value: "recipients__toaddresses__icontains", label: "To Recipient", filterType: "search", group: "System"},
                {value: "recipients__caddresses__icontains", label: "CC Recipient", filterType: "search", group: "System"},
                {value: "subject__icontains", label: "Sent Email Subject", filterType: "search", group: "System"},
            ]: []),
            dateRelativeOption,
            dateFixedOption,
            ...tagNames.map(t => {
                return {
                    value: "tv__" + t.id + "",
                    label: t.name,
                    tagId: t.id,
                    filterType: 'tag',
                    group: "Tag"
                }
            }),
            ...this.props.indexes.map(i => {
                return {
                    value: "i__" + i + "__icontains",
                    label: i,
                    filterType: 'search',
                    group: "Data Received (Warning! Raw data from hook)"
                }
            })
        ]

        const selectedFilterKeys = Object.keys(this.props.filters)
        const selectedFilterOptions = filterOptions.filter(o => selectedFilterKeys.indexOf(o.value) > -1)
        const availableFilterOptions = filterOptions.filter(o => selectedFilterKeys.indexOf(o.value) === -1).filter(o => {
            return !(o.value === 'date_relative' && selectedFilterKeys.indexOf('date_fixed') > -1) && !(o.value === 'date_fixed' && selectedFilterKeys.indexOf('date_relative') > -1)
        })

        const filtersRender = selectedFilterOptions.length === 0 ? <Row>
            <Col className="text-center text-muted">
                No filters
            </Col>
        </Row> : selectedFilterOptions.map(
            o => <DynamicFilter
                mailTemplates={this.props.mailTemplates}
                tagNames={this.props.tagNames}
                selectedFilter={o}
                filterValue={this.props.filters[o.value]}
                availableFilters={availableFilterOptions}
                onFilterChange={newOption => {
                    const newFilters = {
                        ...this.props.filters,
                        [newOption.value]: null
                    }
                    delete newFilters[o.value]
                    this.props.onConfigChange({
                        filters: newFilters
                    })
                }}
                onFilterValueChange={newValue => {
                    const newFilters = {
                        ...this.props.filters,
                        [o.value]: newValue
                    }
                    this.props.onConfigChange({
                        filters: newFilters
                    })
                }}
                onDelete={() => {
                    const newFilters = {
                        ...this.props.filters
                    }
                    delete newFilters[o.value]
                    this.props.onConfigChange({
                        filters: newFilters
                    })
                }}
            />
        )

        return <Row className="mt-4">
            <Col>
                <Row>
                    <Col>
                        <h4>Filters</h4>
                        {
                            this.props.showWarning && <p className="text-muted">
                                If you are using the same filters in this chart and in this dashboard, chart
                                level filter values will be prioritized.
                            </p>
                        }
                    </Col>
                    <Col className="text-right" lg={3}>

                        <Button disabled={availableFilterOptions.length <= 0} size="sm" onClick={() => {
                            this.props.onConfigChange({
                                filters: {
                                    ...this.props.filters,
                                    [availableFilterOptions[0].value]: null
                                }
                            })
                        }}>
                            Add Filter
                        </Button>
                    </Col>
                </Row>

                {filtersRender}

            </Col>
        </Row>
    }
}