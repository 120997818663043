import React from "react";
import { Alert } from "reactstrap";
class GetWeatherInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }
  render() {
    return (
      <div>
        {this.props.city && this.props.country && (
          <p className=" border-bottom font-weight-medium mt-5 mb-3">
            <span className="fa fa-map-marker-alt font-color-1" /> Location :{" "}
            {this.props.city},{this.props.country}
          </p>
        )}
        {this.props.temp && (
          <p className=" border-bottom font-weight-medium mb-3">
            <span className="fa fa-temperature-high font-color-1" />{" "}
            Temperature: {this.props.temp}
          </p>
        )}
        {this.props.humidity && (
          <p className=" border-bottom font-weight-medium mb-3">
            <span className="fa fa-water font-color-1" /> Humidity:{" "}
            {this.props.humidity}
          </p>
        )}
        {this.props.description && (
          <p className=" border-bottom font-weight-medium mb-3">
            <span className="fa fa-umbrella font-color-1" /> Conditions:{" "}
            {this.props.description}
          </p>
        )}
        {this.props.error && (
          <Alert
            className="mt-2"
            color="danger"
            isOpen={this.state.visible}
            toggle={this.onDismiss}
          >
            {this.props.error}
          </Alert>
        )}
      </div>
    );
  }
}
export default GetWeatherInfo;
