import React, {Component} from "react";
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {toast} from "react-toastify";

export default class PromoEditNavigation extends Component {
    state = {
        navigateTo: '',
        showConfirm: false,
        isCreatingWebhook: false,
    }

    constructor(props) {
        super(props);
        this.api = new ApiClient('promo', authenticationErrorHandler.bind(this))

    }

    navigateTo(path) {
        if (this.props.hasUnsavedChanges) {
            this.setState({
                navigateTo: path,
                showConfirm: true
            })
        } else {
            this.setState({
                navigateTo: path,
                showConfirm: false
            }, () => {
                this.doNavigate()
            })
        }
    }

    doNavigate() {
        if (this.state.navigateTo === 'create-webhook') {
            this.setState({isCreatingWebhook: true}, () => {
                this.api.postItemAction(this.props.data.id, 'create_webhook').then(response => {
                    toast.success(<>Created Chatbot connection for promo <strong>{this.props.data.title}</strong> </>)
                    this.props.history.push('/app/apiHooks/' + response.data.webhook_uid + '/')
                }).catch(() => {
                    toast.error(<>Something went wrong while creating a Chatbot connection. If problem persists, contact
                        administrator.</>)
                })
            })
        } else {
            this.props.history.push(this.state.navigateTo)
        }

    }

    renderWebhookButtons() {
        if (this.props.data.webhook_uid) {
            return <>
                {
                    this.props.data.permission.can_view_webhook && <Button color="secondary" onClick={() => {
                        this.navigateTo('/app/apiHooks/' + this.props.data.webhook_uid + '/')
                    }}>
                        <span className="fa fa-project-diagram"/> <small>View Chatbot Connection</small>
                    </Button>
                }

                {
                    this.props.currentPage !== 'edit-mapping' && <Button color="secondary" onClick={() => {
                        this.navigateTo('/app/promo/' + this.props.data.id + '/entryMapping/')
                    }} title={"Entry Property Mapping"}>
                        <span className="fa fa-map-signs"/> <small>ChatBot Property Mapping</small>
                    </Button>
                }
            </>
        }
        return this.props.data.permission.can_view_webhook && <Button color="secondary" disabled={this.state.isCreatingWebhook} onClick={() => {
            this.navigateTo('create-webhook')
        }}>
            <span className="fa fa-project-diagram"/>{" "}
            <small>{this.state.isCreatingWebhook ? "Creating connection..." : "Add Chatbot Connection"}</small>
        </Button>
    }

    render() {
        if (!this.props.data.id) {
            return null
        }

        return <>
            <ButtonGroup size="xs">

                <Button outline onClick={() => this.navigateTo('/app/promo/list/')}>
                    <span className="fa fa-stream"/> <small>Go Back to Promo List</small>
                </Button>
                {
                    this.props.currentPage !== 'edit-promo' && <Button onClick={() => {
                        this.navigateTo('/app/promo/' + this.props.data.id + '/')
                    }}>
                        <span className="fa fa-cog"/> <small>Promo Config</small>
                    </Button>
                }

                {
                    this.props.currentPage !== 'edit-code' && <Button className="" color="secondary" onClick={() => {
                        this.navigateTo('/app/promo/' + this.props.data.id + '/codeDefinition/')
                    }} title="Code Settings">
                        <span className="fa fa-qrcode"/> <small>Code Settings</small>
                    </Button>
                }
                <Button className="" color="secondary" onClick={() => {
                    this.navigateTo('/app/promo/' + this.props.data.id + '/disburse/')
                }} title="Code Settings">
                    <span className="fa fa-plus"/> <small>Disburse Codes</small>
                </Button>


                {
                    this.renderWebhookButtons()
                }

            </ButtonGroup>

            <Modal isOpen={this.state.showConfirm} toggle={() => {
                this.setState({showConfirm: false})
            }}>
                <ModalBody>
                    You have unsaved changes. Please save before navigating away.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" size="sm" onClick={() => {
                        this.doNavigate()
                    }}>
                        Leave anyway
                    </Button>
                    <Button color="info" size="sm" onClick={() => {
                        this.setState({showConfirm: false})
                    }}>
                        Continue Editing
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    }
}