import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    CustomInput,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import "../../assets/css/bootstrap.extra.css";
import EntryDetail from "./entryDetail";
import moment from "moment";
import Loading from "../../components/loading";
import {toast} from "react-toastify";
import DownloadToast from "../../components/EntryDownload/DownloadToast";
import DownloadsModalContent from "../../components/EntryDownload/DownloadsModalContent";
import {EntryFilter} from "./entryFilter";
import TablePagination from "../../components/tablePagination";
import ImgLoading from "../../components/imgLoading";

const COL_TO_PROPERTY = {
    // 'ID': 'id',
    'Name': 'name',
    'Email Address': 'email_address',
    'Mobile Number': 'mobile_number',
    // 'Profile ID': 'profile_identifier',
    'Code': 'raw_code',
    'Entry Date': 'datetime_registered'
}


class EntryList extends Component {
    entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
    promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))

    state = {
        entries: [],
        limit: 20,
        page: 1,
        hasPrevious: false,
        hasNext: false,
        totalCount: 0,
        filters: {
            // has_attachment_only: false,
            include_invalid: false,
            search: ""
        },
        filterOptions: {},

        promoTitle: '',

        sort: '',

        modalEntry: null,
        modalImage: null,
        isLoadingEntries: true,
        showDownloads: false,

        generateDownloadEnabled: true
    }

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    fetchPromoName(promoID) {
        if (promoID) {
            this.promoApi.getItem(promoID).then(response => {
                this.setState({promoTitle: response.data.title})
            })
        } else {
            this.setState({promoTitle: ""})
        }
    }

    fetchEntries() {
        const params = this.buildParams()
        this.setState({isLoadingEntries: true}, () => {
            this.entryApi.list({params: params}).then(response => {
                this.setState({
                    hasNext: !!response.data.next,
                    hasPrevious: !!response.data.previous,
                    entries: response.data.results,
                    totalCount: response.data.count,
                    isLoadingEntries: false
                })
            })
        })
    }

    buildParams() {
        const params = {
            limit: this.state.limit,
            offset: (this.state.page - 1) * this.state.limit,
            ...this.state.filters
        }

        const {match} = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            params["promo_id"] = promoID
        }
        if (this.state.sort) {
            params['sort'] = this.state.sort
        }

        return params
    }

    fetchFilters() {
        const {match} = this.props;
        const promoID = match.params.promoID

        const params = {}
        if (promoID) {
            params["promo_id"] = promoID
        }

        this.entryApi.getItem('filters', {params: params}).then(response => {
            this.setState({filterOptions: response.data})
        })
    }

    loadPromoName() {
        const {match} = this.props;
        const promoID = match.params.promoID

        this.fetchPromoName(promoID)
    }

    componentDidMount() {
        this.fetchEntries()
        this.fetchFilters()
        this.loadPromoName()
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    goToPage(pageNumber) {
        console.log("Going to ", pageNumber)
        this.setState({page: pageNumber}, () => {
            this.fetchEntries()
        })
    }

    toggleSort(fieldName) {
        this.setState(prevState => {
            let sortValue = ''
            if (prevState.sort === fieldName) {
                sortValue = '-' + fieldName
            } else if (prevState.sort === '-' + fieldName) {
                sortValue = ''
            } else {
                sortValue = fieldName
            }
            return {
                ...prevState,
                sort: sortValue
            }
        }, () => {
            this.fetchEntries()
        })
    }

    toggleModalEntry() {
        this.setState({modalEntry: null})
    }

    toggleModalImage() {
        this.setState({modalImage: null})
    }

    toggleShowDownloads() {
        this.setState(prevState => ({...prevState, showDownloads: !prevState.showDownloads}))
    }

    generateDownload() {
        this.setState({generateDownloadEnabled: false}, () => {
            this.entryApi.post('download/', this.buildParams()).then(response => {
                toast(<DownloadToast downloadId={response.data.id}/>, {
                    autoClose: false
                });
                this.setState({generateDownloadEnabled: true})
            })
        })
    }

    applyFilters(filterValues) {
        this.setState({filters: filterValues}, () => {
            this.fetchEntries()
        })
    }

    renderFilters() {
        const {match} = this.props;
        const promoID = match.params.promoID

        return <Row className="mb-4 border-1px py-3">
            <Colxx>
                <Row>
                    <Colxx>
                        <h4>
                            Filters
                        </h4>
                    </Colxx>
                </Row>
                <EntryFilter
                    showInvalidEntryToggle={true}
                    promoID={promoID}
                    applyHandler={this.applyFilters.bind(this)}
                    history={this.props.history}
                />
            </Colxx>
        </Row>
    }

    renderModalContent() {
        if (!this.state.modalEntry) {
            return <>Loading</>
        }
        return <>

            <ModalHeader toggle={this.toggleModalEntry.bind(this)}>
                Entry ID {this.state.modalEntry.id}
            </ModalHeader>
            <ModalBody>
                <EntryDetail entry={this.state.modalEntry}/>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.toggleModalEntry.bind(this)}>
                    Close
                </Button>
            </ModalFooter>
        </>
    }

    renderModalImageContent() {
        if (!this.state.modalImage) {
            return <>Loading</>
        }

        return <>
            <ModalHeader toggle={this.toggleModalImage.bind(this)}>
                Entry Image
            </ModalHeader>
            <ModalBody>
                <ImgLoading src={this.state.modalImage.file}/>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.toggleModalImage.bind(this)}>
                    Close
                </Button>
            </ModalFooter>
        </>
    }

    renderTable() {
        if (this.state.isLoadingEntries) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }
        return <>
            <Row>
                <Colxx style={{overflow: "scroll"}}>
                    <Table hover striped>
                        <thead>
                        <tr>
                            <th></th>
                            {Object.keys(COL_TO_PROPERTY).map(
                                k => <th key={"header-" + k} onClick={this.toggleSort.bind(this, COL_TO_PROPERTY[k])}>
                                    {k}{" "}
                                    {COL_TO_PROPERTY[k] === this.state.sort ? <span className="fa fa-caret-up"/> : ''}
                                    {"-" + COL_TO_PROPERTY[k] === this.state.sort ?
                                        <span className="fa fa-caret-down"/> : ''}
                                </th>
                            )}
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.entries.map(entry => {
                                return <tr
                                    key={"entry-" + entry.id}
                                    className={entry.is_active ? '' : 'text-danger font-italic'}
                                    style={{cursor: entry.is_active ? '' : 'not-allowed'}}
                                    title={!entry.is_active && (entry.entry_errors ? entry.entry_errors : "Invalid entry.")}
                                >
                                    <td>
                                        {entry.id} {!entry.is_active && <><br/>invalid</>}
                                    </td>
                                    {
                                        Object.values(COL_TO_PROPERTY).map(
                                            k => <td
                                                key={"entry-" + entry.id + "-" + k}
                                                title={k === 'datetime_registered' && moment(entry[k]).format('LLLL')}>
                                                {k === 'datetime_registered' ? moment(entry[k]).fromNow() : entry[k]}
                                            </td>
                                        )
                                    }
                                    <td>
                                        <ButtonGroup>
                                            <Button size="xs" outline onClick={
                                                () => {
                                                    this.setState({
                                                        modalImage: entry.attachments[0]
                                                    })
                                                }
                                            } disabled={entry.attachments.length <= 0}>
                                                <span className="fa fa-image"/><br/>
                                                img
                                            </Button>
                                            <Button size="xs" outline onClick={
                                                () => {
                                                    this.setState({
                                                        modalEntry: JSON.parse(JSON.stringify(entry))
                                                    })
                                                }
                                            }>
                                                <span className="fa fa-eye"/><br/>
                                                view
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </Colxx>
            </Row>
        </>
    }

    render() {
        const {match} = this.props;
        const promoID = match.params.promoID
        return <>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h1>{this.state.promoTitle} Entries</h1>
                            </CardTitle>

                            <EntryFilter
                                showInvalidEntryToggle={true}
                                promoID={promoID}
                                applyHandler={this.applyFilters.bind(this)}
                                history={this.props.history}
                            />
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>

            <Row className="pb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Colxx className="text-right">

                                    <span className="pr-5">Entry Count: {this.state.totalCount}</span>
                                    <Button size="sm" onClick={() => {
                                        this.generateDownload()
                                    }} disabled={!this.state.generateDownloadEnabled}>Generate Download</Button>
                                    <Button size="sm" color="primary" onClick={() => {
                                        this.toggleShowDownloads()
                                    }}>View All Downloads</Button>
                                </Colxx>
                            </Row>
                            <Row>
                                <Colxx xxs="12" className="text-center">
                                    <TablePagination
                                        hasNext={this.state.hasNext}
                                        hasPrevious={this.state.hasPrevious}
                                        totalCount={this.state.totalCount}
                                        limit={this.state.limit}
                                        page={this.state.page}
                                        changePageHandler={this.goToPage.bind(this)}
                                    />
                                </Colxx>
                            </Row>

                            {this.renderTable()}


                            <div style={{right: 0}}
                                 className='pt-2 pr-4 position-absolute d-none d-lg-block d-xl-block'>
                                <select onChange={e => this.setState({limit: e.target.value})}>
                                    <option selected={this.state.limit === 10}>10</option>
                                    <option selected={this.state.limit === 20}>20</option>
                                    <option selected={this.state.limit === 50}>50</option>
                                    <option selected={this.state.limit === 100}>100</option>
                                </select> disputes per page
                            </div>

                            <Row>
                                <Colxx xxs="12" className="text-center">
                                    <TablePagination
                                        hasNext={this.state.hasNext}
                                        hasPrevious={this.state.hasPrevious}
                                        totalCount={this.state.totalCount}
                                        limit={this.state.limit}
                                        page={this.state.page}
                                        changePageHandler={this.goToPage.bind(this)}
                                    />
                                </Colxx>
                            </Row>

                            <Modal isOpen={!!this.state.modalEntry} toggle={this.toggleModalEntry.bind(this)}>
                                {this.renderModalContent()}
                            </Modal>

                            <Modal isOpen={!!this.state.modalImage} toggle={this.toggleModalImage.bind(this)} size="xl">
                                {this.renderModalImageContent()}
                            </Modal>
                            <Modal size="lg" isOpen={this.state.showDownloads}
                                   toggle={this.toggleShowDownloads.bind(this)}>

                                <ModalHeader toggle={this.toggleShowDownloads.bind(this)}>
                                    Download Generation
                                </ModalHeader>
                                <ModalBody>
                                    {this.state.showDownloads && <DownloadsModalContent/>}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggleShowDownloads.bind(this)}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        </>
    }
}

export default EntryList