import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { Row, Card, CardBody, CardTitle, Progress, Table } from "reactstrap";
import { LineShadow } from "../../../components/Charts";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import "chartjs-plugin-datalabels";
import "react-circular-progressbar/dist/styles.css";

import { lineChartConfig } from "../../../constants/chartConfig";
import { Sparklines, SparklinesLine, SparklinesBars } from "react-sparklines";
import leftStorages from "../../../data/leftStorage.json";

const sparklineData = [936, 968, 1025, 999, 998, 1014, 1017, 1010, 1010, 1007];

const leftStorage = leftStorages.data;

export default class storageDashboard extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.storage" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          {/* storage First Row starts here */}
          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card className="bg-theme-1">
              <CardBody>
                <div className="ml-3">
                  <i className="fas fa-file-alt float-left font-50px mr-2" />
                  <IntlMessages id="storage.files" />
                  <h3>34,908</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card className="bg-theme-2">
              <CardBody>
                <div className="ml-3">
                  <i className="far fa-file-archive float-left font-50px mr-2" />
                  <IntlMessages id="storage.zipped" />
                  <h3>64,876</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card className="bg-theme-3">
              <CardBody>
                <div className="ml-3">
                  <i className="fa fa-camera float-left font-50px mr-2" />
                  <IntlMessages id="storage.photos" />
                  <h3>79,441</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx sm="6" md="6" lg="6" xl="3" className="mb-4">
            <Card className="bg-danger text-white">
              <CardBody>
                <div className="ml-3">
                  <i className="fa fa-video float-left font-50px mr-2" />
                  <IntlMessages id="storage.videos" />
                  <h3>57,432</h3>
                </div>
              </CardBody>
            </Card>
          </Colxx>
          {/* storage First Row ends here */}

          {/* Chart starts here */}
          <Colxx sm="12" md="12" lg="12" xl="8" className="mb-4">
            <Card>
              <CardBody>
                <div className="dashboard-line-chart">
                  <LineShadow {...lineChartConfig} />
                </div>
                <Row className="mt-5">
                  <Colxx className="mt-3" xl="6">
                    <strong>
                      <IntlMessages id="storage.temperature" />
                    </strong>
                    <p className="font-50px mt-5">
                      43.7<sup>°C</sup>
                    </p>{" "}
                    <div className="mt-4">
                      <span className="mr-2">
                        <IntlMessages id="storage.min-value" /> 27<sup>°c</sup>{" "}
                        <span className="fa fa-caret-down text-danger" /> - 20
                      </span>{" "}
                      |
                      <span className="ml-2 mt-3">
                        <IntlMessages id="storage.max-value" /> 69<sup>°c</sup>{" "}
                        <span className="fa fa-caret-up text-success" /> + 57
                      </span>
                    </div>
                  </Colxx>
                  <Colxx xl="6" className="mt-3">
                    <strong>
                      <IntlMessages id="storage.bandwidth" />
                    </strong>
                    <p>754.9 Mbps</p>

                    {leftStorage.map((storage, index) => {
                      return (
                        <div key={index} className="mb-4">
                          <p className="mb-2">
                            <IntlMessages id={`${storage.title}`} />
                            <span className="float-right text-muted">
                              {storage.status}/{storage.total}
                            </span>
                          </p>
                          <Progress
                            color={`${storage.color}`}
                            value={(storage.status / storage.total) * 100}
                          />
                        </div>
                      );
                    })}
                  </Colxx>
                  <Colxx lg="12">
                    <strong>
                      <IntlMessages id="storage.todays-tip" />
                    </strong>
                    <p>
                      {" "}
                      <IntlMessages id="storage.tip-desc" />
                    </p>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
          {/* Chart Ends here */}

          {/* Usage starts here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Row>
              <Colxx sm="12" xs="12" md="12" lg="12" xl="12" className="mb-4">
                <Card className="pt-4 bg-theme-1">
                  <CardBody>
                    <div>
                      <strong>
                        <span className="fa fa-cloud mr-2" /> Storage Usage
                      </strong>
                      <p className="mt-2">
                        <span className="mr-2">Free Space</span>
                        <span className="mr-2">456PB</span> |
                        <span className="mx-2">Free Space</span>
                        <span>456PB</span>
                      </p>
                      <Sparklines
                        data={sparklineData}
                        margin={6}
                        style={{
                          height: "calc(40% - 38px)",
                          width: "100%"
                        }}
                      >
                        <SparklinesLine
                          style={{
                            strokeWidth: 3,
                            stroke: "#fff",
                            fill: "none"
                          }}
                        />
                      </Sparklines>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="12" xs="12" md="12" lg="12" xl="12" className="mb-4">
                <Card className="pt-4 bg-theme-2">
                  <CardBody>
                    <div>
                      <strong>
                        <span className="fa fa-cloud mr-2" /> Storage Usage
                      </strong>
                      <p className="mt-2">
                        <span className="mr-2">Free Space</span>
                        <span className="mr-2">456PB</span> |
                        <span className="mx-2">Free Space</span>
                        <span>456PB</span>
                      </p>
                      <Sparklines
                        data={sparklineData}
                        style={{
                          height: "calc(40% - 38px)",
                          width: "100%"
                        }}
                      >
                        <SparklinesBars
                          style={{
                            stroke: "white",
                            strokeWidth: "1",
                            fill: "#fff"
                          }}
                        />
                      </Sparklines>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="12" xs="12" md="12" lg="12" xl="12" className="mb-4">
                <Card className="pt-4 bg-theme-3">
                  <CardBody>
                    <div>
                      <strong>
                        <span className="fa fa-tachometer-alt mr-2" /> Server
                        Load
                      </strong>
                      <Sparklines
                        data={sparklineData}
                        style={{
                          height: "calc(40% - 38px)",
                          width: "100%"
                        }}
                        height={20}
                      >
                        <SparklinesLine
                          style={{
                            stroke: "white",
                            strokeWidth: "2",
                            fill: "none"
                          }}
                        />
                      </Sparklines>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
          {/* Usage Ends here  */}

          {/* Social Interaction starts here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Card className="pt-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="storage.social-interactions" />
                </CardTitle>
                <div className="text-center">
                  <p>
                    <IntlMessages id="storage.target-more" />
                  </p>
                  <p>
                    <span className="fa fa-tachometer-alt font-50px font-color-1" />
                  </p>
                  <h3>
                    9.974 <IntlMessages id="storage.social-interactions" />
                  </h3>
                </div>
                <Table borderless>
                  <tbody>
                    <tr>
                      <th scope="row">
                        Twitter <IntlMessages id="storage.events" />
                      </th>
                      <th>
                        <p>
                          <i className="fa fa-caret-up font-color-1 mr-1" />
                          5711
                        </p>
                      </th>
                      <th scope="row">
                        {" "}
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        Facebook <IntlMessages id="storage.events" />
                      </th>
                      <th>
                        <i className="fa fa-caret-up font-color-5 mr-1" />
                        1419
                      </th>
                      <th scope="row">
                        {" "}
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        YouTube <IntlMessages id="storage.views" />
                      </th>
                      <th>
                        <p>
                          <i className="fa fa-caret-up font-color-1 mr-1" />
                          8,984
                        </p>
                      </th>
                      <th scope="row">
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
          {/* Social Interaction Ends here  */}

          {/* Web Metrics starts here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Card className="pt-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="storage.web-metric" />
                </CardTitle>
                <div className="text-center">
                  <p>
                    <IntlMessages id="storage.target-or-less" />
                  </p>
                  <p>
                    <span className="fa fa-tachometer-alt font-50px font-color-1" />
                  </p>
                  <h3>
                    <IntlMessages id="storage.average-ratio" />
                  </h3>
                </div>
                <Table borderless>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <IntlMessages id="storage.visitors" />
                      </th>
                      <th>
                        <p>
                          <i className="fa fa-caret-up font-color-1 mr-1" />
                          570,111
                        </p>
                      </th>
                      <th scope="row">
                        {" "}
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <IntlMessages id="storage.site-events" />
                      </th>
                      <th>
                        <i className="fa fa-caret-up font-color-5 mr-1" />
                        20,419
                      </th>
                      <th scope="row">
                        {" "}
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <IntlMessages id="storage.leads" />
                      </th>
                      <th>
                        <p>
                          <i className="fa fa-caret-up font-color-1 mr-1" />
                          8,984
                        </p>
                      </th>
                      <th scope="row">
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
          {/* Web Metrics Ends here  */}

          {/* Uploads Ratio starts here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Card className="pt-4">
              <CardBody>
                <CardTitle>
                  <IntlMessages id="storage.uploads-metrics" />
                </CardTitle>
                <div className="text-center">
                  <p>
                    <IntlMessages id="storage.uploads-target" />
                  </p>
                  <p>
                    <span className="fa fa-tachometer-alt font-50px font-color-1" />
                  </p>
                  <h3>
                    <IntlMessages id="storage.making-uploads" />
                  </h3>
                </div>
                <Table borderless>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <IntlMessages id="storage.recent" />
                      </th>
                      <th>
                        <p>
                          <i className="fa fa-caret-up font-color-1 mr-1" />
                          90,765
                        </p>
                      </th>
                      <th scope="row">
                        {" "}
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <IntlMessages id="storage.per-minute" />
                      </th>
                      <th>
                        <i className="fa fa-caret-up font-color-5 mr-1" />
                        327
                      </th>
                      <th scope="row">
                        {" "}
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <IntlMessages id="storage.load" />
                      </th>
                      <th>
                        <p>
                          <i className="fa fa-caret-up font-color-1 mr-1" />
                          67%
                        </p>
                      </th>
                      <th scope="row">
                        <Sparklines
                          data={sparklineData}
                          margin={6}
                          style={{
                            height: "calc(35% - 38px)",
                            width: "40px"
                          }}
                        >
                          <SparklinesLine
                            style={{
                              strokeWidth: 3,
                              stroke: "#922c88",
                              fill: "none"
                            }}
                          />
                        </Sparklines>
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
          {/* Uploads Ratio Ends here  */}
        </Row>
      </Fragment>
    );
  }
}
