import React, {Component} from "react";
import {EntryFilter} from "../entryFilter";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {Colxx} from "../../../components/CustomBootstrap";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import AreaChart from "../../../components/ApexCharts/AreaChart";
import RegistrationChart from "./RegistrationChart";
import BreakdownChartsWrapper from "./BreakdownChartsWrapper";
import LocationList from "./LocationList";
import Switch from "rc-switch";

require('rc-switch/assets/index.css')
require("../../../index.css")

export default class Report extends Component {
    constructor(props) {
        super(props);

        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
        this.state = {
            promoTitle: "",
            filters: {},
            showByProfile: false
        }

        this.registrationChart = React.createRef()
        this.breakdownCharts = React.createRef()
        this.locationList = React.createRef()
    }

    fetchPromoName(promoID) {
        if (promoID) {
            this.promoApi.getItem(promoID).then(response => {
                this.setState({promoTitle: response.data.title})
            })
        } else {
            this.setState({promoTitle: ""})
        }
    }

    loadPromoName() {
        const {match} = this.props;
        const promoID = match.params.promoID

        this.fetchPromoName(promoID)
    }

    componentDidMount() {
        this.loadPromoName()
    }

    applyFilter(filterValues) {
        this.setState(prevState => ({
            ...prevState,
            filters: filterValues
        }), () => {
            this.reloadData()
        })
    }

    reloadData() {

        this.registrationChart.current.loadData()
        this.breakdownCharts.current.loadData()
        this.locationList.current.loadData()
    }

    render() {
        const {match} = this.props;
        const promoID = match.params.promoID

        const queryParams = {...this.state.filters};
        if (promoID) {
            queryParams['promo_id'] = promoID;
        }
        if (this.state.showByProfile) {
            queryParams['by_profile'] = 1
        }

        return <>
            <div className="bg-primary text-light p-2 pl-4 report-switcher">
                <Row className="text-center mb-1">
                    <Col><strong>Show report by</strong></Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <span className="pr-2">
                            Entries{" "}
                            <i className="fa fa-ticket-alt mr-1"></i>
                        </span>
                        <Switch
                            className=""
                            checked={this.state.showByProfile}
                            onChange={showByProfile => {
                                this.setState({ showByProfile }, () => {
                                    this.reloadData()
                                });
                            }}
                        />
                        <span className="pl-2">
                            <i className="fa fa-users mr-1"></i>{" "}
                            Profile
                        </span>
                    </Col>
                </Row>
            </div>
            <div className="clearfix">&nbsp;</div>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>{this.state.promoTitle} Report</h2>
                            </CardTitle>

                            <EntryFilter
                                promoID={promoID}
                                applyHandler={this.applyFilter.bind(this)}
                                history={this.props.history}
                            />

                        </CardBody>
                    </Card>
                </Colxx>
            </Row>

            <RegistrationChart
                ref={this.registrationChart}
                showExtras={true}
                params={queryParams}
                history={this.props.history}
            />
            <BreakdownChartsWrapper
                ref={this.breakdownCharts}
                params={queryParams}
                history={this.props.history}/>
            <LocationList
                ref={this.locationList}
                params={queryParams}
                history={this.props.history}/>

            <br/>
        </>
    }
}