import React, {Component} from "react";
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import TagListItem from "./tagListItem";
import TagEditModal from "./tagEditModal";
import {toast} from "react-toastify";
import Loading from "../../../components/loading";


export default class Tag extends Component {
    constructor(props) {
        super(props);

        this.api = new ApiClient('email_tag', authenticationErrorHandler.bind(this))
        this.emailHistoryApi = new ApiClient('email_history', authenticationErrorHandler.bind(this))
        this.state = {
            isLoading: true,
            hasError: false,
            data: [],

            savingRows: [],
            errorRows: [],

            editingTag: null,
            createTag: false,

            indexes: [],
            mailTemplates: []
        }
    }

    componentDidMount() {
        this.loadTags()
        this.loadIndexes()
        this.loadTemplates()
    }

    loadIndexes() {
        this.emailHistoryApi.getItem('indexes').then(response => {
            this.setState({indexes: [...response.data.indexes]})
        })
    }

    loadTemplates() {
        this.emailHistoryApi.getItem('templates').then(response => {
            this.setState({mailTemplates: [...response.data.templates]})
        })
    }

    loadTags() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.api.list().then(response => {
                this.setState({
                    data: response.data,
                    isLoading: false
                })
            }).catch(() => {
                this.setState({
                    isLoading: false,
                    hasError: true
                })
            })
        })
    }

    updateTag(editingTagName, newData) {
        this.setState(prevState => ({
            ...prevState, savingRows: [...prevState.savingRows, editingTagName]
        }), () => {
            this.api.updateItem(editingTagName, newData).then(response => {
                this.setState(prevState => ({
                    savingRows: [...prevState.savingRows.filter(sr => sr !== prevState.editingTag.name)],
                    editingTag: null,
                    data: [
                        ...prevState.data.map(d => {
                            if (d.name === prevState.editingTag.name) {
                                return response.data
                            }
                            return d
                        })
                    ]
                }), () => {
                    toast.success("Updated tag " + editingTagName)
                })
            }).catch(() => {
                this.setState(prevState => ({
                    savingRows: [...prevState.savingRows.filter(sr => sr !== prevState.editingTag.name)],
                    editingTag: null,
                    errorRows: [...prevState.errorRows, editingTagName]
                }), () => {
                    toast.error("Error saving tag " + editingTagName)
                })
            })
        })
    }

    createTag(newData) {
        this.api.create(newData).then(response => {
            this.setState(prevState => ({
                createTag: false,
                data: [
                    ...prevState.data,
                    response.data
                ]
            }), () => {
                toast.success("Created tag " + response.data.name)
            })
        }).catch(() => {
            toast.error("Error saving tag " + newData.name)
        })
    }

    deleteTag(tagName) {
        this.setState(prevState => ({
            ...prevState, savingRows: [...prevState.savingRows, tagName]
        }), () => {
            this.api.deleteItem(tagName).then(response => {
                this.setState(prevState => ({
                    savingRows: [...prevState.savingRows.filter(sr => sr !== tagName)],
                    data: [
                        ...prevState.data.filter(d => {
                            return d.name !== tagName
                        })
                    ]
                }), () => {
                    toast.success("Deleted tag " + tagName)
                })
            }).catch(() => {
                this.setState(prevState => ({
                    savingRows: [...prevState.savingRows.filter(sr => sr !== tagName)],
                    editingTag: null,
                    errorRows: [...prevState.errorRows, tagName]
                }), () => {
                    toast.error("Error deleting tag " + tagName)
                })
            })
        })
    }

    renderTagGroups() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        return <Row className="mt-4">
            {
                this.state.data.map(tag => {
                    return <Col xl={4} lg={6} sm={12} className="mb-2 d-flex">
                        <TagListItem
                            name={tag.name}
                            values={tag.values}
                            onEdit={() => {
                                this.setState({editingTag: tag})
                            }}
                            onDelete={() => {
                                this.deleteTag(tag.name)
                            }}
                        />
                    </Col>
                })
            }
        </Row>
    }

    render() {
        return <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h1>Email Tagging</h1>
                            </CardTitle>
                            <Button color="success" className="float-right" onClick={() => {this.setState({createTag: true})}}>
                                <span className="fa fa-plus"/> Create Tag
                            </Button>
                            <p>
                                Outgoing emails will be tagged based on the filters on each tag. You can also add tags defined here into the mail template.
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            { this.renderTagGroups() }
            {
                this.state.createTag && <TagEditModal
                    key={"new"}
                    data={{name: "", values: []}}
                    onSave={newData => {
                        this.createTag(newData)
                    }}
                    onCancel={() => {
                        this.setState({createTag: null})
                    }}

                    mailTemplates={this.state.mailTemplates}
                    indexes={this.state.indexes}
                    api={this.api}
                />
            }
            {
                this.state.editingTag && <TagEditModal
                    key={this.state.editingTag.name}
                    data={this.state.editingTag}
                    onSave={newData => {
                        this.updateTag(this.state.editingTag.name, newData)
                    }}
                    onCancel={() => {
                        this.setState({editingTag: null})
                    }}

                    mailTemplates={this.state.mailTemplates}
                    indexes={this.state.indexes}
                    api={this.api}
                />
            }
        </>
    }
}