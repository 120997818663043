import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {
    Alert, Badge, Button,
    Card,
    CardBody,
    CardTitle,
    Col, FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row
} from "reactstrap";
import Loading from "../../components/loading";
import {toast} from "react-toastify";
import TemplateEdit from "./templateEdit";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import InputCopy from "../../components/inputCopy";
require("../../App.css")


export default class TemplateList extends Component {
    state = {
        data: [],
        isLoading: true,
        hasError: false,
        editingId: null,
        searchText: "",
        enabledFilter: null,
        migrateId: null,
        migrationSyncing: false,
        migrationFinalizing: false,

        tags: [],
        isTagsLoading: true
    }

    constructor(props) {
        super(props);
        this.api = new ApiClient('template', authenticationErrorHandler.bind(this))
    }

    loadTagOptions() {
        this.setState({isTagsLoading: true}, () => {
            this.api.getItem('tags').then(response => {
                this.setState({tags: response.data, isTagsLoading: false})
            }).catch(() => {
                this.setState({isTagsLoading: false})
            })
        })
    }


    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.api.list().then(response => {
                this.setState({
                    data: response.data,
                    hasError: false,
                    isLoading: false
                })
            }).catch(() => {
                this.setState({
                    isLoading: false,
                    hasError: true
                })
            })
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadTagOptions()
    }

    renderContent() {

        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        if (this.state.hasError) {
            return <Row>
                <Col className="text-center">
                    <Alert color="error">Something went wrong. Please contact administrator.</Alert>
                </Col>
            </Row>
        }

        const searchText = this.state.searchText.toLowerCase();
        const data = this.state.data
            .filter(row => JSON.stringify(Object.values(row)).toLowerCase().indexOf(searchText) > -1)
            .filter(row => this.state.enabledFilter === null || this.state.enabledFilter === row.is_enabled)


        if (this.state.data.length <= 0) {
            return <Row>
                <Col className="text-center">
                    No template yet. Create one now.
                </Col>
            </Row>
        }
        if (data.length <= 0) {
            return <Row>
                <Col className="text-center">
                    No result found for "{searchText}"
                </Col>
            </Row>
        }

        return <>
            {
                data.map(template => {
                    const appliedTags = this.state.tags.length > 0 ? this.state.tags.filter(tag => template.tags.indexOf(tag.id) >= 0) : []
                    return <>
                        <Row className="my-2">
                            <Col className="my-auto" xl={3} lg={12} md={12}>
                                <h4 className="">
                                    {!template.is_enabled && <> <Badge color="danger" pill={true}>Disabled</Badge> </>}
                                    {template.subject_template}
                                </h4>
                                {appliedTags.length > 0 ? <>
                                    {appliedTags.map(tag => <span className="badge badge-outline-info text-small">{tag.__str__}</span>)}
                                    <br/>
                                    </> : null }
                                Emails Sent: {template.email_sent}
                            </Col>
                            <Col xl={4} lg={12} md={12}>
                                <ul style={{
                                    listStyleType: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }} className="p-0 m-0">
                                    <li>To: {template.email}</li>
                                    {template.email_cc && <li>CC: {template.email_cc}</li>}

                                    <li>From: {template.sender} {template.sender_name && <>&lt;{template.sender_name}&gt;</>} </li>
                                </ul>

                            </Col>
                            <Col xl={5} lg={12} md={12}>
                                <Row>
                                    <Col xl={8} lg={8} md={8} className={"mb-1"}>
                                        <InputCopy prepend="POST" value={template.receiver_url} toastText={"Receiver URL copied to clipboard."}/>
                                        {/*{template.original_uid && <span className="text-semi-muted small">Old URL ID: {template.original_uid}</span>}*/}
                                    </Col>
                                    <Col xl={4} lg={4} md={4} className="text-right">
                                        {
                                            template.is_migrated === false &&
                                            <Button size="sm" color="warning" onClick={() => {
                                                this.setState({migrateId: template.uid})
                                            }}>
                                                <span className="fa fa-cloud-download-alt"></span>{" "}
                                                Migrate
                                            </Button>
                                        }

                                        <Button size="sm" onClick={() => {
                                            this.setState({editingId: template.uid})
                                        }}>
                                            <span className="fa fa-pen"></span>{" "}
                                            Edit
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr/>
                    </>
                })
            }
        </>
    }

    render() {
        const showOptions = [
            {value: true, label: "Show only enabled"},
            {value: false, label: "Show only disabled"},
        ]
        const showSelected = this.state.enabledFilter === null ? null : showOptions.filter(o => o.value === this.state.enabledFilter)[0]
        return <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <Row>
                                    <Col>
                                        <h1>Email Templates</h1>
                                    </Col>
                                    <Col xl={8} lg={12}>
                                        <Row form className="form text-right">
                                            <Col className="mb-2" sm={12} md={6} lg={4}>
                                                <Select
                                                    isClearable={true}
                                                    className="react-select small"
                                                    classNamePrefix="react-select"
                                                    value={showSelected}
                                                    placeholder={"Show all"}
                                                    onChange={o => this.setState({enabledFilter: o ? o.value : null})}
                                                    options={showOptions}
                                                />
                                            </Col>
                                            <Col className="mb-2" sm={12} md={6} lg={4}>
                                                <Input
                                                    style={{height: "3em"}}
                                                    type="text"
                                                    placeholder="search here"
                                                    value={this.state.searchText}
                                                    onChange={e => this.setState({searchText: e.target.value})}
                                                />
                                            </Col>
                                            <Col md={12} lg={4} className="text-right">
                                                <Button size="sm" className="text-nowrap"
                                                        onClick={() => this.setState({editingId: "new"})}>
                                                    <span className="fa fa-plus"></span>{" "}
                                                    Create Template
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardTitle>
                            {this.renderContent()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={!!this.state.editingId} size="xl">
                {!!this.state.editingId && <TemplateEdit
                    id={this.state.editingId}
                    tagOptions={this.state.tags}
                    displayAsModal={true}
                    history={this.props.history}
                    onClose={() => {
                        this.setState({editingId: null})
                    }}
                    onUpdate={newData => {
                        this.setState(prevState => {
                            const updatedDataList = prevState.data.some(row => row.uid === newData.uid) ? prevState.data.map(row => {
                                if (row.uid === newData.uid) {
                                    return {
                                        ...row,
                                        ...newData
                                    }
                                } else {
                                    return row
                                }
                            }) : [newData, ...prevState.data]
                            return {
                                ...prevState,
                                data: [
                                    ...updatedDataList
                                ],
                                editingId: null
                            }
                        })
                    }}
                />}
            </Modal>
            {this.renderMigration()}
        </>
    }

    updateItem(item) {
        this.setState(prevState => {
            return {
                ...prevState,
                migrationSyncing: false,
                migrationFinalizing: false,
                data: [
                    ...prevState.data.map(row => {
                        if (row.uid === item.uid) {
                            return item
                        } else {
                            return row
                        }
                    })
                ]
            }
        })
    }

    handleMigrateSync() {
        this.setState({migrationSyncing: true}, () => {
            this.api.postItemAction(this.state.migrateId, 'sync', {}).then(response => {
                this.updateItem(response.data)
                toast.info("Data from old platform had been synced for " + response.data.subject_template)
            })
        })
    }

    handleFinalizeMigration() {
        this.setState({migrationFinalizing: true}, () => {
            this.api.patchItem(this.state.migrateId, {is_migrated: true}).then(response => {
                this.updateItem(response.data)
                this.setState({migrateId: null})
                toast.success(response.data.subject_template + " had been migrated")
            })
        })
    }

    renderMigration() {
        if (!this.state.migrateId || this.state.data.length === 0) {
            return null
        }
        const template = this.state.data.filter(row => row.uid === this.state.migrateId)[0]

        return <Modal isOpen={!!this.state.migrateId} size={"lg"}>
            <ModalHeader>
                <h3>Migrating {template.subject_template}</h3>
            </ModalHeader>

            <ModalBody>
                <h4>Step 1 - Update from old platform</h4>
                <p>Make sure that the email sent matches.</p>
                <p>If you have already updated the template in this platform and prefer to keep the data saved in this platform, ignore this step.</p>
                <p>
                    Last seen count: {template.email_sent}
                    <Button size="sm" color={null} onClick={() => {this.handleMigrateSync()}}>
                        <span className={"fa fa-sync " + (this.state.migrationSyncing && "spin") }/> Sync
                    </Button>
                </p>

                <hr/>

                <h4>Step 2 - Update Webhook URL</h4>
                <p>
                    Change the webhook URL in the chatbot platform.
                </p>
                <FormGroup>
                    <Label>Original Webhook URL from Old Platform</Label>
                    <InputCopy value={"https://w7zldwz4oe.execute-api.ap-southeast-1.amazonaws.com/develop/handle/" + template.original_uid} prepend={"POST"}/>
                </FormGroup>

                <FormGroup>
                    <Label>New Webhook URL</Label>
                    <InputCopy value={template.receiver_url} prepend={"POST"}/>
                </FormGroup>

                <hr/>
                <h4>Step 3 - Mark as migrated</h4>
                <p>
                    Finally, let iokidigital app know that the webhook had been migrated.
                </p>
                <p>
                    Warning! This step is not reversible. Once marked as migrated, syncing from old platform will no longer be available.
                </p>

                <div className="text-center">
                    <Button disabled={this.state.migrationFinalizing} onClick={this.handleFinalizeMigration.bind(this)}>Finalize Migration</Button>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button disabled={this.state.migrationFinalizing} color="danger" onClick={() => {this.setState({migrateId: null})}}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}