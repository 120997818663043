import React, {Component} from "react";
import {Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";

export const dateRelativeOption = {value: "date_relative", label: "Relative Datetime", filterType: "date_relative", group: "System"}
export const dateFixedOption = {value: "date_fixed", label: "Fixed Date Range", filterType: "date_fixed", group: "System"}
export const dateRelativeValues = {
    thisyear: () => "This Year (currently: " + moment().year() + ")",
    lastyear: () => "Last Year (current value: " + moment().subtract( 1, "year").year() + ")",
    thismonth: () => "This Month (currently: " + moment().format("MMM") + ")",
    lastmonth: () => "Last Month (current value: " + moment().subtract( 1, "month").format("MMM") + ")",
    thisquarter: () => {
        const currentQuarter = moment().quarter(moment().quarter())
        const currentQuarterText = currentQuarter.startOf('quarter').format("MMM") + " to " + currentQuarter.endOf('quarter').format("MMM")
        return "This Quarter (currently: " + currentQuarterText + " " + currentQuarter.year() + ")"
    },
    lastquarter: () => {
        const currentQuarter = moment().subtract(1, "quarter").quarter(moment().subtract(1, "quarter").quarter())
        const currentQuarterText = currentQuarter.startOf('quarter').format("MMM") + " to " + currentQuarter.endOf('quarter').format("MMM")
        return "Last Quarter (currently: " + currentQuarterText + " " + currentQuarter.year() + ")"
    },
    last3months: () => {
        return "Last 3 Months (currently: " + moment().subtract(3, 'months').format("ll") + " to " + moment().format("ll") + ")"
    },
    last30days: () => {
        return "Last 30 Days (currently: " + moment().subtract(30, 'days').format("ll") + " to " + moment().format("ll") + ")"
    }

}

export class DynamicFilter extends Component {
    renderFilterInput() {
        if (this.props.selectedFilter.filterType === 'search') {
            return <InputGroup>
                <Input
                    value={this.props.filterValue}
                    onChange={e => this.props.onFilterValueChange(e.target.value)}
                    type="text" size="lg"/>
                <InputGroupAddon addonType={"prepend"}>
                    <InputGroupText>
                        <span className="fa fa-search"/>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        } else if (this.props.selectedFilter.filterType === 'date_relative') {
            const RELATIVE_DATE_OPTIONS = [
                ...Object.keys(dateRelativeValues).map( k => {
                    return {value: k, label: dateRelativeValues[k]()}
                })
            ]

            const selectedFilterValue = this.props.filterValue && RELATIVE_DATE_OPTIONS.filter(o => o.value === this.props.filterValue)[0]

            return <Select
                className="react-select small"
                classNamePrefix="react-select"
                options={RELATIVE_DATE_OPTIONS}

                value={selectedFilterValue}
                onChange={o => this.props.onFilterValueChange(o.value)}
                // onChange={this.props.onFilterChange}
            />
        } else if (this.props.selectedFilter.filterType === 'date_fixed') {
            const [startDate, endDate] = this.props.filterValue ? this.props.filterValue : [null, null];
            return <DatePicker
                selectsRange
                selected={typeof(startDate) === "string" ? new Date(startDate) : startDate}
                startDate={typeof(startDate) === "string" ? new Date(startDate) : startDate}
                endDate={typeof(endDate) === "string" ? new Date(endDate) : endDate}
                onChange={dates => {
                    this.props.onFilterValueChange(dates)
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="All time"
                isClearable={true}
                maxDate={new Date()}
                onCalendarClose={() => {
                    if (!endDate) {
                        this.props.onFilterValueChange([null, null])
                    }
                }}
            />
        } else if (this.props.selectedFilter.filterType === 'template') {
            const templateOptions = this.props.mailTemplates.map(row => ({
                value: row.id,
                label: row.subject_template
            }))
            const selectedTemplate = this.props.filterValue
                && (
                    Array.isArray(this.props.filterValue) ?
                        templateOptions.filter(o => this.props.filterValue.indexOf(o.value) > -1) :
                        templateOptions.filter(o => o.value === this.props.filterValue)[0]
                )
            return <Select
                isMulti={true}
                className="react-select small"
                classNamePrefix="react-select"
                options={templateOptions}

                value={selectedTemplate}
                onChange={o => {
                    if (Array.isArray(o)) {
                        this.props.onFilterValueChange([...o.map(oItem => oItem.value)])
                    } else {
                        this.props.onFilterValueChange(o.value)
                    }
                }}
                // onChange={this.props.onFilterChange}
            />
        } else if (this.props.selectedFilter.filterType === 'tag') {
            const tagNameId = this.props.selectedFilter.tagId
            const tagOptions = this.props.tagNames.filter(tagName => tagName.id === tagNameId)[0].values.map(tagValue => {
                return {
                    value: tagValue.id,
                    label: tagValue.value
                }
            })
            const selectedOption = this.props.filterValue && (
                Array.isArray(this.props.filterValue) ?
                    tagOptions.filter(o => this.props.filterValue.indexOf(o.value) > -1) :
                    tagOptions.filter(o => o.value === this.props.filterValue)[0]
            )
            return <Select
                isMulti={true}
                className="react-select small"
                classNamePrefix="react-select"
                options={tagOptions}

                value={selectedOption}
                onChange={o => {
                    if (Array.isArray(o)) {
                        this.props.onFilterValueChange([...o.map(oItem => oItem.value)])
                    } else {
                        this.props.onFilterValueChange(o.value)
                    }
                }}
                // onChange={this.props.onFilterChange}
            />
        }
    }

    render() {
        const selectedFilter = this.props.selectedFilter;

        const canChangeToOptions = [selectedFilter, ...this.props.availableFilters]
        if (selectedFilter.value === 'date_relative') {
            canChangeToOptions.push(dateFixedOption)
        } else if (selectedFilter.value === 'date_fixed') {
            canChangeToOptions.push(dateRelativeOption)
        }

        const groupedOptions = Object.values(canChangeToOptions.reduce((acc, o) => {
            if (!acc.hasOwnProperty(o.group)) {
                acc[o.group] = {
                    label: o.group,
                    options: []
                }
            }
            acc[o.group].options.push(o)
            return acc
        }, {}))

        return <Row className="mb-2">
            <Col md={1} sm={2} className="text-right">
                <Button color="danger" size="sm" onClick={this.props.onDelete}>
                    <span className="fa fa-trash"/>
                </Button>
            </Col>
            <Col>
                <Select
                    className="react-select small"
                    classNamePrefix="react-select"
                    value={selectedFilter}
                    options={groupedOptions}
                    onChange={this.props.onFilterChange}
                />
            </Col>
            <Col>
                {this.renderFilterInput()}
            </Col>
        </Row>
    }
}