import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, Card, CardBody, CardTitle, CustomInput, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";

import moment from "moment";
import {clearAuthTokens} from "axios-jwt";

const DEFAULT_USER_DATA = {
    'username': '',
    'email': '',
    'is_active': false,
    profile: {
        description: ''
    }
}


export default class ChangePassword extends Component {

    myProfileApi = new ApiClient('profile', authenticationErrorHandler.bind(this))
    state = {
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
        errors: {
            old_password: null,
            new_password: null,
            repeat_password: null,
        }
    }

    constructor(props) {
        super(props);

        this.history = props.history;
    }


    handleChangeOldPassword(e) {
        this.setState(
            prevState => ({
                ...prevState,
                oldPassword: e.target.value,
                errors: {
                    ...prevState.errors,
                    old_password: null
                }
            })
        )
    }

    handleChangeNewPassword(e) {
        this.setState(
            prevState => ({
                ...prevState,
                newPassword: e.target.value,
                errors: {
                    ...prevState.errors,
                    new_password: null
                }
            })
        )
    }

    handleChangeRepeatPassword(e) {
        this.setState(
            prevState => ({
                ...prevState,
                repeatPassword: e.target.value,
                errors: {
                    ...prevState.errors,
                    repeat_password: null
                }
            })
        )
    }


    handleSave() {
        this.myProfileApi.post('change_password/', {
            "old_password": this.state.oldPassword,
            "new_password": this.state.newPassword,
            "repeat_password": this.state.repeatPassword
        }).then(response => {
            if (response && response.status === 200 && response.data.status === "ok") {
                this.props.passwordChanged()
            }
        }).catch(error => {
            if (error.response && error.response.status === 400) {

                this.setState({errors: error.response.data})
            }
        })
    }

    canSave() {
        return this.state.newPassword && this.state.oldPassword && this.state.repeatPassword &&
            !this.state.errors.old_password && !this.state.errors.new_password && !this.state.errors.repeat_password &&
            this.state.newPassword === this.state.repeatPassword
    }

    getRepeatPasswordError() {
        if (this.state.repeatPassword && this.state.repeatPassword !== this.state.newPassword) {
            return "New password does not match"
        } else if (this.state.errors && this.state.errors.repeat_password) {
            return this.state.errors.repeat_password
        }
    }

    getNewPasswordError() {
        if (!this.state.newPassword && this.state.repeatPassword) {
            return "New password does not match"
        } else if (this.state.errors && this.state.errors.new_password) {
            return this.state.errors.new_password
        }
    }

    getOldPasswordError() {
        if (!this.state.oldPassword && (this.state.newPassword || this.state.repeatPassword)) {
            return "Old password is required"
        } else if (this.state.errors && this.state.errors.old_password) {
            return this.state.errors.old_password
        }
    }

    render() {
        const oldPasswordError = <span>{this.getOldPasswordError() ? this.getOldPasswordError() : <>&nbsp;</>}</span>
        const newPasswordError = <span>{this.getNewPasswordError() ? this.getNewPasswordError() : <>&nbsp;</>}</span>
        const repeatPasswordError =
            <span>{this.getRepeatPasswordError() ? this.getRepeatPasswordError() : <>&nbsp;</>}</span>

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Form>

                    <Row form>
                        <Colxx md={12}>
                            <FormGroup>
                                <Label for="old_password">Old Password</Label>
                                <Input
                                    type="password"
                                    name="old_password"
                                    id="old_password"
                                    placeholder="Old Password"
                                    value={this.state.oldPassword}
                                    onChange={this.handleChangeOldPassword.bind(this)}
                                />
                                {oldPasswordError}
                            </FormGroup>

                        </Colxx>
                    </Row>

                    <Row form>
                        <Colxx md={12}>
                            <FormGroup>
                                <Label for="new_password">New Password</Label>
                                <Input
                                    type="password"
                                    name="new_password"
                                    id="new_password"
                                    placeholder="New Password"
                                    value={this.state.newPassword}
                                    onChange={this.handleChangeNewPassword.bind(this)}
                                />
                                {newPasswordError}
                            </FormGroup>
                        </Colxx>
                    </Row>

                    <Row form>
                        <Colxx md={12}>
                            <FormGroup>
                                <Label for="repeat_password">Repeat Password</Label>
                                <Input
                                    type="password"
                                    name="repeat_password"
                                    id="repeat_password"
                                    placeholder="Repeat Password"
                                    value={this.state.repeatPassword}
                                    onChange={this.handleChangeRepeatPassword.bind(this)}
                                />
                                {repeatPasswordError}
                            </FormGroup>
                        </Colxx>
                    </Row>
                </Form>

                <Row>
                    <Colxx className="text-right">
                        <Button color="primary" className="mt-2"
                                disabled={!this.canSave()}
                                onClick={this.handleSave.bind(this)}>
                            Save
                        </Button>

                        <Button color="danger" className="mt-2"
                                onClick={() => this.props.cancel() }>
                            Cancel
                        </Button>
                    </Colxx>
                </Row>

            </Colxx>
        </Row>
    }
}