import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import defaultDash from "./main";
import ticketingDash from "./ticketing";
import bloggingDashboard from "./blogging";
import storageDashboard from "./storage";
import advertsDashboard from "./adverts";

const Dashboards = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/main`} />
      <Route path={`${match.url}/main`} component={defaultDash} />
      <Route path={`${match.url}/ticketing`} component={ticketingDash} />
      <Route path={`${match.url}/blogging`} component={bloggingDashboard} />
      <Route path={`${match.url}/storage`} component={storageDashboard} />
      <Route path={`${match.url}/adverts`} component={advertsDashboard} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Dashboards;
