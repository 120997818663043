import React, {Component} from "react";
import {Button, ButtonGroup, Card, CardBody, CardTitle, Col, Row} from "reactstrap";

export default class TagListItem extends Component {
    render() {
        return <Card className=" flex-fill">
            <CardBody>
                <CardTitle>
                    <Row className="">
                        <Col>
                            <h3 className="mb-0">{this.props.name}</h3>
                        </Col>
                        <Col sm={4} className="text-right">
                            <ButtonGroup>
                                <Button size="xs" onClick={this.props.onEdit}>
                                    <span className="fa fa-pen"/>
                                </Button>

                                <Button size="xs" color="danger" onClick={this.props.onDelete}>
                                    <span className="fa fa-trash"/>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>


                </CardTitle>
                <p>
                    Values: {this.props.values.map(v => v.value).join(', ')}
                </p>

            </CardBody>

        </Card>
    }
}