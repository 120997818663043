import React, {Component} from "react";
import {Badge, Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import TrackingApiValueTagging from "./trackingApiValueTagging";
import {toast} from "react-toastify";


export default class TrackingApiValue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
        }
    }

    render() {
        return <Row className="p-0 py-1 m-1 border-info border-1px border-left-0 border-right-0 border-bottom-0">
                <Col className="font-weight-bold">
                            {this.props.value}
                </Col>
            <Col>
                {this.props.tags.map(tagId => {
                    return <Badge color="outline-info" className="mr-1">{this.props.tagDefinitions[tagId]}</Badge>
                })}
            </Col>
                <Col className="text-right col-auto">
                    <Button size="xs" onClick={() => {this.setState({isEditing: true})}}>
                        <span className="fa fa-tags"/> Tags
                    </Button>

                    {
                        this.state.isEditing && <TrackingApiValueTagging
                            id={this.props.id}
                            api={this.props.api}
                            pointValue={this.props.value}
                            tags={this.props.tags}
                            isActive={this.props.isActive}
                            tagDefinitions={this.props.tagDefinitions}
                            onFinishedSave={data => {
                                this.setState({
                                    isEditing: false
                                }, () => {
                                    this.props.onUpdatePoint(data.id, data)
                                })
                            }}
                            onClose={() => {
                                this.setState({isEditing: false})
                            }}
                        />
                    }
                </Col>
            </Row>
    }
}