import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../api/ApiClient";
import {InfoCard} from "../routes/promo/report/InfoCard";
import Loading from "./loading";


export default class ApiInfoSet extends Component {

    constructor(props) {
        super(props);
        const data = props.data ? props.data : {}
        this.state = {
            isLoading: Object.keys(data).length === 0,
            hasError: false,
            data: data
        }
        this.client = new ApiClient('', authenticationErrorHandler.bind(this))
    }

    loadData() {
        if (this.props.url) {
            this.setState({isLoading: true, hasError: false}, () => {
                this.client.get(this.props.url).then(response => {
                    this.setState({data: {...response.data}, isLoading: false})
                }).catch(() => {
                    this.setState({hasError: true, isLoading: false})
                })
            })
        }
    }

    componentDidMount() {
        this.loadData()
    }


    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }

        const data = this.props.data ? this.props.data : this.state.data;

        let colDivisor = null;
        const keysLength = Object.keys(data).length
        if (keysLength > 3) {  // try to find what best way to display the keys
            if (keysLength % 3 === 0) {
                colDivisor = 3
            } else {
                if (keysLength >= 5) {
                    colDivisor = 3  // use maximum and let the remainder just adjust
                } else {
                    colDivisor = 2  // use something lesser
                }
            }
        } else {
            colDivisor = keysLength
        }

        const hasRemainder = keysLength % colDivisor > 0;
        const rowCount = Math.floor(keysLength / colDivisor)
        const lastRowItems = keysLength % colDivisor;

        return <>
            {
                this.props.title && <Row>
                    <Col>
                        <h3>{this.props.title}</h3>
                    </Col>
                </Row>
            }

            <Row className={'justify-content-center'}>
                {
                    Object.keys(data).map((k, i) => {
                        const isLastRow = Math.floor((i)/colDivisor) === rowCount;
                        const colSize = isLastRow && hasRemainder ? 12 / lastRowItems : 12/colDivisor;
                        return <Col className="d-flex" sm={12} md={12} lg={colSize}>
                            <InfoCard title={k} value={this.props.valueRenderer ? this.props.valueRenderer(data[k]) : data[k]}/>
                        </Col>
                    })
                }
            </Row>

            { this.props.renderBelow && this.props.renderBelow() }

        </>
    }
}