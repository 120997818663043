import React, {Component} from "react";


export default class PointsFetch extends Component {
    render() {
        return <>
            <div className="collapsible-content-container">
                <div className="collapsible-content-container__header"><span
                    className="collapsible-content-container__number">1.</span><span
                    className="collapsible-content-container__title">Connection Settings</span>
                    <svg className="arrow-icon collapsible-content-container__arrow-icon" width="15" height="15"
                         viewBox="0 0 1792 1792" style={{transition: "transform 0.5s ease 0s", transform: "rotate(0deg)"}}>
                        <path fill="#4e5274"
                              d="M1683 1331l-166 165q-19 19-45 19t-45-19l-531-531-531 531q-19 19-45 19t-45-19l-166-165q-19-19-19-45.5t19-45.5l742-741q19-19 45-19t45 19l742 741q19 19 19 45.5t-19 45.5z"></path>
                    </svg>
                </div>
                <div className="collapsible-content-container__dropdown">
                    <div className="edit-connection-type">
                        <div className="edit-connection-panel__title">Connection Type</div>
                        <div className="edit-connection-type__radio radio-input">
                            <label
                                className="radio-input-label">
                                <input type="radio" name="connectionKind"
                                       className="edit-connection-type__radio radio-input__input"
                                       value="variables_updates"/>
                                <span
                                    className="radio-input-label__title"
                                    title="Continuously send contact data from an audience">
                                    Continuously send contact data from an audience</span>
                            </label>
                        </div>
                        <div className="edit-connection-type__radio radio-input"><label
                            className="radio-input-label">
                            <input type="radio" name="connectionKind"
                                                                 className="edit-connection-type__radio radio-input__input"
                                                                 value="widget" checked=""/><span
                            className="radio-input-label__title" title="Send contact data whenever a widget is reached">Send contact data whenever a widget is reached</span></label>
                        </div>
                    </div>
                    <div className="edit-connection-panel__devider"></div>
                    <div className="edit-request-type-and-url">
                        <div className="edit-request-type-and-url__title-row">
                            <div className="edit-request-type-and-url__col1">
                                <div className="edit-connection-panel__title">Request type</div>
                                <div className="primary-select">
                                    <div className="primary-select__active-item">
                                        <div
                                            className="primary-select__active-item-name primary-select__active-item-name--value">GET
                                        </div>
                                        <svg className="primary-select__arrow-icon c-arrow-stateful-icon" width="20"
                                             height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                             style={{transform: "rotate(90deg)", transition: "all 0.3s ease 0s", width: "15px", height: "15px"}}>
                                            <path
                                                d="M15.2616 10.4979L7.96184 17.2935C7.67059 17.5688 7.19964 17.5688 6.9084 17.2935L6.46843 16.8776C6.17719 16.6022 6.17719 16.157 6.46843 15.8817L12.8077 10L6.47463 4.11834C6.18339 3.843 6.18339 3.39777 6.47463 3.12244L6.9146 2.7065C7.20584 2.43117 7.67679 2.43117 7.96804 2.7065L15.2677 9.50205C15.5528 9.77739 15.5528 10.2226 15.2616 10.4979Z"
                                                fill="#938bb7"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-request-type-and-url__col2">
                                <div className="edit-connection-panel__title">URL</div>
                                <div>
                                    <div
                                        className="attribute-containing-text-input edit-request-type-and-url__url-input">
                                        <div className="react-autosuggest__container"><input autoComplete="off"
                                                                                             role="combobox"
                                                                                             aria-autocomplete="list"
                                                                                             aria-owns="react-autowhatever-1"
                                                                                             aria-expanded="false"
                                                                                             aria-haspopup="false"
                                                                                             placeholder="URL"
                                                                                             type="text"
                                                                                             className="reactstrap-input react-autosuggest__input form-control"
                                                                                             value="https://the31pcfj3.execute-api.ap-southeast-1.amazonaws.com/dev/api/webhook/7c2448f7-227c-4147-aa2d-80530a7e3866/entries/"/>
                                            <div id="react-autowhatever-1"
                                                 className="react-autosuggest__suggestions-container"></div>
                                        </div>
                                    </div>
                                    <div className="edit-request-type-and-url__url-note-wrapper">
                                        <div className="edit-request-type-and-url__note">Note: Variables in urls are URL
                                            encoded
                                        </div>
                                        <div className="attribute-picker edit-request-type-and-url__attribute-picker">
                                            <svg className="" width="13" height="13" viewBox="0 0 13 13" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                    <path
                                                        d="M5.15768 12.1835L4.59345 12.0258C4.52123 12.0058 4.4806 11.9325 4.50091 11.8614L7.67644 0.910744C7.69675 0.839651 7.77123 0.79966 7.84345 0.819655L8.40769 0.977395C8.47991 0.99739 8.52053 1.07071 8.50022 1.1418L5.3247 12.0902C5.30439 12.1613 5.22765 12.2035 5.15768 12.1835ZM3.45143 9.39978L3.86897 8.93545C3.91185 8.8888 3.90508 8.8177 3.85768 8.77771L1.12678 6.50049L3.85768 4.22327C3.90508 4.18328 3.91185 4.11218 3.86897 4.06553L3.45143 3.6012C3.41081 3.55454 3.33859 3.5501 3.29119 3.59231L0.0389323 6.41829C-0.0129774 6.46272 -0.0129774 6.54048 0.0389323 6.58491L3.29119 9.41089C3.33859 9.45088 3.41081 9.44644 3.45143 9.39978ZM9.70994 9.40867L12.9622 6.58269C13.0141 6.53826 13.0141 6.4605 12.9622 6.41607L9.70994 3.59231C9.66255 3.55232 9.59258 3.55676 9.5497 3.6012L9.13217 4.06553C9.08928 4.11218 9.09606 4.18328 9.14345 4.22327L11.8744 6.50049L9.14345 8.77771C9.09606 8.8177 9.08928 8.8888 9.13217 8.93545L9.5497 9.39978C9.59033 9.44644 9.66255 9.45088 9.70994 9.40867Z"
                                                        fill="#F8F3FF"></path>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <path d="M0 0H13V13H2C0.89543 13 0 12.1046 0 11V0Z"
                                                              fill="white"></path>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}