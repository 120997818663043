import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Button, ButtonGroup, Card, CardBody, CardTitle, Col, Label, Row} from "reactstrap";
import Loading from "../../../components/loading";
import Select from "react-select";
import CustomSelect from "../../../components/CustomSelect";
import moment from "moment";

import FileSaver from "file-saver";
import {toast} from "react-toastify";

require("../../../index.css")

class DisburseNew extends Component {
    state = {
        promoTitle: "",
        prefixes: [],
        isLoading: true,
        hasError: false,
        selectedPrefix: null,
        numberOfCodes: 1,
        disbursedCodes: [],

        disburseLoading: false,
        disburseError: false
    }

    constructor(props) {
        super(props);

        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.getItemAction(this.props.promoId, 'disburse_settings').then(response => {
                this.setState({
                    isLoading: false,
                    hasError: false,
                    prefixes: response.data.prefixes,
                    promoTitle: response.data.promo_title
                })
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    disburseCode() {
        const targetCount = this.state.numberOfCodes;
        this.setState({disburseLoading: true, disburseError: false}, () => {
            this.promoApi.postItemAction(this.props.promoId, 'disburse', {}, {
                params: {
                    prefix: this.state.selectedPrefix,
                    count: targetCount
                }
            }).then(response => {
                this.setState({
                    disburseLoading: false,
                    disburseError: false,
                    disbursedCodes: targetCount === 1 ? [response.data] : [...response.data]
                }, () => {
                    if (targetCount > 1) {
                        this.props.onBatchGet()
                    }
                    toast("Generated " + targetCount + " code" + (targetCount > 1 ? "s. Download available below." : "."))
                })
            }).catch(() => {
                this.setState({disburseLoading: false, disburseError: true})
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    renderPrefixSelection() {
        if (this.state.prefixes.length <= 0) {
            return null
        }

        const options = this.state.prefixes.map(prefix => ({"label": prefix, "value": prefix}))
        const selectedOption = this.state.selectedPrefix ? options.filter(o => o.value === this.state.selectedPrefix)[0] : null

        return <Row className="justify-content-center">
            <Col lg={6} md={12}>
                <Label>Prefix/Brand</Label>
                <Select
                    isClearable={true}
                    components={{Input: CustomSelect}}
                    className="react-select small"
                    classNamePrefix="react-select"
                    name={"filter-prefix"}
                    value={selectedOption}
                    placeholder={"Choose prefix/brand code"}
                    onChange={newSelection => this.setState({selectedPrefix: newSelection ? newSelection.value : null})}
                    options={options}
                />
            </Col>
        </Row>
    }

    renderNumberOfCodes() {
        const options = [
            {value: 1, label: "1 code"},
            {value: 20, label: "20 codes (downloadable)"},
            {value: 50, label: "50 codes (downloadable)"},
            {value: 100, label: "100 codes (downloadable)"},
            // {value: 500, label: "500 codes (downloadable)"},
            // {value: 1000, label: "1000 codes (downloadable)"},
        ]
        const selectedOption = options.filter(o => o.value === this.state.numberOfCodes)[0]

        return <Row className="justify-content-center mt-3">
            <Col lg={6} md={12}>
                <Label>Number of Codes</Label>
                <Select
                    isClearable={false}
                    components={{Input: CustomSelect}}
                    className="react-select small"
                    classNamePrefix="react-select"
                    name={"filter-prefix"}
                    value={selectedOption}
                    onChange={newSelection => this.setState({numberOfCodes: newSelection.value})}
                    options={options}
                />
            </Col>
        </Row>
    }

    renderDisbursedCodes() {
        return <Row className="justify-content-center mt-4 text-center">
            <Col lg={8} md={12}>
                {this.state.disbursedCodes.map(c => <code key={c.value}
                                                          className="px-4 lead text-nowrap">{c.value}</code>)}
            </Col>
        </Row>
    }

    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }

        return <Row className="mt-4">
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>Disbursing Codes for {this.state.promoTitle}</h2>
                        </CardTitle>

                        {this.renderPrefixSelection()}
                        {this.renderNumberOfCodes()}
                        <Row className="justify-content-center mt-3">
                            <Col lg={6} md={12}>
                                <Button disabled={this.state.disburseLoading} onClick={this.disburseCode.bind(this)}>
                                    Generate Code{this.state.numberOfCodes > 1 && 's'}
                                </Button>
                            </Col>
                        </Row>

                        {this.renderDisbursedCodes()}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    }
}


class DisburseBatch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {...props.data},
            downloadingCSV: false,
            downloadingPDF: false,
        }
        this.codeBatchApi = new ApiClient('code_batch', authenticationErrorHandler.bind(this))
        this.batchId = props.data.id
    }

    loadData() {
        this.codeBatchApi.getItem(this.batchId).then(response => {
            this.setState({
                data: {...response.data},
                downloadingCSV: false,
                downloadingPDF: false,
            })
        })
    }

    downloadCsv() {
        this.setState({downloadingCSV: true}, () => {
            this.codeBatchApi.getItemAction(this.batchId, 'csv').then(response => {
                const file = new File([response.data], this.state.data.code_count.toString() + ' codes - ' + moment(this.state.data.datetime_added).format('LLLL'), {type: 'text/csv'});
                FileSaver.saveAs(file);
                this.loadData()
            }).catch(() => {this.loadData()})
        })
    }


    downloadPdf() {
        this.setState({downloadingPDF: true}, () => {
            this.codeBatchApi.getItemAction(this.batchId, 'pdf').then(response => {
                const file = new File(
                    [response.data],
                    this.state.data.code_count.toString() + ' codes - ' + moment(this.state.data.datetime_added).format('LLLL'),
                    {type: 'application/pdf'});
                FileSaver.saveAs(file);
                this.loadData()
            }).catch(() => {this.loadData()})
        })
    }


    render() {
        return <div className={"border-1px py-4 my-2 text-center" + (this.props.highlight ? ' border-primary' : '')}>
            <Row>
                <Col className="lead">
                    <strong>{this.state.data.code_count}</strong> codes
                </Col>
            </Row>
            <Row>
                <Col title={moment(this.state.data.datetime_added).format('LLLL')}>
                    Generated {moment(this.state.data.datetime_added).fromNow()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup size="sm">
                        <Button onClick={this.downloadCsv.bind(this)} disabled={this.state.downloadingCSV} style={{width: "6em"}}>
                            { this.state.downloadingCSV ? <small><Loading/></small> : <><span className="fas fa-file-csv"></span> CSV</> }
                        </Button>
                        <Button onClick={this.downloadPdf.bind(this)} disabled={this.state.downloadingPDF} style={{width: "6em"}}>
                            { this.state.downloadingPDF ? <small><Loading/></small> : <><span className="fa fa-file-pdf"></span> PDF</> }
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>
                        Download count: <strong>{this.state.data.download_count}</strong>.{" "}
                        Last downloaded: <strong title={this.state.data.last_downloaded ? moment(this.state.data.last_downloaded).format('LLLL') : 'never'}>
                        {this.state.data.last_downloaded ? moment(this.state.data.last_downloaded).fromNow() : 'never'}
                    </strong>
                    </small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small></small><br/>
                </Col>
            </Row>


        </div>
    }
}


class DisburseBatches extends Component {
    state = {
        isLoading: true,
        hasError: false,
        data: [],
        previousHighestId: null
    }

    constructor(props) {
        super(props);

        this.codeBatchApi = new ApiClient('code_batch', authenticationErrorHandler.bind(this))
    }

    loadData() {
        const maxId = this.state.data.length > 0 ? Math.max(...this.state.data.map(row => row.id)) : 0;

        this.setState({isLoading: true, hasError: false}, () => {
            this.codeBatchApi.list({params: {promo: this.props.promoId, id__gt: maxId}}).then(response => {

                this.setState(prevState=> ({
                    isLoading: false,
                    hasError: false,
                    data: [...response.data, ...prevState.data],
                    previousHighestId: maxId
                }))
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    loadLatest() {

    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return <Row className="mt-4">
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h4>Download Code Generation Batches</h4>
                        </CardTitle>

                        <Row className="justify-content-center">
                            { this.state.isLoading && <Col md={4} lg={3} className="text-center"><Loading/></Col> }
                            { !this.state.isLoading && this.state.data.length <= 0 && <Col className="text-center">
                                No downloadable generated set yet. Generate 20 or more codes.
                            </Col>}
                            {
                                this.state.data.map(d => <Col md={4} lg={3}>
                                    <DisburseBatch data={d} key={d.id} highlight={d.id > this.state.previousHighestId && this.state.previousHighestId}/>
                                </Col>)
                            }
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    }
}


export default class Disburse extends Component {
    constructor(props) {
        super(props);

        this.disburseBatchesRef = React.createRef()
    }

    batchGetTriggered() {
        this.disburseBatchesRef.current.loadData()
    }

    render() {

        const {match} = this.props;
        const promoId = match.params.promoID

        return <>
            <DisburseNew promoId={promoId} onBatchGet={this.batchGetTriggered.bind(this)}/>
            <DisburseBatches promoId={promoId} ref={this.disburseBatchesRef}/>
        </>
    }
}