import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row,
    Spinner
} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";

import moment from "moment";
import PromoEditNavigation from "./promoEditNavigation";

const ENTRY_FIELDS = [
    "name",
    "email_address",
    "mobile_number",
    "address",
    "city",
    "region",
    "country",
    "raw_code",
    "attachments",
]

const selectEntryFields = ENTRY_FIELDS.map(o => ({
    label: o.replace('_', ' ').split(' ')
        .map(i => i[0].toUpperCase() + i.substring(1).toLowerCase()).join(' '),
    value: o,
    key: o
}))


class MappingInput extends Component {
    handleChangeFrom(e) {
        this.props.handleChangeFrom(e.target.value)
    }

    handleChangeTo(selectedOption) {
        this.props.handleChangeTo(selectedOption.value)
    }

    render() {
        const toOption = selectEntryFields.filter(o => o.value === this.props.to)[0]

        return <Row form className="mb-4">
            <Colxx md={5} sm={12} xs={12} xxs={12} className="my-auto">
                <FormGroup className="mb-0">
                    <Input
                        type="text"
                        name="promoTitle"
                        placeholder="Property key from ChatBot"
                        value={this.props.from}
                        onChange={this.handleChangeFrom.bind(this)}
                    />
                </FormGroup>
            </Colxx>
            <Colxx className="my-auto text-center">
                <span className="fa fa-arrow-right d-none d-md-block"/>
                <span className="fa fa-level-up-alt fa-rotate-90 d-md-none d-xs-block d-sm-block"/>
            </Colxx>
            <Colxx md={5} sm={10} xs={10} xxs={8}>
                <FormGroup className="mb-0">
                    <Select
                        components={{Input: CustomSelect}}
                        className="react-select"
                        classNamePrefix="react-select"
                        name="form-field-name"
                        value={toOption}
                        placeholder="Promo Entry property"
                        onChange={this.handleChangeTo.bind(this)}
                        options={this.props.availableOptions}
                    />
                </FormGroup>
            </Colxx>
            <Colxx className="my-auto text-center">
                <Button color="danger" size="xs" onClick={() => {
                    this.props.delete()
                }}>
                    <span className="fa fa-trash"/><br/>
                    delete
                </Button>
            </Colxx>
        </Row>
    }
}


class PromoIndexes extends Component {
    state = {
        data: ["BRANCH", "COUNTRY"],
        originalData: [],
        newIndex: '',
        isLoading: true,
        hasError: false
    }

    constructor(props) {
        super(props);
        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.getItem(this.props.promoId).then(response => {
                this.setState({
                    isLoading: false,
                    hasError: false,
                    data: [...response.data.indexed_raw_data_keys],
                    originalData: [...response.data.indexed_raw_data_keys],
                })
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    canAddIndex() {
        if (!this.state.newIndex) {
            return false
        }
        if (this.state.data.map(i => i.toLowerCase()).indexOf(this.state.newIndex.toLowerCase()) >= 0) {
            return false
        }
        return true
    }

    isDataChanged() {
        return JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData)
    }

    save() {

        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.patchItem(this.props.promoId, {indexed_raw_data_keys: this.state.data}).then(response => {
                this.setState({
                    isLoading: false,
                    hasError: false,
                    data: [...response.data.indexed_raw_data_keys],
                    originalData: [...response.data.indexed_raw_data_keys],
                })
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    handleAddIndex() {
        this.setState(prevState => ({
            ...prevState,
            data: [...prevState.data, prevState.newIndex],
            newIndex: '',
        }), () => {
            this.props.onHasUnsavedChanged(this.isDataChanged())
        })
    }

    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>Indexed Properties</h2>
                        </CardTitle>

                        <p>
                            For properties coming from ChatBot that cannot be mapped into Promo Entries.
                            Adding them here will allow filtering and reporting of those values.
                            Note that adding new indexes will only
                            catalogue newly added entries and will not show values from old entries.
                        </p>
                        <p>Example: <code>BRANCH</code> value that is sent from ChatBot</p>

                        <p>
                            {
                                this.state.data.map(val => {
                                    return <div className="m-1 d-inline">
                                        <span key={val} className="p-2 badge badge-light" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>
                                            {val}
                                        </span>
                                        <span
                                            className="p-2 badge badge-danger"
                                            style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, cursor: "pointer"}}
                                            onClick={() => {
                                                this.setState(prevState => {
                                                    return {...prevState, data: [...prevState.data.filter(i => i !== val)]}
                                                })
                                            }}
                                        >
                                            <i className="fa fa-trash" ></i>
                                        </span>
                                    </div>
                                })
                            }
                            <Form inline={true} className="mt-2">
                                <InputGroup>
                                    <Input type="text" value={this.state.newIndex} onChange={e => {
                                        this.setState({newIndex: e.target.value})
                                    }} onKeyDown={e => {
                                        if (e.key === 'Enter' && this.canAddIndex()) {
                                            this.handleAddIndex()
                                        }
                                    }}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            size="xs"
                                            className="pt-2"
                                            disabled={!this.canAddIndex()}
                                            onClick={this.handleAddIndex.bind(this)}
                                        >Add</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Form>
                        </p>


                        <Button color="primary" className="mt-2"
                                disabled={!this.isDataChanged()}
                                onClick={this.save.bind(this)}>
                            <span className="fa fa-save"/> Save
                        </Button>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}


class PromoEntryMapping extends Component {
    state = {
        indexesHasUnsavedChanges: false,
        promoData: {},
        mapping: {},
        originalMapping: {},
        promoTitle: "",
        blockingStatus: "Loading..."
    }

    promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.promoIndexesRef = React.createRef()
    }

    componentDidMount() {
        const {match} = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            this.promoApi.getItem(promoID).then(this.handleApiResponse.bind(this))
        }
    }

    handleApiResponse(response) {
        const promoData = response.data
        const entryMapping = promoData.entry_mapping ? Object.keys(promoData.entry_mapping).map(
            (k) => ({
                key: k,
                value: promoData.entry_mapping[k]
            })
        ).reduce((acc, o, idx) => {
            acc[idx] = o;
            return acc;
        }, {}) : {};  // not promoData.entry_mapping, return empty object
        this.setState({
            promoData: promoData,
            mapping: entryMapping,
            originalMapping: entryMapping,
            promoTitle: promoData.title,
            blockingStatus: null,
        })
    }

    handleSave() {
        const {match} = this.props;
        const promoID = match.params.promoID
        this.setState({
            blockingStatus: "Saving...",
        }, () => {

            this.promoApi.patchItem(promoID, {
                "entry_mapping": Object.values(this.state.mapping).reduce((acc, o) => {
                    acc[o.key] = o.value;
                    return acc
                }, {})
            }).then(this.handleApiResponse.bind(this))
        })
    }

    isDataChanged() {
        const newData = {...this.state.mapping}
        const originalData = {...this.state.originalMapping}

        const isEqual = JSON.stringify(newData) === JSON.stringify(originalData)

        return !isEqual
    }

    handleChangeMappingKey(idx, newKey) {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...prevState.mapping,
                    [idx]: {
                        ...prevState.mapping[idx],
                        key: newKey
                    }
                }
            }
        })
    }

    handleChangeMappingValue(idx, newValue) {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...prevState.mapping,
                    [idx]: {
                        ...prevState.mapping[idx],
                        value: newValue
                    }
                }
            }
        })
    }

    handleDelete(idx) {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...Object.keys(prevState.mapping).filter(k => k !== idx).map(k => prevState.mapping[k])
                }
            }
        })
    }

    addRow() {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...prevState.mapping,
                    [Math.max(0, ...Object.keys(prevState.mapping).map(k => parseInt(k))) + 1]: {
                        key: "",
                        value: ""
                    }
                }
            }
        })
    }

    renderMap(idx) {
        const {key, value} = this.state.mapping[idx]
        const usedValues = Object.values(this.state.mapping).map(o => o.value)
        const availableValues = selectEntryFields.filter(o => usedValues.indexOf(o.value) === -1 || o.value === value)

        return <MappingInput
            from={key}
            to={value}
            availableOptions={availableValues}
            key={"map-" + idx}
            handleChangeFrom={this.handleChangeMappingKey.bind(this, idx)}
            handleChangeTo={this.handleChangeMappingValue.bind(this, idx)}
            delete={this.handleDelete.bind(this, idx)}
        />
    }

    render() {
        if (this.state.blockingStatus) {
            return <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>Editing Entry Mapping for <strong>{this.state.promoTitle}</strong></h2>
                            </CardTitle>

                            <Row>
                                <Colxx className="text-center my-auto">
                                    <Spinner animation="growing">
                                        {this.state.blockingStatus}
                                    </Spinner>
                                </Colxx>
                            </Row>

                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        }
        const usedFieldValues = Object.values(this.state.mapping).map(o => o.value)
        const unusedFieldValues = selectEntryFields.filter(o => usedFieldValues.indexOf(o.value) === -1)
        const valuesValid = Object.values(this.state.mapping).every(o => !!o.value && !!o.key)

        const {match} = this.props;
        const promoID = match.params.promoID

        return <>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-1">
                                <h2 className="mb-0">
                                    {this.state.promoTitle && <><strong>{this.state.promoTitle}</strong> - </>}
                                    Entry Mapping
                                </h2>

                            </CardTitle>

                            {   <PromoEditNavigation
                                data={this.state.promoData}
                                currentPage="edit-mapping"
                                history={this.props.history}
                                hasUnsavedChanges={this.isDataChanged() || this.state.indexesHasUnsavedChanges}
                            /> }
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            <Row className="mb-4">
                <Colxx xxs={12}>
                    <Card>
                        <CardBody>
                            <Row className="mb-4">
                                <Colxx>
                                    Entry mapping manages how the received information from ChatBot messages are mapped
                                    into registration entries.
                                    <ul>
                                        <li>
                                            By default, platform will try to find same named ChatBot attribute and
                                            map it into entry properties.
                                            For different attribute names and entry property names, please add a mapping.
                                        </li>
                                        <li>
                                            Unmapped entry properties will not be filled in. Be careful if some
                                            promo required properties, such as <code>code</code> is not mapped.
                                            This may mark the entry as invalid.
                                        </li>
                                        <li>
                                            Unmapped ChatBot API attributes will still be saved in the database
                                            and can be used on filtering and exports if they indexed below.
                                        </li>
                                    </ul>

                                </Colxx>
                            </Row>
                            <Form>
                                <Row form className="mb-4">
                                    <Colxx md={5} sm={12} xs={12} xxs={12} className="my-auto">
                                        <strong>Property Key from ChatBot</strong>
                                    </Colxx>
                                    <Colxx className="my-auto text-center">
                                        <span className="fa fa-arrow-right d-none d-md-block"/>
                                        <span
                                            className="fa fa-level-up-alt fa-rotate-90 d-md-none d-xs-block d-sm-block"/>
                                    </Colxx>
                                    <Colxx md={5} sm={10} xs={10} xxs={8}>
                                        <FormGroup className="mb-0">
                                            <strong>Promo Entry property</strong>
                                        </FormGroup>
                                    </Colxx>

                                    <Colxx className="my-auto text-center">
                                    </Colxx>
                                </Row>

                                {Object.keys(this.state.mapping).length > 0 ? Object.keys(this.state.mapping).map(k => this.renderMap(k)) : <Row>
                                    <Colxx className="text-muted">
                                        No mapping configured. Add one now!
                                    </Colxx>
                                </Row>}

                                <Button color="primary" className="mt-2"
                                        onClick={this.addRow.bind(this)}
                                        disabled={unusedFieldValues.length === 0 || usedFieldValues.length >= selectEntryFields.length}>
                                    <span className="fa fa-plus"/> Add
                                </Button>
                                <Button color="primary" className="mt-2"
                                        disabled={!this.isDataChanged() || !valuesValid}
                                        onClick={this.handleSave.bind(this)}>
                                    <span className="fa fa-save"/> Save
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>

            <PromoIndexes promoId={promoID} ref={this.promoIndexesRef} onHasUnsavedChanged={(status) => {
                this.setState({indexesHasUnsavedChanges: status})
            }}/>
        </>
    }
}

export default PromoEntryMapping;