import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Tagging from "./tagging";
import TrackingPoint from "./trackingPoint";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import Report from "./report";


export default class DialogueTracking extends Component {
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/report`} />
                <Route path={`${match.url}/report`} component={Report} />

                <Route path={`${match.url}/tagging`} component={Tagging} />
                <Route path={`${match.url}/tracking-point`} component={TrackingPoint} />

                <Redirect to="/error" />
            </Switch>
        );
    }
}
