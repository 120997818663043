import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Label,
    Modal,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import "../../assets/css/bootstrap.extra.css";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import moment from "moment";
import {DisputeModalContent} from "./disputeModalContent";
import Loading from "../../components/loading";
import {EntryFilter} from "./entryFilter";
import TablePagination from "../../components/tablePagination";


class DisputeList extends Component {
    disputeApi = new ApiClient('dispute', authenticationErrorHandler.bind(this))
    promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))

    state = {
        disputes: [],
        limit: 20,
        page: 1,
        hasPrevious: false,
        hasNext: false,
        totalCount: 0,
        filters: {
            // has_attachment_only: false,
            include_invalid: true
        },
        filterOptions: {},

        promoTitle: '',

        sort: '',

        modalDispute: null,

        isResolved: false,
        isLoadingDisputes: true
    }

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    fetchPromoName(promoID) {
        if (promoID) {
            this.promoApi.getItem(promoID).then(response => {
                this.setState({promoTitle: response.data.title})
            })
        } else {
            this.setState({promoTitle: ""})
        }
    }

    fetchDisputes() {
        const params = {
            limit: this.state.limit,
            offset: (this.state.page - 1) * this.state.limit,
            ...this.state.filters
        }

        const {match} = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            params["promo_id"] = promoID
        }
        if (this.state.sort) {
            params['sort'] = this.state.sort
        }

        params['is_resolved'] = this.state.isResolved;
        this.setState({isLoadingDisputes: true}, () => {

            this.disputeApi.list({params: params}).then(response => {
                this.setState({
                    hasNext: !!response.data.next,
                    hasPrevious: !!response.data.previous,
                    disputes: response.data.results,
                    totalCount: response.data.count,
                    isLoadingDisputes: false
                    // modalDispute: response.data.results.slice(-1)[0].id  // TODO: remove this
                })
            })
        })
    }

    fetchFilters() {
        const {match} = this.props;
        const promoID = match.params.promoID

        const params = {}
        if (promoID) {
            params["promo_id"] = promoID
        }

        this.disputeApi.getItem('filters', {params: params}).then(response => {
            this.setState({filterOptions: response.data})
        })
    }

    componentDidMount() {
        this.fetchDisputes()
        this.fetchFilters()

        const {match} = this.props;
        const promoID = match.params.promoID

        this.fetchPromoName(promoID)
    }

    goToPage(pageNumber) {
        this.setState({page: pageNumber}, () => {
            this.fetchDisputes()
        })
    }


    handleChangeFilterValue(fullFilterKey, filterOption) {
        this.setState(prevState => {
            return {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    [fullFilterKey]: filterOption ? filterOption.value : undefined
                }
            }
        })
    }

    toggleSort(fieldName) {
        this.setState(prevState => {
            let sortValue = ''
            if (prevState.sort === fieldName) {
                sortValue = '-' + fieldName
            } else if (prevState.sort === '-' + fieldName) {
                sortValue = ''
            } else {
                sortValue = fieldName
            }
            return {
                ...prevState,
                sort: sortValue
            }
        }, () => {
            this.fetchDisputes()
        })
    }

    toggleModalDispute() {
        this.setState({modalDispute: null})
    }

    disputeResolved(disputeId) {

        this.setState(prevState => {

            return {
                ...prevState,
                disputes: [...prevState.disputes.filter(dispute => dispute.id !== disputeId)],
                modalDispute: null
            }
        })
    }

    applyFilters(filterValues) {
        this.setState({filters: filterValues}, () => {
            this.fetchDisputes()
        })
    }


    renderModalContent() {
        return <DisputeModalContent
            key={this.state.modalDispute} disputeId={this.state.modalDispute}
            close={this.toggleModalDispute.bind(this)}
            resolve={this.disputeResolved.bind(this, this.state.modalDispute)}
            location={this.props.location}
        />
    }

    renderTable() {
        if (this.state.isLoadingDisputes) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }

        if (this.state.disputes.length <= 0) {
            return <Row>
                <Colxx className="text-center">
                    No disputes available to display.
                </Colxx>
            </Row>
        }

        return <Row>
            <Colxx style={{overflow: "scroll"}}>

                <Table hover striped>
                    <thead>
                    <tr>
                        <th>Dispute #</th>

                        <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__name')}>
                            Name
                            {'disputed_by_entry__name' === this.state.sort ?
                                <span className="fa fa-caret-up"/> : ''}
                            {"-disputed_by_entry__name" === this.state.sort ?
                                <span className="fa fa-caret-down"/> : ''}
                        </th>

                        {/*<th onClick={this.toggleSort.bind(this, 'disputed_by_entry__email_address')}>*/}
                        {/*    Email Address*/}
                        {/*    {'disputed_by_entry__email_address' === this.state.sort ?*/}
                        {/*        <span className="fa fa-caret-up"/> : ''}*/}
                        {/*    {"-disputed_by_entry__email_address" === this.state.sort ?*/}
                        {/*        <span className="fa fa-caret-down"/> : ''}*/}
                        {/*</th>*/}

                        <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__mobile_number')}>
                            Mobile Number
                            {'disputed_by_entry__mobile_number' === this.state.sort ?
                                <span className="fa fa-caret-up"/> : ''}
                            {"-disputed_by_entry__mobile_number" === this.state.sort ?
                                <span className="fa fa-caret-down"/> : ''}
                        </th>
                        <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__raw_code')}>
                            Code
                            {'disputed_by_entry__raw_code' === this.state.sort ?
                                <span className="fa fa-caret-up"/> : ''}
                            {"-disputed_by_entry__raw_code" === this.state.sort ?
                                <span className="fa fa-caret-down"/> : ''}
                        </th>
                        <th onClick={this.toggleSort.bind(this, 'datetime_created')}>
                            Dispute Date
                            {'datetime_created' === this.state.sort ?
                                <span className="fa fa-caret-up"/> : ''}
                            {"-datetime_created" === this.state.sort ?
                                <span className="fa fa-caret-down"/> : ''}
                        </th>
                        { this.state.isResolved && <th>Resolution</th> }
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.disputes.map(dispute => {
                            return <tr
                                key={"dispute-" + dispute.id}
                            >
                                <td>
                                    #{dispute.id} {dispute.get_dispute_type_display}
                                </td>

                                <td>
                                    {dispute.disputed_by_entry.name}
                                </td>
                                <td>
                                    {dispute.disputed_by_entry.mobile_number}
                                </td>
                                <td>
                                    {dispute.disputed_by_entry.raw_code}
                                </td>
                                <td title={moment(dispute.datetime_created).format('LLLL')}>
                                    {moment(dispute.datetime_created).fromNow()}
                                </td>

                                {
                                    this.state.isResolved &&
                                    <td title={dispute.resolution + " - " + dispute.resolution_notes}>
                                        {dispute.resolution.toString().toLocaleUpperCase()}
                                    </td>
                                }
                                <td>
                                    <ButtonGroup>
                                        {/*    <Button size="xs" outline onClick={*/}
                                        {/*        () => {*/}
                                        {/*            this.setState({*/}
                                        {/*                modalDispute: JSON.parse(JSON.stringify(entry))*/}
                                        {/*            })*/}
                                        {/*        }*/}
                                        {/*    } disabled={entry.attachments.length <= 0}>*/}
                                        {/*        <span className="fa fa-image"/><br/>*/}
                                        {/*        img*/}
                                        {/*    </Button>*/}
                                        <Button size="xs" outline onClick={
                                            () => {
                                                this.setState({
                                                    modalDispute: dispute.id
                                                })
                                            }
                                        }>
                                            <span className="fa fa-eye"/><br/>
                                            view
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
            </Colxx>
        </Row>
    }

    render() {
        const COL_TO_PROPERTY = {
            // 'ID': 'id',
            'Name': 'name',
            'Email Address': 'email_address',
            'Mobile Number': 'mobile_number',
            // 'Profile ID': 'profile_identifier',
        }

        const {match} = this.props;
        const promoID = match.params.promoID

        return <>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <button className="btn btn-primary float-right" onClick={() => {
                                    this.setState(prevState => {
                                        return {...prevState, isResolved: !prevState.isResolved}
                                    }, () => {
                                        this.fetchDisputes()
                                    })
                                }}>
                                    {this.state.isResolved ? "View unresolved disputes" : "View resolution history"}
                                </button>
                                <h1>
                                    {this.state.isResolved ? '' : 'Unresolved'}
                                    {" "}{this.state.promoTitle}{" "}
                                    {this.state.isResolved ? 'Dispute Resolution History' : 'Disputes'}
                                </h1>
                            </CardTitle>


                            <EntryFilter
                                promoID={promoID}
                                applyHandler={this.applyFilters.bind(this)}
                                history={this.props.history}
                            />
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Colxx xxs="12" className="text-center">
                                    <TablePagination
                                        hasNext={this.state.hasNext}
                                        hasPrevious={this.state.hasPrevious}
                                        totalCount={this.state.totalCount}
                                        limit={this.state.limit}
                                        page={this.state.page}
                                        changePageHandler={this.goToPage.bind(this)}
                                    />
                                </Colxx>
                            </Row>

                            {this.renderTable()}


                            <div style={{right: 0}}
                                 className='pt-2 pr-4 position-absolute d-none d-lg-block d-xl-block'>
                                <select onChange={e => this.setState({limit: e.target.value})}>
                                    <option selected={this.state.limit === 10}>10</option>
                                    <option selected={this.state.limit === 20}>20</option>
                                    <option selected={this.state.limit === 50}>50</option>
                                    <option selected={this.state.limit === 100}>100</option>
                                </select> disputes per page
                            </div>

                            <Row>
                                <Colxx xxs="12" className="text-center">
                                    <TablePagination
                                        hasNext={this.state.hasNext}
                                        hasPrevious={this.state.hasPrevious}
                                        totalCount={this.state.totalCount}
                                        limit={this.state.limit}
                                        page={this.state.page}
                                        changePageHandler={this.goToPage.bind(this)}
                                    />
                                </Colxx>
                            </Row>

                            <Modal
                                size="xl"
                                isOpen={!!this.state.modalDispute}
                                toggle={this.toggleModalDispute.bind(this)}>
                                {this.renderModalContent()}
                            </Modal>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        </>
    }
}

export default DisputeList