import React, {Component} from 'react';
import {Row} from "reactstrap";
import {Colxx} from "../../components/CustomBootstrap";
import {dictToRows, keyValueToRow} from "../../util/RenderUtils";
import ImgLoading from "../../components/imgLoading";


export default class EntryDetail extends Component {
    state = {
        showOriginalData: false
    }

    render() {
        if (!this.props.entry) {
            return <>Loading</>
        }

        return <>
            {keyValueToRow("Code", <b>{this.props.entry.raw_code}</b>)}
            {
                dictToRows(this.props.entry, ['raw_code', 'id', 'entry_errors', 'has_error', 'is_active'])
            }
            {
                dictToRows(this.props.entry.additional_data)
            }
            {
                this.props.entry.attachments.length > 0 && this.props.entry.attachments[0].file && <>
                    <Row className="mt-4">
                        <Colxx><strong>Attachment</strong></Colxx>
                        <colxx className="text-right pr-3">
                            <a href={this.props.entry.attachments[0].file} target="_blank">
                                Open in a new tab <span className="fa fa-external-link-alt"/>
                            </a>
                        </colxx>
                    </Row>
                    <Row>
                        <Colxx>
                            <ImgLoading src={this.props.entry.attachments[0].file}/>
                        </Colxx>
                    </Row>
                </>
            }

            <Row className="mt-4" onClick={() => {this.setState(prevState =>{
                return {...prevState, showOriginalData: !prevState.showOriginalData}
            })}}>
                <Colxx><strong>Original Data</strong></Colxx>
                <Colxx className="text-right">
                    {this.state.showOriginalData ? 'hide': 'show'}{" "}
                    <span className={"fa fa-chevron-" + (this.state.showOriginalData ? 'down' : 'right')}/>
                </Colxx>
            </Row>
            <Row className="px-2" hidden={!this.state.showOriginalData}>
                <Colxx style={{textOverflow: "ellipsis", overflow: "hidden"}} className="border-1px m-2 p-2">
                    {console.log(this.props.entry.raw_data, dictToRows(this.props.entry.raw_data))}
                    {
                        dictToRows(this.props.entry.raw_data)
                    }
                </Colxx>
            </Row>
        </>
    }
}