import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { Row, Card, CardBody, CardTitle, NavLink, Table } from "reactstrap";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import "chartjs-plugin-datalabels";
import "react-circular-progressbar/dist/styles.css";
import TinyLineChartAds from "../../../components/Rechart/TinyLineAds";
import TinyLineChartRPM from "../../../components/Rechart/TinyLineChartRPM";

import { Sparklines, SparklinesLine, SparklinesBars } from "react-sparklines";

const sparklineData = [936, 968, 1025, 999, 998, 1014, 1017, 1010, 1010, 1007];
const sparklineSubs = [936, 968, 936, 930, 998, 1014, 1014, 1010, 900, 1007];

export default class advertsDashboard extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.blogging" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          {/* Adverts Fist Row  starts here */}
          <Colxx className="mb-4 bg-theme-1" sm="12" md="12" lg="12" xl="12">
            <Row>
              <Colxx xs="12" sm="6" md="6" lg="6" xl="3" className="mb-4">
                <Card className="bg-theme-1 boxshadow-0">
                  <CardBody>
                    <CardTitle>Estimated Earnings</CardTitle>
                    <div className="ml-3">
                      <IntlMessages id="adverts.todays-so-far" />
                      <h3>
                        <strong>$345</strong>
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xs="12" sm="6" md="6" lg="6" xl="3" className="mb-4">
                <Card className="bg-theme-1 boxshadow-0">
                  <CardBody>
                    <div className="ml-3">
                      <IntlMessages id="adverts.yesterday" />
                      <h3>
                        <strong>$745</strong>
                      </h3>
                      <p className="mt-3">
                        <span className="fa fa-level-up-alt mr-2" />
                        $1.86 (56%)
                      </p>
                      <p className="lineheight-0">
                        <IntlMessages id="adverts.last-week" />
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xs="12" sm="6" md="6" lg="6" xl="3" className="mb-4">
                <Card className="bg-theme-1 boxshadow-0">
                  <CardBody>
                    <div className="ml-3">
                      <IntlMessages id="adverts.last-week" />
                      <h3>
                        <strong>$$3345</strong>
                      </h3>
                      <p className="mt-3">
                        <span className="fa fa-level-up-alt mr-2" />
                        $141.86 (6%)
                      </p>
                      <p className="lineheight-0">
                        <IntlMessages id="adverts.last-seven-days" />
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xs="12" sm="6" md="6" lg="6" xl="3" className="mb-4">
                <Card className="bg-theme-3 boxshadow-0 mt-3">
                  <CardBody>
                    <div className="ml-3">
                      <span className="fab fa-google-wallet" />{" "}
                      <IntlMessages id="adverts.balance" />
                      <h3>
                        <strong>$10987</strong>
                      </h3>
                      <p className="mt-3">
                        <IntlMessages id="adverts.last-payment" />
                      </p>
                      <p className="lineheight-0">$8768</p>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
          {/* Adverts First Row Ends Here */}

          {/* Chart starts here */}
          <Colxx sm="12" md="12" lg="12" xl="8" className="mb-4">
            <Row>
              <Colxx sm="6" md="6" lg="4" xl="4" className="mb-4">
                <Card>
                  <CardBody>
                    <p>Page Views</p>
                    <strong>109K</strong>
                    <p className="font-color-1">+7.32K (+31%)</p>

                    <TinyLineChartAds />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="12" md="12" lg="12" xl="4" className="mb-4">
                <Card>
                  <CardBody>
                    <p>Page RPM</p>
                    <strong>$0.89</strong>
                    <p className="text-danger">-$0.88 (-48%)</p>

                    <TinyLineChartRPM />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="12" md="12" lg="12" xl="4" className="mb-4">
                <Card>
                  <CardBody>
                    <p>Page Views</p>
                    <strong>109K</strong>
                    <p className="font-color-1">+7.32K (+31%)</p>

                    <TinyLineChartAds />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="6" md="6" lg="4" xl="4" className="mb-4">
                <Card>
                  <CardBody>
                    <p>Page Views</p>
                    <strong>109K</strong>
                    <p className="font-color-1">+7.32K (+31%)</p>

                    <TinyLineChartAds />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="12" md="12" lg="12" xl="4" className="mb-4">
                <Card>
                  <CardBody>
                    <p>Page Views</p>
                    <strong>109K</strong>
                    <p className="font-color-1">+7.32K (+31%)</p>

                    <TinyLineChartAds />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx sm="12" md="12" lg="12" xl="4" className="mb-4">
                <Card>
                  <CardBody>
                    <p>Page Views</p>
                    <strong>109K</strong>
                    <p className="font-color-1">+7.32K (+31%)</p>

                    <TinyLineChartAds />
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
          {/* Chart Ends here */}

          {/* Team messages start here */}
          <Colxx sm="12" xs="12" md="12" lg="6" xl="4" className="mb-4">
            <Card className="pt-4 justify-content-center">
              <CardBody>
                <Table borderless>
                  <thead>
                    <tr>
                      <th className="text-uppercase">sites</th>
                      <th className="text-uppercase">visits</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <span className="fab fa-google mr-1 font-color-3" />{" "}
                          Google
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-danger">-8</sup>{" "}
                        <span>12,543</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            style={{
                              height: "calc(30% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesBars
                              style={{
                                stroke: "white",
                                strokeWidth: "1",
                                fill: "#922c88"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <img
                            className="w-15px mr-1"
                            src="/assets/img/bing.png"
                          />{" "}
                          Bing
                        </NavLink>
                      </th>

                      <td>
                        {" "}
                        <sup className="text-success">+5</sup>{" "}
                        <span>11,963</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                            height={20}
                          >
                            <SparklinesLine
                              style={{
                                stroke: "white",
                                strokeWidth: "1",
                                fill: "#922c88"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <img
                            className="w-15px mr-1"
                            src="/assets/img/envato.png"
                          />{" "}
                          Envato
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-success">+10</sup>{" "}
                        <span>9,965</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            margin={6}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesLine
                              style={{
                                strokeWidth: 3,
                                stroke: "#922c88",
                                fill: "none"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <span className="fab fa-facebook-f mr-1 font-color-2" />{" "}
                          Facebook
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-success">+7</sup>{" "}
                        <span>4,223</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            margin={6}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesLine
                              style={{
                                strokeWidth: 3,
                                stroke: "#922c88",
                                fill: "none"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <NavLink className="px-0 py-0" href="#">
                          <span className="fab fa-yahoo mr-1 font-color-1" />{" "}
                          Yahoo
                        </NavLink>
                      </th>
                      <td>
                        <sup className="text-danger">-3</sup> <span>3,437</span>
                        <sup>
                          <Sparklines
                            data={sparklineData}
                            margin={6}
                            style={{
                              height: "calc(35% - 38px)",
                              width: "40px"
                            }}
                          >
                            <SparklinesLine
                              style={{
                                strokeWidth: 3,
                                stroke: "#922c88",
                                fill: "none"
                              }}
                            />
                          </Sparklines>
                        </sup>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
          {/* Team Messages End here  */}
        </Row>
      </Fragment>
    );
  }
}
