import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import ApiHookList from "./apiHookList";
import ApiHookForm from "./apiHookForm";


export default class ApiHookIndex extends Component {
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
                <Route path={`${match.url}/list`} component={ApiHookList} />

                <Route key="new-webhook" path={`${match.url}/new`} component={ApiHookForm} />
                <Route key="edit-webhook" path={`${match.url}/:webhookUid`} component={ApiHookForm} />

                <Redirect to="/error" />
            </Switch>
        );
    }
}
