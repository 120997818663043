import {Row} from "reactstrap";
import {Colxx} from "../components/CustomBootstrap";
import React from "react";

export function titleCase(value) {
    return value.replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b(\w)/g, s => s.toUpperCase());
}

export function keyValueToRow(key, value) {
    const displayKey = titleCase(key)
    return <Row key={"row-" + key}>
        <Colxx md={4} sm={4} xs={4} xxs={4}>{displayKey}</Colxx>
        <Colxx md={8} sm={8} xs={8} xxs={8}>{value}</Colxx>
    </Row>
}

export function dictToRows(dict, ignoreKeys) {
    if (!dict) {
        return
    }
    if (!ignoreKeys) {
        ignoreKeys = []
    }
    return Object.keys(dict).filter(
        k => {
            if (ignoreKeys.indexOf(k) !== -1 || !dict[k]) {
                return false
            } else if (Array.isArray(dict[k])) {
                return Object.values(dict[k]).every(v => typeof(v) === 'string')
            }
            return typeof (dict[k]) === 'string'
        }
    ).map(
        k => {
            const val = Array.isArray(dict[k]) ? dict[k].join(', ') : dict[k]
            return keyValueToRow(k, val)
        }
    )
}

export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    name=getInitials(name)

    const canvas=document.createElement('canvas')
    const context=canvas.getContext('2d')
    canvas.width=canvas.height=size

    context.fillStyle="#ffffff"
    context.fillRect(0,0,size,size)

    context.fillStyle=`${color}50`
    context.fillRect(0,0,size,size)

    context.fillStyle=color;
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`bold ${size/5*3}px Nunito`
    context.fillText(name,(size/2),(size/11*6))

    return canvas.toDataURL()
};

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};

export const  getRandomColor=()=> {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const stringToColour = function(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}
