import React, {Component} from 'react';
import Chart from "react-apexcharts";
import {ReactifyChartColors} from "../../../util/ReactifyChartColors";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Card, CardBody, CardDeck, CardTitle, Col, Row} from "reactstrap";
import Loading from "../../../components/loading";


const colors = ReactifyChartColors();


class BreakdownChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: [
                    colors.ReactifyChartColor1,
                    colors.ReactifyChartColor2,
                    colors.ReactifyChartColor3,
                    colors.ReactifyChartColor4
                ],
                labels: [],
                legend: {
                    show: true,
                    position: 'top'
                },
                dataLabels: {
                    offset: '30px'
                },
                plotOptions: {
                    pie: {
                    }
                }
            },
            legend: {
                show: false
            },
            series: [],

            data: {},
            isLoading: true,
            hasError: false
        };

        this.entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {

            this.entryApi.getItem('report_indexed_breakdown', {
                params: {
                    ...this.props.params,
                    breakdown_by: this.props.breakdownBy
                }
            }).then(response => {
                this.setState({data: response.data, isLoading: false})
            }).catch(() => {
                this.setState({data: {}, isLoading: false, hasError: true})
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const keys = Object.keys(this.state.data)
        return (

            <Card>
                <CardBody>
                    <CardTitle>
                        <h2>
                            {this.props.title}

                        </h2>
                        <div className={"float-right position-absolute"} style={{right: '1em', top: '1em'}}>
                            {this.state.isLoading && <Loading/>}
                        </div>
                    </CardTitle>
                    <Chart
                        options={{
                            ...this.state.options,
                            labels: [...keys],
                            noData: {
                                text: this.state.hasError ? 'Error loading!' : (this.state.isLoading ? 'Loading ...' : 'No Data')
                            }
                        }}
                        series={keys.map(k => this.state.data[k])}
                        type="pie"
                        width="100%"
                        height="280px"
                    />
                </CardBody>
            </Card>
        );
    }
}

export default class BreakdownChartsWrapper extends Component {
    state = {
        'renders': []
    }

    constructor(props) {
        super(props);
        this.entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
        this.chartRefs = []
    }

    loadRenderKeys() {
        this.entryApi.getItem('report_indexed_breakdown', {
            params: {...this.props.params}
        }).then(response => {
            this.chartRefs = [...Object.values(response.data).map((val) => {
                return React.createRef()
            })]

            this.setState({renders: response.data})
        })
    }

    loadData() {
        console.log('loadDataCalled', this.chartRefs)
        this.chartRefs.map(r => {
            r.current.loadData()
        })
    }

    componentDidMount() {
        this.loadRenderKeys()
    }

    render() {
        return <CardDeck>
            {
                this.state.renders.map((k, idx) => <BreakdownChart
                        key={k.key}
                        breakdownBy={k.key}
                        title={k.label}
                        history={this.props.history}
                        ref={this.chartRefs[idx]}
                        params={this.props.params}
                    />
                )
            }

        </CardDeck>
    }
}