import React, {Component} from "react";
import GetWeatherInfo from "../../../components/Weather/GetWeatherInfo";
import {InfoCard} from "../../promo/report/InfoCard";
import {Col, Row} from "reactstrap";
//
// import "chartjs-plugin-datalabels";
// import "react-circular-progressbar/dist/styles.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import "react-table-6/react-table.css";
import "../../../index.css"
import Users from "./users";
import Promo from "./promo";
import Connections from "./connections";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import Loading from "../../../components/loading";

class DefaultDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            welcomeDashboards: {},
            isLoading: true,
            hasError: false
        };

        this.welcomeAPI = new ApiClient('welcome_dashboard', authenticationErrorHandler.bind(this))
    }

    loadWelcomeDashboards() {
        this.setState({isLoading: true}, () => {
            this.welcomeAPI.list().then(response => {
                this.setState({welcomeDashboards: response.data, isLoading: false})
            }).catch(() => {
                this.setState({hasError: true, isLoading: false})
            })
        })
    }

    componentDidMount() {
        this.loadWelcomeDashboards()
    }

    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }

        return <React.Fragment>
            <h1>Welcome!</h1>

            { this.state.hasError && <p>Something went wrong. Cannot load the welcome page.</p> }

            {
                this.state.welcomeDashboards.user && <Users
                    history={this.props.history}
                    url={this.state.welcomeDashboards.user}
                />
            }

            {
                this.state.welcomeDashboards.promo && <Promo
                    history={this.props.history}
                    url={this.state.welcomeDashboards.promo}
                />
            }

            {
                this.state.welcomeDashboards.webhook && <Connections
                    history={this.props.history}
                    url={this.state.welcomeDashboards.webhook}
                />
            }


        </React.Fragment>
    }
}

export default DefaultDashboard;
