import React, {Component} from "react";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Row} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import TrackingApi from "./trackingApi";
import Loading from "../../../components/loading";
import {downloadCSV} from "../../../util/Utils";


export default class TrackingPoint extends Component {
    constructor(props) {
        super(props);

        this.api = new ApiClient('tracking_value', authenticationErrorHandler.bind(this))
        this.state = {
            isDataLoading: true,
            hasDataError: false,
            isTagsLoading: true,
            hasTagsError: false,
            isLoading: true,
            hasError: false,
            data: [],
            tagDefinitions: {},

            searchText: "",
        }
    }

    componentDidMount() {
        this.loadData()
        this.loadTags()
    }

    loadData() {
        this.setState({isLoading: true, hasError: false, isDataLoading: true, hasDataError: false}, () => {
            this.api.list().then(response => {
                this.setState(prevState => ({
                    isLoading: prevState.isTagsLoading,
                    isDataLoading: false,
                    data: response.data
                }))
            }).catch(() => {
                this.setState(prevState => ({
                    isLoading: prevState.isTagsLoading,
                    isDataLoading: false,
                    hasDataError: true, hasError: true}))
            })
        })
    }
    loadTags() {
        this.setState({isLoading: true, hasError: false, isTagsLoading: true, hasTagsError: false}, () => {
            this.api.getItem('tags').then(response => {
                this.setState(prevState => ({
                    isLoading: prevState.isDataLoading,
                    isTagsLoading: false,
                    tagDefinitions: {
                        ...response.data.reduce((acc, tag) => {
                            acc[tag.id] = tag.__str__
                            return acc
                        }, {})
                    }
                }))
            }).catch(() => {
                this.setState(prevState=> ({
                    isLoading: prevState.isDataLoading,
                    isTagsLoading: false,
                    hasTagsError: true, hasError: true}))
            })
        })
    }

    renderContent() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center mt-4">
                    <Loading/>
                </Col>
            </Row>
        }
        if (this.state.hasError) {
            return <Row>
                <Col className="text-center mt-4">
                    Something went wrong while loading the data. Please refresh. If problem persists, report to administrator. <br/>
                    <Button color="" onClick={() => {
                        if (this.state.hasTagsError) {
                            this.loadTags()
                        }
                        if (this.state.hasDataError) {
                            this.loadData()
                        }
                    }}>
                        <span className="fa fa-sync"/>
                    </Button>
                </Col>
            </Row>
        }

        if (this.state.data.length === 0) {
            return <Row>
                <Col className="text-center mt-4">
                    No tracking points received from ChatBot yet.
                </Col>
            </Row>
        }
        const data = this.state.data.filter(point => {
            return !this.state.searchText || point.value.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0
        })

        if (data.length === 0) {
            return <Row>
                <Col className="text-center mt-4">
                    No tracking points matching your filters.
                </Col>
            </Row>
        }

        const groupedData = data.reduce((acc, row) => {
            if (!acc.hasOwnProperty(row.webhook.uid)) {
                acc[row.webhook.uid] = {
                    'name': row.webhook.name,
                    'uid': row.webhook.uid,
                    'points': []
                }
            }
            acc[row.webhook.uid].points.push(row)
            return acc
        }, {})

        return Object.values(groupedData).map(trackingApi => {
            return <TrackingApi
                api={this.api}
                key={trackingApi.uid}
                uid={trackingApi.uid}
                name={trackingApi.name}
                points={trackingApi.points}
                tagDefinitions={this.state.tagDefinitions}
                onUpdatePoint={(pointId, data) => {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            data: [
                                ...prevState.data.map(point => {
                                    if (point.id === pointId){
                                        return {...point, ...data}
                                    } else {
                                        return point
                                    }
                                }),
                            ]
                        }
                    })
                }}
            />
        })
    }

    render() {
        return <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="pb-0 mb-0">
                                <h1 className="pb-0 mb-0">Dialogue Tracking Points</h1>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <Label>Search</Label>
                                    <Input type="text" value={this.state.searchText} onChange={e => this.setState({searchText: e.target.value})}/>
                                </Col>
                                <Col className="d-flex justify-content-end align-items-end">
                                    <Button onClick={() => {
                                        const csvData = [...this.state.data.map(point => {
                                            return {
                                                connection: point.webhook.name,
                                                "tracking point": point.value,
                                                tags: point.tag_ids.map(tagId => this.state.tagDefinitions[tagId]).join(', '),
                                                "is hidden": point.is_active ? "FALSE" : "TRUE"
                                            }
                                        })]
                                        downloadCSV(csvData)
                                    }}>
                                        Download CSV
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {this.renderContent()}
        </>
    }
}