import React, {Component} from "react";
import {Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Select from "react-select";
import {toast} from "react-toastify";


export default class TrackingApiValueTagging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalData: [...this.props.tags],
            data: [...this.props.tags],
            isSaving: false,
            confirmHide: false
        }
    }

    save() {
        this.setState({isSaving: true}, () => {

            this.props.api.patchItem(this.props.id, {tag_ids: [...this.state.data]}).then(response => {
                this.props.onFinishedSave(response.data)
                toast.success("Updated tags of " + this.props.value)
            }).catch(() => {
                this.setState({isSaving: false})
                toast.error("Something went wrong while saving. If issue persists, please contact administrator.")
            })
        })
    }

    hasDataChanged() {
        return !(
            this.props.tags.length === this.state.data.length &&
            this.state.data.every(tagId => this.props.tags.indexOf(tagId) > -1)
        )
    }

    toggleHide() {
        if (this.state.confirmHide) {
            this.setState({isSaving: true}, () => {
                this.props.api.postItemAction(this.props.id, 'is_active').then(response => {
                    this.props.onFinishedSave(response.data)
                    if (!response.data.is_active) {
                        toast.error("Tracking point " + this.props.value + " is now hidden.")
                    } else {
                        toast.success("Tracking point " + this.props.value + " is now available for tagging.")
                    }

                }).catch(() => {
                    this.setState({isSaving: false})
                    toast.error("Something went wrong while saving. If issue persists, please contact administrator.")
                })
            })
        } else {
            this.setState({confirmHide: true})
        }
    }

    render() {
        const tagOptions = [
            ...Object.keys(this.props.tagDefinitions).map(tagId => ({
                label: this.props.tagDefinitions[tagId],
                value: tagId,
                group: this.props.tagDefinitions[tagId].split(':')[0]
            }))
        ]
        const groupedOptions = [
            ...Object.values(tagOptions.reduce((acc, o) => {
                if (!acc.hasOwnProperty(o.group)) {
                    acc[o.group] = {
                        label: o.group,
                        options: []
                    }
                }
                acc[o.group].options.push(o)
                return acc
            }, {}))
        ]
        const selectedOptions = tagOptions.filter(o => this.state.data.indexOf(parseInt(o.value)) > -1)


        return <Modal isOpen={true}>

            <ModalHeader>
                {this.props.pointValue}
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col>

                        <Label>Tagging</Label>
                        <Select
                            disabled={!this.props.isActive}
                            isMulti={true}
                            className="react-select small"
                            classNamePrefix="react-select"
                            options={groupedOptions}

                            value={selectedOptions}
                            onChange={options => {
                                this.setState({data: [...options.map(o => parseInt(o.value))]})
                            }}
                            // onChange={this.props.onFilterChange}
                        />
                    </Col>
                </Row>

                <Row className="pt-2">

                    <Col className="text-center justify-content-center d-flex">
                        {
                            this.props.isActive ?
                                <Button
                                    color="danger" className="float-left"
                                    onClick={this.toggleHide.bind(this)}
                                    onBlur={() => {
                                        this.setState({confirmHide: false})
                                    }}>
                                    <span className="fa fa-eye-slash"/>{" "}
                                    {
                                        this.state.confirmHide ?
                                            <>
                                                Are you sure?<br/>
                                                This will remove all tags and hide this tracking point.
                                            </> :
                                            'Hide Tracking Point'
                                    }
                                </Button> :
                                <Button
                                    color="success" className="float-left"
                                    onClick={this.toggleHide.bind(this)}
                                    onBlur={() => {
                                        this.setState({confirmHide: false})
                                    }}>
                                    <span className="fa fa-eye"/>{" "}
                                    {
                                        this.state.confirmHide ? 'Are you sure?' : 'Show Tracking Point'
                                    }
                                </Button>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>

                <Button
                    onClick={this.save.bind(this)}
                    size="sm" color="success"
                    disabled={this.state.isSaving || !this.hasDataChanged()}
                >
                    {this.state.isSaving ? 'Saving...' : 'Save'}
                </Button>
                <Button
                    disabled={this.state.isSaving}
                    onClick={this.props.onClose} size="sm" color="danger">Cancel</Button>
            </ModalFooter>
        </Modal>
    }
    }