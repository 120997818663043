import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

const rootEl = document.getElementById("root");
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
} else {
  console.log("Running iokidigital-app@" + process.env.REACT_APP_VERSION + " in environment " + process.env.NODE_ENV)
  Sentry.init({
    dsn: "https://09f6805dbc3a4151918bfc480b7bd1aa@o102310.ingest.sentry.io/6244097",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    release: "iokidigital-app@" + process.env.REACT_APP_VERSION,
  });
}
let render = () => {
  const MainApp = require("./App").default;
  ReactDOM.render(<MainApp />, rootEl);
};

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(<NextApp />, rootEl);
  });
}

render();
