import React, {Component} from "react";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Row} from "reactstrap";
import {Colxx} from "../../../components/CustomBootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CustomSelect from "../../../components/CustomSelect";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import Loading from "../../../components/loading";
import axios from "axios";
import {winSettingOptions} from "./constants";

const CancelToken = axios.CancelToken;


export default class DrawWinners extends Component {
    state = {
        target: 10,
        startDate: null,
        endDate: null,
        filterDefinitions: [],
        filters: {},
        winnerUniqueness: 'unique_all_time',
        drawKey: '',

        isLoadingFilterDefinitions: true,
        hasErrorFilterDefinitions: false,


        eligibleCount: null,
        isLoadingEligibleCount: true,
        hasErrorEligibleCount: false
    }

    entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
    drawApi = new ApiClient('draw', authenticationErrorHandler.bind(this))
    eligibleCountAxiosSource = null
    fetchFilterDefinitions() {
        this.setState({
            isLoadingFilterDefinitions: true,
            hasErrorFilterDefinitions: false
        }, () => {

            this.entryApi.get('filters/', {params: {promo_id: this.props.promoID}}).then(response => {
                this.setState({
                    filterDefinitions: response.data,
                    isLoadingFilterDefinitions: false
                })
            }).catch(() => {
                this.setState({isLoadingFilterDefinitions: true, hasErrorFilterDefinitions: true})
            })
        })
    }

    componentDidMount() {
        this.fetchFilterDefinitions()
        this.loadEligibleEntries()
    }


    handleChangeDateRange(dates) {
        const [start, end] = dates;
        this.setState({
            startDate: start,
            endDate: end
        }, () => {
            this.loadEligibleEntries()
        })
    }

    handleChangeFilterValue(fullFilterKey, filterOption) {
        this.setState(prevState => {
            return {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    [fullFilterKey]: filterOption ? filterOption.value : undefined
                }
            }
        }, () => {
            this.loadEligibleEntries()
        })
    }

    generateDraw() {
        const postData = {
            promo: this.props.promoID,
            draw_key: this.state.drawKey,
            target_count: this.state.target,
            winner_uniqueness: this.state.winnerUniqueness,
            entry_filters: {
                ...this.state.filters,
            }
        }
        if (this.state.startDate) {
            postData.entry_filters['datetime_registered__gte'] = this.state.startDate
        }
        if (this.state.endDate) {
            postData.entry_filters['datetime_registered__lte'] = this.state.endDate
        }

        this.drawApi.create(postData).then(response => {
            this.setState({drawKey: ''})  // reset this
            this.props.generateDrawComplete(response.data)
        })
    }

    loadEligibleEntries() {
        const data = {
            promo: this.props.promoID,
            entry_filters: this.state.filters,
            winner_uniqueness: this.state.winnerUniqueness,
        }
        if (this.state.startDate) {
            data.entry_filters['datetime_registered__gte'] = this.state.startDate
        }
        if (this.state.endDate) {
            data.entry_filters['datetime_registered__lte'] = this.state.endDate
        }

        this.eligibleCountAxiosSource && this.eligibleCountAxiosSource.cancel("Axios operation cancelled due to updated filters.")

        this.eligibleCountAxiosSource = CancelToken.source()
        this.setState({isLoadingEligibleCount: true, hasErrorEligibleCount: false}, () => {
            this.drawApi.getItem('eligible_entries', {
                params: data,
                cancelToken: this.eligibleCountAxiosSource.token
            }).then(response => {
                console.log(response.request.responseURL, response.data)
                this.setState({
                    eligibleCount: response.data.count,
                    isLoadingEligibleCount: false
                })
            })
        })
    }

    renderFilter(filterDefinition, filterValues) {
        const filterKey = filterDefinition.key
        const fullFilterKey = (filterDefinition.is_raw ? 'raw_data__' : '') + filterKey

        const selectedValue = filterValues[fullFilterKey]
        const selectedOption = selectedValue ? filterDefinition.values.filter(options => options.value === selectedValue)[0] : null
        const displayKey = filterKey.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());  // titleCase

        // TODO: add download entries
        return <Colxx key={"filter-" + filterKey}>
            <Label>{displayKey}</Label>
            <Select
                isClearable={true}
                components={{Input: CustomSelect}}
                className="react-select small"
                classNamePrefix="react-select"
                name={"filter-" + filterKey}
                value={selectedOption}
                placeholder={"Select " + displayKey}
                onChange={this.handleChangeFilterValue.bind(this, fullFilterKey)}
                options={filterDefinition.values}
            />
        </Colxx>
    }

    renderFilterDefinitions() {
        if (this.state.isLoadingFilterDefinitions) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        if (this.state.hasErrorFilterDefinitions) {
            return <Row>
                <Col className="text-center">
                    Something went wrong while loading entry filters.
                </Col>
            </Row>
        }

        if (Object.keys(this.state.filterDefinitions).length === 0) {
            return <Row>
                <Col className="text-center text-muted">
                    No entry filters available.
                </Col>
            </Row>
        }

        return <>
            <Row>
                <Colxx>
                    <h4>Filter Entries</h4>
                </Colxx>
            </Row>
            <Row className="align-items-end d-flex">
                {
                    this.state.filterDefinitions.map(filterOptionDefinition => this.renderFilter(filterOptionDefinition, this.state.filters))
                }
            </Row>
        </>
    }

    render() {
        const maxDate = new Date();

        const canGenerateDraw = !!this.state.drawKey;

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>New Draw for {this.props.promoTitle}</h2>
                        </CardTitle>

                        <Row>
                            <Colxx>
                                <Row>
                                    <Colxx className="mb-2" xl={3} lg={6}>
                                        Draw Key
                                        <Input type="text"
                                               size="lg"
                                               value={this.state.drawKey}
                                               onChange={e => this.setState({drawKey: e.target.value})}
                                               placeholder={"Set Draw Key"}
                                               style={{fontWeight: "bold", fontSize: "1.2em"}}
                                        />
                                        <small>Unique draw identifier. Could be a short description or title of the draw. Example: final-draw-2022-Dec</small>
                                    </Colxx>
                                    <Colxx className="mb-2" xl={3} lg={6}>
                                        Draw random valid entries from
                                        <DatePicker
                                            key="entry_date_range"
                                            selectsRange
                                            selected={this.state.startDate}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleChangeDateRange.bind(this)}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="All time"
                                            isClearable={true}
                                            maxDate={maxDate}
                                            onCalendarClose={() => {
                                                if (!this.state.endDate) {
                                                    this.setState({startDate: null})
                                                }
                                            }}
                                        />
                                    </Colxx>
                                    <Colxx className="mb-2" xl={3} lg={6}>
                                        Winning profiles * {" "}
                                        <Select options={winSettingOptions}
                                                setValue={value => this.setState({winnerUniqueness: value})}
                                                onChange={newOption => this.setState({winnerUniqueness: newOption.value}, () => {this.loadEligibleEntries()})}
                                                value={winSettingOptions.filter(o => o.value === this.state.winnerUniqueness)[0]}
                                        />

                                        * <small>A unique profile is identified by mobile number</small>
                                    </Colxx>
                                    <Colxx className="mb-2" xl={3} lg={6}>
                                        Target number of winners
                                        <Input type="number"
                                               size="lg"
                                               onChange={e => this.setState({target: e.target.value})}
                                               value={this.state.target}/>

                                    </Colxx>

                                </Row>
                                <Row>
                                    <Colxx>
                                    </Colxx>
                                </Row>
                            </Colxx>
                        </Row>
                        <div className=" p-4 border-1px my-3">
                        {this.renderFilterDefinitions()}
                        </div>

                        <Row>
                            <Col>
                                <h4>
                                    Eligible Entries: {this.state.eligibleCount}{" "}
                                    {this.state.isLoadingEligibleCount && <span className="fa fa-sync spin"/>}
                                </h4>
                            </Col>
                            <Colxx className="text-right">
                                <Button color="success" disabled={!canGenerateDraw} onClick={this.generateDraw.bind(this)}>Generate Draw</Button>
                            </Colxx>
                        </Row>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}