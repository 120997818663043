import React, {Component} from "react";
import {ReactifyChartColors} from "../../util/ReactifyChartColors";

import Chart from "react-apexcharts";

const colors = ReactifyChartColors();

class DonutChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {

                legend: {
                    show: true,
                    position: props.legendPosition,

                    formatter: (seriesName, opts) => {
                        return [
                            seriesName,
                            " - ",
                            opts.w.globals.series[opts.seriesIndex].toLocaleString()
                        ]
                    }
                },
                chart: {
                    id: props.id,
                    toolbar: {
                        show: true,
                        export: {
                            svg: {
                                filename: props.title
                            },
                            png: {
                                filename: props.title
                            }
                        }
                    }
                }
            },
            data: {
                "USA": 44,
                "CA": 55,
                "UK": 13,
                "FRA": 43
            }
        };
    }

    render() {
        const data = this.props.data ? this.props.data : this.state.data
        const labels = Object.keys(data)
        const series = labels.map(l => data[l])
        return (
            <Chart
                options={{
                    ...this.state.options,
                    labels: [...labels],
                    title: {
                        text: this.props.title
                    }
                }}
                series={series}
                type="donut"
                width="100%"
                height="100%"
            />
        );
    }
}

export default DonutChart;
