import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Button, Card, Col, Row} from "reactstrap";
import {CustomizableChart} from "./customizableChart";
import Loading from "../../../components/loading";
import Title from "../../../components/Dashboard/title";
import ChartRow from "../../../components/Dashboard/chartRow";


export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.api = new ApiClient(props.reportApiObject, authenticationErrorHandler.bind(this))

        this.state = {
            isEditing: props.config.chart_config.length === 0,
            indexes: [],
            mailTemplates: [],
            tagNames: [],

            isLoadingIndexes: props.reportApiObject === 'email_history',
            isLoadingMailTemplates: props.reportApiObject === 'email_history',
            isLoadingTagNames: true,

            config: JSON.parse(JSON.stringify(props.config)),
            originalConfig: JSON.parse(JSON.stringify(props.config))
        }
    }

    componentDidMount() {
        if (this.props.reportApiObject === 'email_history') {

            this.loadIndexes()
            this.loadTemplates()
        }
        this.loadTagNames()
    }

    loadIndexes() {
        this.api.getItem('indexes').then(response => {
            this.setState({indexes: [...response.data.indexes], isLoadingIndexes: false})
        })
    }

    loadTemplates() {
        this.api.getItem('templates').then(response => {
            this.setState({mailTemplates: [...response.data.templates], isLoadingMailTemplates: false})
        })
    }

    loadTagNames() {
        this.api.getItem('tag_names').then(response => {
            this.setState({tagNames: [...response.data.tag_names], isLoadingTagNames: false})
        })
    }

    handleConfigChange(id, newConfig) {
        this.setState(prevState => {
            const newData = [
                ...prevState.config.chart_config.map(item => {
                    if (item.id === id) {
                        return {...item, ...newConfig}
                    } else {
                        return item
                    }
                })
            ].sort((a, b) => {
                if (a.row_position < b.row_position) {
                    return -1
                }
                if (a.row_position > b.row_position) {
                    return 1
                }
                return 0
            })
            // TODO: reindex row and row_position to always make a sequential data
            const rowValues = newData.map(row => row.row).sort().filter(function (el, i, a) {
                return i === a.indexOf(el)
            })
            const rowPositions = rowValues.reduce((acc, v, i) => {
                acc[i] = -1
                return acc
            }, {})
            const rowIndexedData = newData.map(row => {
                rowPositions[rowValues.indexOf(row.row)] += 1
                return {
                    ...row,
                    row: rowValues.indexOf(row.row),
                    row_position: rowPositions[rowValues.indexOf(row.row)]
                }
            })
            return {
                ...prevState,
                config: {
                    ...prevState.config,
                    chart_config: rowIndexedData
                }
            }
        })
    }

    renderDashboard() {
        const chartConfigs = this.state.config.chart_config
        const data = (!chartConfigs || Object.values(chartConfigs).length === 0) ? [] : chartConfigs

        if (this.state.isEditing && data.length === 0) {
            return <Row className='border-1px p-2 my-2'>
                <Col className="my-2 d-flex text-center align-items-center justify-content-center">
                    <Button onClick={() => {
                        this.setState(prevState => {
                            const lastId = Math.max(...prevState.config.chart_config.map(chart => chart.id))
                            return {
                                ...prevState,
                                config: {
                                    ...prevState.config,
                                    chart_config: [
                                        {
                                            id: 1,
                                            title: "Chart #1",
                                            row: 0,
                                            row_position: 0,
                                            width: 12,
                                            height: 1,
                                            filters: {},
                                            chart_type: "pie",
                                            dimensions: []
                                        }
                                    ]
                                }
                            }
                        })
                    }}>
                        <span className="fa fa-plus-circle"/>{" "}
                        Insert Chart
                    </Button>
                </Col>
            </Row>
        }

        const rows = Object.values(data.reduce((acc, item) => {
            if (!acc.hasOwnProperty(item.row)) {
                acc[item.row] = []
            }
            acc[item.row].push(item)
            return acc
        }, {}))

        return rows.map(row => <ChartRow
            hideEmailFilters={this.props.hideEmailFilters}
            isLoading={this.state.isLoadingMailTemplates || this.state.isLoadingIndexes || this.state.isLoadingTagNames}
            isEditing={this.state.isEditing}
            mailTemplates={this.state.mailTemplates}
            tagNames={this.state.tagNames}
            indexes={this.state.indexes}
            onChartChange={this.handleConfigChange.bind(this)}
            onDeleteChart={(chartId) => {
                this.setState(prevState => ({
                    ...prevState,
                    config: {
                        ...prevState.config,
                        chart_config: [
                            ...prevState.config.chart_config.filter(chart => chart.id !== chartId)
                        ]
                    }
                }))
            }}
            maxRow={Math.max(...Object.keys(rows))}
            api={this.api}
            filters={this.state.config.filters}
            row={row}
            onAddChart={(currentMaxRowPosition, rowOccupiedWidth) => {
                this.setState(prevState => {
                    const lastId = Math.max(...prevState.config.chart_config.map(chart => chart.id), 0)
                    return {
                        ...prevState,
                        config: {
                            ...prevState.config,
                            chart_config: [
                                ...prevState.config.chart_config,
                                {
                                    id: lastId + 1,
                                    title: "Chart #" + (lastId + 1).toString(),
                                    row: row[0].row,
                                    row_position: currentMaxRowPosition + 1,
                                    width: 12 - rowOccupiedWidth,
                                    filters: {},
                                    chart_type: "pie",
                                    dimensions: []
                                }
                            ]
                        }
                    }
                })

            }}
            onMoveUp={() => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        config: {
                            ...prevState.config,
                            chart_config: [
                                ...prevState.config.chart_config.map(chart => {
                                    if (chart.row === row[0].row) {
                                        return {
                                            ...chart,
                                            row: chart.row - 1
                                        }
                                    } else if (chart.row === row[0].row - 1) {
                                        return {
                                            ...chart,
                                            row: chart.row + 1
                                        }
                                    } else {
                                        return chart
                                    }

                                })
                            ]
                        }
                    }
                })
            }}
            onMoveDown={() => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        config: {
                            ...prevState.config,
                            chart_config: [
                                ...prevState.config.chart_config.map(chart => {
                                    if (chart.row === row[0].row) {
                                        return {
                                            ...chart,
                                            row: chart.row + 1
                                        }
                                    } else if (chart.row === row[0].row + 1) {
                                        return {
                                            ...chart,
                                            row: chart.row - 1
                                        }
                                    } else {
                                        return chart
                                    }

                                })
                            ]
                        }
                    }
                })
            }}
            onInsertRowAfter={() => {
                this.setState(prevState => {
                    const lastId = Math.max(...prevState.config.chart_config.map(chart => chart.id), 0)
                    const newId = lastId + 1
                    return {
                        ...prevState,
                        config: {
                            ...prevState.config,
                            chart_config: [
                                ...prevState.config.chart_config.map(chart => {
                                    if (chart.row > row[0].row) {
                                        return {
                                            ...chart,
                                            row: chart.row + 1
                                        }
                                    } else {
                                        return chart
                                    }

                                }),
                                {
                                    id: newId,
                                    title: "Chart #" + newId.toString(),
                                    row: row[0].row + 1,
                                    row_position: 0,
                                    width: 12,
                                    height: 1,
                                    filters: {},
                                    chart_type: "pie",
                                    dimensions: []
                                }
                            ]
                        }
                    }
                })
            }}
        />)
    }

    canSave() {
        return !this.state.config.can_edit
            || JSON.stringify(this.state.config) !== JSON.stringify(this.state.originalConfig)
    }

    render() {
        const titleRow = <Row>
                <Col>

                    <Title
                        hideEmailFilters={this.props.hideEmailFilters}
                        isEditing={this.state.isEditing}
                        onEdit={() => {
                            this.setState(
                                prevState => ({
                                    ...prevState,
                                    isEditing: !prevState.isEditing
                                })
                            )
                        }}
                        onCopy={() => {
                            this.props.onCopy(this.state.config)
                        }}
                        apexCharts={[
                            ...Object.values(this.props.config.chart_config).filter(chart_config => {
                                return chart_config.chart_type !== 'text' && chart_config.chart_type !== 'table'
                            }).map(chart_config => {
                                return {id: chart_config.id, title: chart_config.title}
                            })
                        ]}

                        title={this.state.config.title}
                        can_edit={this.state.config.can_edit}
                        is_shared={this.state.config.is_shared}
                        is_active={this.state.config.is_active}
                        is_default={this.state.config.is_default}
                        filters={this.state.config.filters}

                        mailTemplates={this.state.mailTemplates}
                        tagNames={this.state.tagNames}
                        indexes={this.state.indexes}
                        canSave={this.canSave()}

                        onTitleChange={newTitle=>{
                            this.setState(prevState => ({
                                ...prevState,
                                config: {
                                    ...prevState.config,
                                    title: newTitle
                                }
                            }))
                        }}
                        onChangeFilter={newFilters => {
                            this.setState(prevState => {
                                console.log(newFilters, prevState.config)
                                return {
                                    ...prevState,
                                    config: {
                                        ...prevState.config,
                                        filters: {
                                            ...newFilters
                                        }
                                    }
                                }
                            })
                        }}
                        onToggle={key => {
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    config: {
                                        ...prevState.config,
                                        [key]: !prevState.config[key]
                                    }
                                }
                            })
                        }}
                        onSave={() => {
                            if (this.props.can_edit) {
                                this.props.onSave(this.state.config, newData => {
                                    this.setState({
                                        isEditing: false,
                                        config: JSON.parse(JSON.stringify(newData)),
                                        originalConfig: JSON.parse(JSON.stringify(newData))
                                    }, () => {
                                    })
                                })
                            } else {
                                this.props.onCopy(this.state.config)
                            }
                        }}
                        onSetDefault={() => {
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    config: {
                                        ...prevState.config,
                                        is_default: true
                                    }, originalConfig: {
                                        ...prevState.originalConfig,
                                        is_default: true
                                    }
                                }
                            }, () => {
                                this.props.onSetDefault(this.props.config.id)
                            })

                        }}
                        onCancelEdit={() => {
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    isEditing: false,
                                    config: JSON.parse(JSON.stringify(prevState.originalConfig))
                                }
                            })
                        }}
                    />
                </Col>
            </Row>

        return <>
            {titleRow}
            {this.renderDashboard()}
        </>
    }
}