import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  NavLink
} from "reactstrap";
import { LineShadow } from "../../../components/Charts";
import PerfectScrollbar from "react-perfect-scrollbar";
import messagesData from "../../../data/messages.json";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import "chartjs-plugin-datalabels";
import recentTickets from "../../../data/recentTickets.json";
import latestUpdates from "../../../data/latestUpdates.json";

import { ticketsSatusChart, lineChartConfig } from "../../../constants/chartConfig";

const messagesD = messagesData.data;
const recentTicket = recentTickets.data;
const latestUpdate = latestUpdates.data;

export default class TicketingDashboard extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.ticketing" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          {/* Products and tickets starts here */}
          <Colxx sm="12" xs="12" md="12" lg="12" xl="4" className="mb-4">
            <Card className="pt-4">
              <div className="card-body pt-0">
                <Row>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-gift color-theme-1 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.products" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-gift color-theme-2 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.tickets" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-users color-theme-3 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.users" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-cog color-theme-4 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.settings" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                </Row>
              </div>
            </Card>
          </Colxx>
          {/* Products and tickets ends here */}

          {/* Chart starts here */}
          <Colxx sm="12" md="12" lg="12" xl="4" className="mb-4">
            <Card className="dashboard-filled-line-chart">
              <CardBody>
                <div className="float-left float-none-xs">
                  <div className="d-inline-block">
                    <h5 className="d-inline">
                      <IntlMessages id="ticketing.weekly-tickets" />
                    </h5>
                    <span className="text-muted text-small d-block">
                      <IntlMessages id="ticketing.weekly-tickets-progress" />
                    </span>
                  </div>
                </div>
              </CardBody>

              <div className="dashboard-small-chart mb-5 card-body pt-0">
                <LineShadow {...ticketsSatusChart} />
              </div>
            </Card>
          </Colxx>
          {/* Chart Ends here */}

          {/* Team messages start here */}
          <Colxx sm="12" xs="12" md="12" lg="12" xl="4" className="mb-4">
            <Card className="pt-4">
              <div className="card-body pt-0">
                <Row>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-cart-plus color-theme-1 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.monthly-sales" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-dollar-sign color-theme-2 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.income" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fa fa-money-bill-alt color-theme-3 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.taxes" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                  <Colxx
                    lg="3"
                    xl="6"
                    md="6"
                    sm="6"
                    xss="12"
                    className="text-center"
                  >
                    <div className="border-square px-3 py-3 my-2">
                      <a href="#">
                        <i className="fab fa-opencart color-theme-4 fa-4x" />
                        <br />
                        <div className="mt-3">
                          <strong>
                            <IntlMessages id="ticketing.todays-sales" />{" "}
                          </strong>
                        </div>
                      </a>
                    </div>
                  </Colxx>
                </Row>
              </div>
            </Card>
          </Colxx>
          {/* Team Messages End here  */}
        </Row>

        <Row>
          <Colxx xl="4" lg="6" md="12" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="ticketing.by-date" />
                </CardTitle>
                <div>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.overdue" />

                      <span className="float-right badge badge-danger">10</span>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.todays" />
                      <span className="float-right badge bg-theme-2">120</span>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.tomorrow" />
                      <span className="float-right badge bg-theme-1">89</span>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.this-week" />
                      <span className="float-right badge bg-theme-3">377</span>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.this-month" />
                      <span className="float-right badge badge-warning">
                        2110
                      </span>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.on-hold" />
                      <span className="float-right badge badge-danger">49</span>
                    </p>
                  </a>
                  <a href="#">
                    <p>
                      <IntlMessages id="ticketing.close-tickets" />
                      <span className="float-right badge badge-success">
                        45610
                      </span>
                    </p>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Colxx>
          <Colxx xl="4" lg="6" md="12" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="ticketing.by-importance" />
                </CardTitle>
                <Row>
                  <Colxx md="4" xs="4">
                    <a href="#">
                      <p className="text-center">
                        <span className="bg-theme-2 px-2 py-2 radius-10px">
                          39
                        </span>
                      </p>
                      <strong>
                        <p className="mt-3 text-center">
                          {" "}
                          <IntlMessages id="ticketing.medium" />
                        </p>
                      </strong>
                    </a>
                  </Colxx>
                  <Colxx md="4" xs="4" className="mx-auto">
                    <a href="#">
                      <p className="text-center">
                        <span className="bg-theme-1 px-3 py-3 radius-10px">
                          16
                        </span>
                      </p>

                      <strong>
                        <p className="mt-4 text-center">
                          {" "}
                          <IntlMessages id="ticketing.high-importance" />
                        </p>
                      </strong>
                    </a>
                  </Colxx>

                  <Colxx md="4" xs="4">
                    <a href="#">
                      <p className="text-center">
                        <span className="bg-theme-3 px-2 py-2 radius-10px">
                          14
                        </span>
                      </p>

                      <strong>
                        <p className="mt-3 text-center">
                          {" "}
                          <IntlMessages id="ticketing.low" />
                        </p>
                      </strong>
                    </a>
                  </Colxx>
                </Row>
                <Row className="mt-3">
                  <Colxx className="ml-auto" md="6" sm="3" xs="6">
                    <p>
                      <span className="font-color-4 fa fa-dot-circle mr-2" />
                      <IntlMessages id="ticketing.replied" />
                    </p>
                    <p>
                      <strong className="font-30px text-muted">1299</strong>
                    </p>
                  </Colxx>

                  <Colxx className="ml-auto" md="6" sm="3" xs="6">
                    <p>
                      <span className="font-color-1 fa fa-dot-circle mr-2" />
                      <IntlMessages id="ticketing.transfered" />
                    </p>
                    <p>
                      <strong className="font-30px text-muted">299</strong>
                    </p>
                  </Colxx>
                  <Colxx className="ml-auto" md="6" sm="3" xs="6">
                    <p>
                      <span className="font-color-5 fa fa-dot-circle mr-2" />
                      <IntlMessages id="ticketing.on-hold" />
                    </p>
                    <p>
                      <strong className="font-30px text-muted">49</strong>
                    </p>
                  </Colxx>
                  <Colxx className="ml-auto" md="6" sm="3" xs="6">
                    <p>
                      <span className="font-color-6 fa fa-dot-circle mr-2" />
                      <IntlMessages id="ticketing.need-reply" />
                    </p>
                    <p>
                      <strong className="font-30px text-muted">341</strong>
                    </p>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
          {/* Messages */}
          <Colxx lg="12" xl="4" md="12" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="main.team-messages" />

                  <div className="mt-2">
                    <span className=" bg-theme-1 mr-2 mb-2 pl-1 pr-1 fontsize10px">
                      <IntlMessages id="main.total-online" />
                    </span>
                    <span className="fontsize10px bg-danger text-white pl-1 pr-1">
                      <IntlMessages id="main.total-offline" />
                    </span>
                  </div>
                </CardTitle>
                <div className="dashboard-recent-act-list">
                  <PerfectScrollbar
                    option={{ suppressScrollX: true, wheelPropagation: false }}
                  >
                    {messagesD.map((message, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row mb-3 pb-3 border-bottom"
                        >
                          <NavLink to="/app/layouts/details">
                            <img
                              src={message.thumb}
                              alt={message.label}
                              className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                            />

                            <p
                              className={`${
                                message.color
                              } fontsize10px pr-1 pl-1 mt-2 text-white`}
                            >
                              {message.online}
                            </p>
                          </NavLink>

                          <div className="pl-3 pr-2">
                            <div className="float-right">{message.time}</div>
                            <NavLink to="/app/layouts/details">
                              <p className="font-weight-medium mb-0 ">
                                {message.label}
                              </p>

                              <p className="text-muted mb-0 mt-1 text-small">
                                {message.message}
                              </p>
                            </NavLink>
                          </div>
                        </div>
                      );
                    })}
                  </PerfectScrollbar>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" md="12" lg="12" xl="6" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="ticketing.recent-tickets" />
                </CardTitle>
                {recentTicket.map((tickets, index) => {
                  return (
                    <div key={index} className="border-bottom">
                      <p className="mt-2 py-3 px-3">
                        <span
                          className={`${
                            tickets.color
                          } text-uppercase mr-2 mb-2 pl-1 pr-1 fontsize10px py-2 px-3`}
                        >
                          <strong>{tickets.priority}</strong>
                        </span>
                        <span>
                          <strong className="text-muted">
                            {tickets.title}
                          </strong>
                        </span>
                        {/* Delete Icon */}
                        <span
                          className="fa fa-trash float-right ml-2 cursor-pointer text-muted"
                          href="#"
                          id={`${tickets.delete}`}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`${tickets.delete}`}
                        >
                          {tickets.deleteTool}
                        </UncontrolledTooltip>
                        {/* Delete Icon Ends Here */}

                        {/* Eye Icon  */}
                        <span
                          className="fa fa-eye-slash float-right cursor-pointer text-muted"
                          href="#"
                          id={`${tickets.read}`}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`${tickets.read}`}
                        >
                          {tickets.mark}
                        </UncontrolledTooltip>
                        {/* Eye Icon Ends Here */}
                      </p>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Colxx>
          <Colxx xxs="12" md="12" lg="12" xl="6" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="ticketing.latest-updates" />
                </CardTitle>
                {latestUpdate.map((updates, index) => {
                  return (
                    <div key={index} className="border-bottom">
                      <p className="mt-2 py-3 px-3">
                        <span
                          className={`${updates.icon} mr-2 mb-2 pl-1 pr-1`}
                        />
                        <span className="text-muted">
                          <strong className="text-muted">
                            <IntlMessages id={`${updates.title}`} />
                          </strong>
                          <br />
                          <span className="ml-29px">{updates.date}</span>
                        </span>
                        {/* Delete Icon */}
                        <span
                          className="fa fa-minus float-right ml-2 cursor-pointer text-muted"
                          href="#"
                          id={`${updates.removeIt}`}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`${updates.removeIt}`}
                        >
                          {updates.remove}
                        </UncontrolledTooltip>
                        {/* Delete Icon Ends Here */}
                      </p>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="main.sales" />
                </CardTitle>

                <div className="dashboard-line-chart">
                  <LineShadow {...lineChartConfig} />
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
