import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import {Button, Input, Label, ModalBody, ModalHeader, Row} from "reactstrap";
import {dictToRows, keyValueToRow, titleCase} from "../../util/RenderUtils";
import moment from "moment";
import {Colxx} from "../../components/CustomBootstrap";
import EntryDetail from "./entryDetail";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import Loading from "../../components/loading";
import {toast} from "react-toastify";

export class DisputeModalContent extends Component {
    state = {
        modalDispute: null,
        resolutionNotes: '',
        selectedModalDisputeAction: null,
    }
    disputeApi = new ApiClient('dispute', authenticationErrorHandler.bind(this))

    authenticationErrorHandler(error) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            clearAuthTokens();
            this.history.replace('/login/')
        } else {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            return Promise.reject(error)
        }
        console.log(error.config);
    }

    componentDidMount() {
        this.loadDispute()
    }

    loadDispute() {
        if (this.props.disputeId) {
            this.disputeApi.getItem(this.props.disputeId).then(response => {
                this.setState({modalDispute: response.data})
            });
        }
    }


    handleApplyDisputeResolution() {
        const resolution = this.state.selectedModalDisputeAction.value
        this.disputeApi.patchItem(this.state.modalDispute.id, {
            "resolution": resolution,
            "resolution_notes": this.state.resolutionNotes
        }).then(response => {
            if (response.data.is_resolved) {
                toast("Dispute #" + this.props.disputeId.toString() + " is now " + resolution + ".")
                this.props.resolve()
            } else {
                toast.error("Something went wrong during resolution. Please refresh the page.")
            }
        }).catch(error => {
            toast.errort("Something went wrong during resolution. Please refresh the page.")
        })
    }

    renderExplanation() {
        if (!this.state.selectedModalDisputeAction) {
            if (this.state.modalDispute.dispute_type === 'other') {
                return <span>Other types of disputes cannot be automatically approved. Ignore is the only option. If you wish to approve this dispute, kindly contact the administrator for manual correction.</span>
            }
            return ''
        }
        if (this.state.modalDispute.dispute_type === 'used') {
            if (this.state.selectedModalDisputeAction.value === 'approve') {
                return <span> Approve will mark entry <strong className="">B</strong>{" "} as <strong>INVALID</strong>, and mark entry <strong>A</strong> as <strong>VALID</strong>. </span>
            } else {
                return <span>
            Ignore will uphold current valid entry.
                This will not change the status of the entries
            but mark this dispute as resolved.
            </span>
            }
        } else if (this.state.modalDispute.dispute_type === 'invalid') {
            if (this.state.selectedModalDisputeAction.value === 'approve') {
                return <span>Approve will create a new code for the entry, and mark this entry as valid.</span>
            } else {
                return <span>Ignore will do nothing but mark this dispute as resolved. This entry will remain invalid.</span>
            }
        } else if (this.state.modalDispute.dispute_type === 'notissued') {
            if (this.state.selectedModalDisputeAction.value === 'approve') {
                return <span>Approve will mark the code as disbursed/issued and make this entry valid.</span>
            } else {
                return <span>Ignore will do nothing but mark this dispute as resolved. This entry will remain invalid</span>
            }
        } else {
            return <>
                <span>Ignore will do nothing but mark this dispute as resolved. This entry will remain invalid.</span>
                <br/>
                <span>Other types of disputes cannot be automatically approved. Ignore is the only option. If you wish to approve this dispute, kindly contact the administrator for manual correction.</span>
                </>
        }
    }

    renderActions() {
        if (!this.state.modalDispute || this.state.modalDispute.is_resolved) {
            return <></>
        }

        const resolutionOptions = [{label: "Ignore Dispute", value: "ignore"}];
        if (this.state.modalDispute.dispute_type != 'other') {
            resolutionOptions.push({label: "Approve Dispute", value: "approve"})
        }

        return <>

            <hr/>
            <Row className="d-flex align-bottom m-2">
                <Colxx>

                    <strong>{this.renderExplanation()}</strong>
                </Colxx>
            </Row>

            <Row className="d-flex align-bottom m-2">
                <Colxx md={7} className="d-flex align-items-center">

                    <Label for="resolutionNotes">Resolution Notes</Label>
                    <Input
                        type="textarea"
                        name="resolutionNotes"
                        id="resolutionNotes"
                        placeholder="Resolution notes"
                        value={this.state.resolutionNotes}
                        onChange={e => {
                            this.setState({resolutionNotes: e.target.value})
                        }}
                    />
                </Colxx>
                <Colxx md={4}>
                    <Label><strong>Select Resolution</strong></Label>
                    <Select
                        isClearable={true}
                        components={{Input: CustomSelect}}
                        className="react-select small"
                        classNamePrefix="react-select"
                        value={this.state.selectedModalDisputeAction}
                        placeholder={"Select Resolution"}
                        onChange={o => this.setState({selectedModalDisputeAction: o})}
                        options={resolutionOptions}
                    />
                </Colxx>
                <Colxx md={1} className="d-flex justify-content-end">
                    <Button
                        color={this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'ignore' ? 'danger' : 'success'}
                        disabled={!this.state.selectedModalDisputeAction}
                        onClick={this.handleApplyDisputeResolution.bind(this)}
                    >
                        Apply
                    </Button>
                </Colxx>
            </Row>

        </>
    }

    renderDisputingEntries() {
        if (this.state.modalDispute.disputed_entry) {
            return <Row className="mx-4">
                <Colxx
                    md={6}

                    className={"px-4 " + (
                        !!this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'ignore' ? 'blur' : ''
                    )}>
                    <h3 className="text-danger">A) Entry raising dispute</h3>
                    <hr/>
                    <EntryDetail entry={this.state.modalDispute.disputed_by_entry} location={this.props.location}/>
                </Colxx>
                <Colxx
                    md={6}
                    className={"px-4 border-left " + (
                        !!this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'approve' ? 'blur' : ''
                    )}>
                    <h3 className="text-success">B) Entry being disputed</h3>
                    <hr/>
                    <EntryDetail entry={this.state.modalDispute.disputed_entry} location={this.props.location}/>
                </Colxx>
            </Row>
        } else {
            return <Row className="mx-4">
                <Colxx
                    className={"px-4 " + (
                        !!this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'ignore' ? 'blur' : ''
                    )}>
                    <h3 className="text-danger">Entry raising dispute</h3>
                    <hr/>
                    <EntryDetail entry={this.state.modalDispute.disputed_by_entry} location={this.props.location}/>
                </Colxx>
            </Row>
        }
    }

    render() {
        if (!this.props.disputeId || !this.state.modalDispute) {
            return <Row className="p-4 m-4">
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }
        return <>
            <ModalHeader toggle={this.props.close.bind(this)}>
                Dispute #{this.state.modalDispute.id}
            </ModalHeader>
            <ModalBody>
                {this.state.modalDispute.additional_details && dictToRows(this.state.modalDispute.additional_details)}
                {keyValueToRow(
                    "Datetime Dispute Filed",

                    <span title={moment(this.state.modalDispute.datetime_created).format('LLLL')}>
                        <strong>{moment(this.state.modalDispute.datetime_created).format('LLLL')}</strong>{" "}
                        ({moment(this.state.modalDispute.datetime_created).fromNow()})
                    </span>
                )}
                {keyValueToRow("Dispute Type", this.state.modalDispute.get_dispute_type_display)}
                <hr/>
                {
                    this.state.modalDispute.is_resolved && <>
                        {keyValueToRow("Resolution", titleCase(this.state.modalDispute.resolution))}
                        {keyValueToRow("Resolution Notes", this.state.modalDispute.resolution_notes)}
                        {keyValueToRow(
                            "Datetime Resolved",
                            <span title={moment(this.state.modalDispute.datetime_resolved).format('LLLL')}>
                                <strong>{moment(this.state.modalDispute.datetime_resolved).format('LLLL')}</strong>{" "}
                                ({moment(this.state.modalDispute.datetime_resolved).fromNow()})
                            </span>
                        )}
                        {keyValueToRow("Resolved By", this.state.modalDispute.resolved_by)}
                        <hr/>
                    </>
                }
                {this.renderDisputingEntries()}
                {this.renderActions()}
            </ModalBody>
        </>
    }
}