import React, { Component } from "react";
import { ReactifyChartColors } from "../../util/ReactifyChartColors";

import Chart from "react-apexcharts";

const colors = ReactifyChartColors();

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: props.id,
          toolbar: {
            export: {
              svg: {
                filename: props.title
              },
              png: {
                filename: props.title
              }
            }
          }
        },
        title: {
          text: props.title
        },
        dataLabels: {
          enabled: true,
          // offsetY: 0,
          // position: 'top',
          // style: {
          //   colors: ['black']
          // }
          dropShadow: {
            enabled: true,
            blur: 2,
            opacity: 0.7,
            top: 0,
            left: 0,
          },
          formatter: function (val, opts) {
            return val === 0? '' : val
          },
        },

        legend: {
          show: true,
          position: "bottom"
        },
      },
      series: []
    };
  }
  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.props.series ? this.props.series : this.state.series}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default BarChart;
