import React, {Component} from "react";
import {ReactifyChartColors} from "../../../util/ReactifyChartColors";
import Chart from "react-apexcharts";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {Colxx} from "../../../components/CustomBootstrap";
import Loading from "../../../components/loading";
import {InfoCard} from "./InfoCard";

const colors = ReactifyChartColors();


class RegistrationChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: [colors.ReactifyChartColor1],
                chart: {
                    id: "basic-line",
                    stacked: true
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        datetimeUTC: false
                    }
                },
                legend: {
                    show: true,
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                    showForSingleSeries: true
                }
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        }
                    }
                }
            ],
            series: [],

            displayExtras: {
                'Promos With Entries': 0,
                'Total Entries': 0,
                'Profile Count': 0
            },
            isLoading: true,
            hasError: false
        };

        this.entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({
            isLoading: true,
            hasError: false,
            // displayExtras: {}  // reset the data
        }, () => {

            this.entryApi.getItem('report_registrations', {
                params: {...this.props.params, include_invalid: false}
            }).then(response => {
                this.setState({
                    series: response.data.result,
                    displayExtras: response.data.extras,
                    isLoading: false
                })
            }).catch(() => {
                this.setState({
                    series: [],  // reset the data
                    isLoading: false,
                    hasError: true
                })
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return <>
            {
                this.props.showExtras && <Row>
                    {
                        Object.keys(this.state.displayExtras).map(k => {
                            return <Col id={"extra-" + k} xs={12} lg={4}>
                                <InfoCard title={k} value={this.state.displayExtras[k]} isLoading={this.state.isLoading} hasError={this.state.hasError}/>
                            </Col>
                        })
                    }
                </Row>
            }
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>{this.props.title ? this.props.title : 'Registrations'}</h2>
                                <div className={"float-right position-absolute"} style={{right: '1em', top: '1em'}}>
                                    {this.state.isLoading && <Loading/>}
                                </div>
                            </CardTitle>

                            <Chart
                                options={{
                                    ...this.state.options,
                                    noData: {
                                        text: this.state.hasError ? 'Error loading!' : (this.state.isLoading ? 'Loading ...' : 'No Data')
                                    }
                                }}
                                series={this.state.series}
                                type="area"
                                height="100%"
                                width="100%"
                            />
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        </>
    }
}

export default RegistrationChart;
