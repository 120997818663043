import React, { Component, Fragment } from "react";
import {
  Row,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Card,
  CardBody,
  CardTitle, Alert
} from "reactstrap";

import logoLight from '../../assets/img/logo-light.png';
import {isLoggedIn} from "axios-jwt";
import {login, testToken} from "../../api/ApiClient";
import Loading from "../../components/loading";
import {Colxx} from "../../components/CustomBootstrap";

class Login extends Component {
  constructor(props) {
    super(props);

    this.history = props.history;
    this.location = props.location;
    this.state = {
      username: '',
      password: '',
      isLoggingIn: false,
      errorMessages: {},
      isCheckingLogin: true
    }
  }

  componentDidMount() {
    this.redirectIfLoggedIn()
  }

  redirectIfLoggedIn() {
    if (isLoggedIn()) {
      testToken().then(data => {
        if(isLoggedIn()) {
          let { from } = this.location.state || { from: { pathname: "/app/main/layout" } };
          this.history.replace(from)
        } else {
          this.setState({isCheckingLogin: false})
        }
      }).catch(error => {
        this.setState({isCheckingLogin: false})
      })
    } else {
      this.setState({isCheckingLogin: false})
    }
  }

  handleDoLogin(e) {
    e.preventDefault();

    this.setState({
      isLoggingIn: true,
      errorMessage: ''
    }, () => {
      login({...this.state}).then(() => {
        if (this.props.onLoginSuccess) {
          this.props.onLoginSuccess();
        }
        this.redirectIfLoggedIn()
      }).catch((error) => {
        if (error.response && typeof(error.response.data) != "string") {
          const errorMessages = Object.keys(error.response.data).reduce((accumulator, curr) => {
            if (typeof(error.response.data[curr]) == "string") {
              accumulator[curr] = error.response.data[curr];
            } else {
              accumulator[curr] = error.response.data[curr].join(' ');
            }
            return accumulator;
          }, {});

          this.setState({
            errorMessages: errorMessages
          })
        } else {
          this.setState({
            errorMessages: {detail: "Something went wrong. Please contact administrator."}
          })
        }
      }).finally(() => {
        this.setState({
          isLoggingIn: false
        })
      })
    })
  }


  handleUserNameChange(e) {
    this.setState({username: e.target.value})
  }
  handlePasswordChange(e) {
    this.setState({password: e.target.value})
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleDoLogin(e)
    }
  }

  render() {
    if (this.state.isCheckingLogin) {
      return <Row>
        <Colxx className="text-center mx-auto my-5">
          <Loading/>
        </Colxx>
      </Row>
    }
    return (
      <Fragment>
        <Col lg="10" xl="8" md="10" className="mx-auto my-5">
          <Card>
            <Row className="no-gutters">
              <Col xl="6" lg="12" className="loginBoxBg">
                <Card className="boxshadow-0 transparent-card">
                  <CardBody>
                    <a
                      href="#"
                      className="navbar-brand navbar-logo ml-3 navbar-brand"
                    >
                      <h1 className="text-light text-xlarge">iokidigital app</h1>
                    </a>

                    <p className="text-white mt-5">
                      Promo management, chatbot enriching webhooks and more in
                      one central platform.{" "}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6" lg="12" md="12" className="no-gutters">
                <Card className="boxshadow-0 margin-right-2px">
                  <CardBody>
                    <CardTitle>
                      Welcome back again!
                      <div className="mt-5">
                        <CardTitle>
                          <p>
                            <strong>Login</strong>
                          </p>
                        </CardTitle>
                        <Form>
                          <FormGroup>
                            <Label for="username">Username :</Label>
                            <Input
                              type="text"
                              name="username"
                              id="username"
                              placeholder="Username"
                              value={this.state.username}
                              onChange={this.handleUserNameChange.bind(this)}
                              onKeyDown={this.handleKeyDown.bind(this)}
                              disabled={this.state.isLoggingIn}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="yourPassword">Password :</Label>
                            <Input
                              type="password"
                              name="yourPassword"
                              id="yourPassword"
                              placeholder="Your password..."
                              value={this.state.password}
                              onChange={this.handlePasswordChange.bind(this)}
                              onKeyDown={this.handleKeyDown.bind(this)}
                              disabled={this.state.isLoggingIn}
                            />
                          </FormGroup>
                          { this.state.errorMessages.detail && <Alert color="danger">{this.state.errorMessages.detail}</Alert> }
                          <Button
                            href="#"
                            className="btn-shadow"
                            color="primary"
                            className="mt-4"
                            size="lg"
                            style={{width: "15em"}}
                            onClick={this.handleDoLogin.bind(this)}
                            disabled={this.state.isLoggingIn}
                          >
                            { this.state.isLoggingIn ? "Logging in" : "Login" }
                          </Button>
                        </Form>
                      </div>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Fragment>
    );
  }
}

export default Login;
