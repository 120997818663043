import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import Chart from "react-apexcharts";
import {Colxx} from "../../../components/CustomBootstrap";
import Loading from "../../../components/loading";
require('../../../index.css')

export default class LocationList extends Component {
    state = {
        data: [],
        isLoading: true,
        hasError: false
    }

    constructor(props) {
        super(props);
        this.entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.entryApi.getItem('report_location', {
                params: {...this.props.params}
            }).then(response => {
                this.setState({data: response.data, isLoading: false})
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return <Card className="my-4" style={{minHeight: '50vh'}}>
            <CardBody>
                <CardTitle>
                    <h2>Locations</h2>
                    <small>Cities, Region, Country (if specified)</small>
                    <div className={"float-right position-absolute"} style={{right: '1em', top: '1em'}}>
                        {this.state.isLoading && <Loading/>}
                    </div>
                </CardTitle>
                <Row>
                    {
                        this.state.data.map(obj => <Colxx key={obj.location} xs={12} sm={6} md={6} lg={6} xl={4}>
                            <Row className="border-1px p-2 m-1 bg-blue-pixel" style={{backgroundSize: Math.round(obj.percentage * 100).toString() + '% 100%'}}>
                                <Colxx>{this.state.isLoading ? 'Refreshing...' : obj.location}</Colxx>
                                <Colxx className="text-right" xxs={3} xs={3} sm={3}>{obj.total}</Colxx>
                                <Colxx className="text-right" xxs={3} xs={3} sm={3}>{Math.round(obj.percentage * 100)}%</Colxx>
                            </Row>
                        </Colxx>)
                    }

                </Row>
            </CardBody>
        </Card>
    }
}