import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Loading from "../../components/loading";
import {dictToRows, keyValueToRow} from "../../util/RenderUtils";
import moment from "moment";
import ApiInfoSet from "../../components/apiInfoSet";

export default class EntryProfileModal extends Component {
    constructor(props) {
        super(props);
        this.api = new ApiClient('entry', authenticationErrorHandler.bind(this))

        this.state = {
            data: {},
            isLoading: true,
            hasError: false,
        }
    }

    componentDidMount() {
        this.loadProfile()
    }

    loadProfile() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.api.getItemAction(this.props.entryId, 'profile').then(response => {
                this.setState({
                    data: response.data,
                    isLoading: false
                })
            }).catch(() => {
                this.setState({
                    isLoading: false,
                    hasError: true
                })
            })
        })
    }

    renderContent() {
        if (this.state.isLoading) {
            return <Row>
                <Col className={"text-center"}>
                    <Loading/>
                </Col>
            </Row>
        }
        if (this.state.hasError) {
            return <Row>
                <Col className={"text-center"}>
                    Error loading profile
                </Col>
            </Row>
        }

        const addresses = []
        if (this.state.data.last_known_info.address) {
            addresses.push(this.state.data.last_known_info.address)
        }
        if (this.state.data.last_known_info.city) {
            addresses.push(this.state.data.last_known_info.city)
        }
        if (this.state.data.last_known_info.region) {
            addresses.push(this.state.data.last_known_info.region)
        }
        if (this.state.data.last_known_info.country) {
            addresses.push(this.state.data.last_known_info.country)
        }

        return <>
            {keyValueToRow("Name", <strong>{this.state.data.last_known_info.name}</strong>)}
            {
                this.state.data.last_known_info.email_address &&
                keyValueToRow("Email Address", <strong>{this.state.data.last_known_info.email_address}</strong>)
            }
            {keyValueToRow("Mobile Number", <strong>{this.state.data.last_known_info.mobile_number}</strong>)}
            {
                addresses.length > 0 &&
                keyValueToRow("Address", <strong>{addresses.join(', ')}</strong>)
            }

            <ApiInfoSet data={{
                "Number of total entries": this.state.data.entry_count,
                "Promos participated in": this.state.data.promo_count,
                "Confirmed winning entries": this.state.data.confirmed_win_count
            }}/>
            {
                keyValueToRow("First Seen", <strong>
                    {moment(this.state.data.first_known_info.datetime_registered).format('LLL')}
                </strong>)
            }
            {
                keyValueToRow("Last Seen", <strong>
                    {moment(this.state.data.last_known_info.datetime_registered).format('LLL')}
                </strong>)
            }

        </>
    }

    render() {
        return <Modal isOpen={true} size="lg" toggle={this.props.onClose}>
            <ModalHeader>
                Profile {this.state.data && this.state.data.last_known_info && this.state.data.last_known_info.mobile_number}
            </ModalHeader>
            <ModalBody>
                {this.renderContent()}
            </ModalBody>
            <ModalFooter>
                <Button onClick={this.props.onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    }

}