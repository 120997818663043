import React, {Component} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";

import TopNav from "../containers/TopNavbar";
import Sidebar from "../containers/Offcanvas";

import main from "./main";

import promo from "./promo";
import apiHooks from "./apihook";
import users from "./users";
import mail from "./mail";

import {connect} from "react-redux";
import DialogueTracking from "./dialogueTracking";

class MainApp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match, containerClassnames } = this.props;
    return (
      <div id="app-container" className={containerClassnames}>
        <TopNav history={this.props.history} />
        <Sidebar />
        <main>
          <div className="container-fluid">
            <Switch>
              <Route path={`${match.url}/promo`} component={promo} />
              <Route path={`${match.url}/apiHooks`} component={apiHooks} />
              <Route path={`${match.url}/users`} component={users} />

              <Route path={`${match.url}/main`} component={main} />
              <Route path={`${match.url}/mail`} component={mail} />
              <Route path={`${match.url}/dialogue-tracking`} component={DialogueTracking} />
              <Redirect to="/error" />
            </Switch>
          </div>
        </main>
      </div>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(MainApp)
);
