import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import moment from "moment";
import Loading from "../../components/loading";


export default class History extends Component {
    constructor(props) {
        super(props);
        this.api = new ApiClient('email_history', authenticationErrorHandler.bind(this))

        this.state = {
            next: "",
            previous: "",
            data: [],
            isLoadingInitial: true,
            isLoadingNext: false,
            isLoadingPrevious: false,
            nextInfo: ""
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.setState({isLoadingInitial: true}, () => {
            this.api.list().then(response => {
                this.setState(prevState => {
                    return {
                        next: response.data.next,
                        previous: response.data.previous,
                        data: response.data.results,
                        isLoadingInitial: false
                    }
                })
            })
        })
    }

    loadNext() {
        this.setState({isLoadingNext: true, nextInfo: ""}, () => {
            this.api.get(this.state.next).then(response => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        data: [...prevState.data, ...response.data.results],
                        next: response.data.next,
                        isLoadingNext: false,
                        nextInfo: "Retrieved " + response.data.results.length + " new records."
                    }
                })
            })
        })
    }

    loadPrevious() {
        this.setState({isLoadingPrevious: true}, () => {
            this.api.get(this.state.previous).then(response => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        data: [...prevState.data, ...response.data.results],
                        previous: response.data.previous,
                        isLoadingPrevious: false,
                    }
                })
            })
        })
    }

    renderLog(row) {
        let recipientsText = []
        if (row.recipients.ToAddresses && row.recipients.ToAddresses.length > 0) {
            recipientsText.push(<br/>)
            recipientsText.push(<span>TO: {row.recipients.ToAddresses.join(', ')}</span>)
        }
        if (row.recipients.CcAddresses && row.recipients.CcAddresses.length > 0) {
            recipientsText.push(<br/>)
            recipientsText.push(<span>CC: {row.recipients.CcAddresses.join(', ')}</span>)
        }

        const messageIds = []
        if (row.message_id) {
            messageIds.push(row.message_id.split('-')[5])
        } else {
            if (row.record_id) {
                messageIds.push('r' + row.record_id.toString())
            }
            messageIds.push('h' + row.id.toString())
        }

        return <Row key={row.id} className="bg-light mb-1 p-1">
            <Col md={4} sm={12} lg={4} xl={2}>
                {row.did_send ? <span className="fas fa-check-circle text-success lead  float-left pr-1"/> :
                    <span className="fas fa-times-circle text-danger lead float-left pr-1"/>}{" "}
                Message ID: <code>{messageIds.join('-')}</code>
            </Col>
            <Col
                xl={3} lg={8} md={8}
            >
                <strong>Subject: {row.subject ? row.subject : <i className="text-muted">No Subject</i> } <br/></strong>
                {row.tags.map(tagName => {
                    return <span className="badge badge-outline-info text-small">{tagName}</span>
                })}
            </Col>
            <Col xl={5} lg={8} md={11} sm={12}
                 className="offset-lg-1 offset-md-1 offset-xl-0"
            >
                From: {row.sender ? row.sender : <i className="text-muted">No Sender</i> }
                {recipientsText}
            </Col>
            <Col
                xl={2} lg={3} md={12}
                className="text-right"
                title={moment(row.datetime_created).format('LLLL')}>
                {moment(row.datetime_created).fromNow()}<br/>
                <span className="text-muted">{moment(row.datetime_created).format('LLLL')}</span>
            </Col>
        </Row>
    }

    renderLogs() {
        if (this.state.isLoadingInitial) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }

        if (this.state.data.length <= 0) {
            return <Row>
                <Col className="text-center">No email logs as of the moment.</Col>
            </Row>
        }
        const data = this.state.data.sort((a, b) => {
            if (a.id > b.id) {
                return -1
            }
            if (a.id < b.id) {
                return 1
            }
            return 0
        })

        return data.map(row => {
            return this.renderLog(row)
        })
    }

    renderPrevious() {
        if (!this.state.previous) {
            return null
        }

        if (this.state.isLoadingPrevious) {
            return <Row className="bg-light mb-1 p-1">
                <Col className="text-center">
                    Retrieving older data.
                    <br/>
                    <Loading/>

                </Col>
            </Row>
        }

        return <Row className="bg-light mb-1 p-1">
            <Col className="text-center">
                Has older data.{" "}
                <span style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }} onClick={this.loadPrevious.bind(this)}>Load older data</span>
            </Col>
        </Row>
    }

    renderNext() {
        if (!this.state.next) {
            return null
        }

        if (this.state.isLoadingNext) {
            return <Row className="bg-light mb-1 p-1">
                <Col className="text-center">
                    Checking for newer data.
                    <br/>
                    <Loading/>

                </Col>
            </Row>
        }

        return <Row className="bg-light mb-1 p-1">
            <Col className="text-center">
                {this.state.nextInfo}{" "}
                <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={this.loadNext.bind(this)}>Check for newer data</span>
            </Col>
        </Row>
    }

    render() {
        return <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>Email History</h2>
                            </CardTitle>
                            {this.renderNext()}
                            { this.renderLogs() }
                            { this.renderPrevious() }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    }
}