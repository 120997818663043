import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import DownloadToast from "./DownloadToast";
import Loading from "../loading";
import {Row} from "reactstrap";
import {Colxx} from "../CustomBootstrap";

export default class DownloadsModalContent extends Component {
    state = {
        downloads: [],
        isLoaded: false
    }

    downloadApi = new ApiClient('download', authenticationErrorHandler.bind(this))

    componentDidMount() {
        this.downloadApi.list().then(response => {
            this.setState({downloads: [...response.data], isLoaded: true})
        })
    }

    render () {
        if (!this.state.isLoaded) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }
        if (!this.state.downloads.length) {
            return <i>No downloads yet.</i>
        }
        return <>
            {this.state.downloads.map(o => <DownloadToast data={o} downloadId={o.id} key={"download-"+o.id} showDetails={true}/>)}
        </>
    }
}