import React, {Component} from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    Table
} from "reactstrap";
import {Colxx} from "../../../components/CustomBootstrap";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import moment from "moment";
import EntryDetail from "../entryDetail";
import Loading from "../../../components/loading";
import FileSaver from "file-saver";
import {winSettings} from "./constants";
import EntryProfileModal from "../entryProfileModal";


class WinnerRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmApprove: false,
            confirmIgnore: false,
            showEntry: false,
            showProfile: false,
            loadingActions: false,
            data: JSON.parse(JSON.stringify(props.data))
        }
        this.drawWinnerApi = new ApiClient('draw_winner', authenticationErrorHandler.bind(this))
    }

    closeEntryModal() {
        this.setState({showEntry: false})
    }

    renderEntryDetail() {
        if (!this.state.showEntry) {
            return null
        }

        const entry = this.state.data.entry;

        return <Modal isOpen={true} toggle={this.closeEntryModal.bind(this)}>
            <ModalHeader toggle={this.closeEntryModal.bind(this)}>
                Entry ID {entry.id}
            </ModalHeader>
            <ModalBody>
                <EntryDetail entry={entry}/>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.closeEntryModal.bind(this)}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    }

    renderEntryProfile() {
        if (!this.state.showProfile) {
            return null
        }

        return <EntryProfileModal entryId={this.state.data.entry.id} onClose={() => {
            this.setState({showProfile: false})
        }}/>
    }

    resetButtons() {
        this.setState({confirmApprove: false, confirmIgnore: false})
    }

    approve() {
        this.setState({loadingActions: true}, () => {
            this.drawWinnerApi.patchItem(this.state.data.id, {"status": true}).then(response => {
                this.setState({
                    loadingActions: false,
                    data: response.data
                })
            })
        })
    }

    ignore() {
        this.setState({loadingActions: true}, () => {
            this.drawWinnerApi.patchItem(this.state.data.id, {"status": false}).then(response => {
                this.setState({
                    loadingActions: false,
                    data: response.data
                })
            })
        })
    }

    renderStatusColumn() {
        const row = this.state.data;

        const approveBtnWidth = this.state.confirmApprove ? "90px" : (this.state.confirmIgnore ? "34px" : "62px");
        const ignoreBtnWidth = this.state.confirmIgnore ? "90px" : (this.state.confirmApprove ? "34px" : "62px");
        const approveBtnText = this.state.confirmApprove ? <small>confirm win</small> : (this.state.confirmIgnore ?
            <small>&nbsp;</small> : <small>approve</small>);
        const ignoreBtnText = this.state.confirmIgnore ? <small>confirm ignore</small> : (this.state.confirmApprove ?
            <small>&nbsp;</small> : <small>ignore</small>);

        if (row.status === null) {
            if (this.state.loadingActions) {
                return <div className="text-center">
                    <Loading/>
                </div>
            }
            return <ButtonGroup onMouseLeave={this.resetButtons.bind(this)} onBlur={this.resetButtons.bind(this)}>
                <Button size="xs" color="success" style={{width: approveBtnWidth}} onClick={() => {
                    if (!this.state.confirmApprove) {
                        this.setState({confirmApprove: true, confirmIgnore: false})
                    } else {
                        this.approve()
                    }
                }}>
                    <span className="fa fa-check"></span><br/>
                    {approveBtnText}
                </Button>
                <Button size="xs" color="danger" style={{width: ignoreBtnWidth}} onClick={() => {
                    if (!this.state.confirmIgnore) {
                        this.setState({confirmApprove: false, confirmIgnore: true})
                    } else {
                        this.ignore()
                    }
                }}>
                    <span className="fa fa-times"></span><br/>
                    {ignoreBtnText}
                </Button>
            </ButtonGroup>
        }

        return (
            row.status ?
                <><span className="fa fa-check"></span> Confirmed</> :
                <><span className="fa fa-times"></span> Ignored</>
        )
    }

    render() {
        const row = this.state.data;
        const entry = row.entry;

        return <tr>
            <td>{entry.name}</td>
            <td>{entry.mobile_number}</td>
            <td>{entry.raw_code}</td>
            <td>
                {this.renderStatusColumn()}
            </td>
            <td>
                <ButtonGroup>
                    <Button size="xs" onClick={() => {
                        this.setState({showEntry: true})
                    }}>
                        <span className="fa fa-eye"></span><br/>
                        <small>entry</small>
                    </Button>
                    <Button size="xs" onClick={() => {
                        this.setState({showProfile: true})
                    }}>
                        <span className="fa fa-user"></span><br/>
                        <small>profile</small>
                    </Button>
                </ButtonGroup>

                {this.renderEntryDetail()}
                {this.renderEntryProfile()}
            </td>
        </tr>
    }
}


export default class PreviousWinners extends Component {
    state = {
        data: {},
        isLoading: true,
        selectedDraw: null,
        refreshInterval: null,
        confirmApproveAll: false,
    }

    constructor(props) {
        super(props);
        this.drawApi = new ApiClient('draw', authenticationErrorHandler.bind(this))
    }

    componentDidMount() {
        this.getDraws()

        const refreshInterval = window.setInterval(() => {
            if (this.state.selectedDraw) {
                const draw = this.state.data[this.state.selectedDraw];
                if (draw.is_generating !== false) {
                    this.drawApi.getItem(this.state.selectedDraw).then(response => {
                        const winners = response.data.winners
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                data: {
                                    ...prevState.data,
                                    [this.state.selectedDraw]: {
                                        ...prevState.data[this.state.selectedDraw],
                                        winners: winners,
                                        is_generating: response.data.is_generating
                                    }
                                }
                            }
                        })
                    })
                }
            }
        }, 2000)
        this.setState({refreshInterval: refreshInterval})
    }

    componentWillUnmount() {
        if (this.state.refreshInterval) {
            clearInterval(this.state.refreshInterval)
            this.setState({refreshInterval: null})
        }
    }

    getDraws() {
        this.setState({isLoading: true}, () => {
            this.drawApi.list({params: {promo_id: this.props.promoID}}).then(response => {
                const selectedDrawId = response.data.length > 0 && response.data[0].id
                this.setState({
                    data: Object.values(response.data).reduce(
                        (acc, row) => {
                            acc[row.id] = row
                            return acc
                        },
                        {}
                    ),
                    isLoading: false,
                    selectedDraw: selectedDrawId
                })
            })
        })
    }

    addNewDraw(newDraw) {
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [newDraw.id]: newDraw
            },
            selectedDraw: newDraw.id
        }))
    }

    downloadDraw() {
        this.drawApi.getItemAction(this.state.selectedDraw, 'download').then(response => {
            const drawKey = this.state.data[this.state.selectedDraw].draw_key
            const file = new File([response.data], 'Winners - ' + drawKey + ' - ' + moment().format('LLLL'), {type: 'text/csv'});
            FileSaver.saveAs(file);
        })
    }

    downloadAll() {
        this.drawApi.getItem('download_all').then(response => {
            const drawKey = this.state.data[this.state.selectedDraw].draw_key
            const file = new File([response.data], 'Winners - All - ' + moment().format('LLLL'), {type: 'text/csv'});
            FileSaver.saveAs(file);
        })
    }

    approveAllWinners() {
        if (this.state.confirmApproveAll) {
            this.setState({isLoading: true}, () => {

                this.drawApi.postItemAction(this.state.selectedDraw, 'approve_all', {}).then(response => {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                [response.data.id]: response.data
                            },
                            confirmApproveAll: false,
                            isLoading: false
                        }
                    })
                })
            })
        } else {
            this.setState({confirmApproveAll: true})
        }
    }

    renderSelectedDraw(selectedDraw) {
        if (this.state.isLoading) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }

        if (!this.state.selectedDraw) {
            return <Row>
                <Colxx className="text=center">
                    No draws yet.
                </Colxx>
            </Row>
        }

        let drawDatetimeString = 'since beginning of promo'
        if (selectedDraw.entry_filters && selectedDraw.entry_filters.datetime_registered__gte) {
            drawDatetimeString = 'from ' + moment(selectedDraw.entry_filters.datetime_registered__gte).format('LLL')
        }

        if (selectedDraw.entry_filters && selectedDraw.entry_filters.datetime_registered__lte) {
            drawDatetimeString += ' to ' + moment(selectedDraw.entry_filters.datetime_registered__lte).format('LLL')
        }

        const hasUnapprovedWinners = selectedDraw.winners.filter(winner => winner.status === null).length > 0
        return <Row className="m-0 p-0">
            <Colxx className="border" style={{borderColor: "rgb(222, 226, 230)"}}>

                <Row className="pt-4">
                    <Colxx>

                        <Row>
                            <Colxx>
                                Draw generated by <strong>{selectedDraw.drawn_by}</strong>
                            </Colxx>
                        </Row>
                        <Row className="">
                            <Colxx>
                                Datetime generated <strong>{moment(selectedDraw.datetime_draw).format('LLLL')}</strong>
                            </Colxx>
                        </Row>
                        <Row className="">
                            <Colxx>
                                Draw target count <strong>{selectedDraw.target_count}</strong>
                            </Colxx>
                        </Row>
                        <Row className="">
                            <Colxx>
                                Winning profiles <strong>{winSettings[selectedDraw.winner_uniqueness].toLowerCase()}</strong>
                            </Colxx>
                        </Row>

                        <Row className="">
                            <Colxx>
                                Drawing entries <strong>{drawDatetimeString}</strong>.
                            </Colxx>
                        </Row>
                    </Colxx>
                    <Colxx sm={4} md={4} xs={4} className="text-right">
                        <ButtonGroup size="sm">

                            <Button
                                disabled={!hasUnapprovedWinners}
                                onBlur={() => {this.setState({confirmApproveAll: false})}}
                                onMouseLeave={() => {this.setState({confirmApproveAll: false})}}
                                color="success" onClick={this.approveAllWinners.bind(this)}>
                                <span className="fa fa-check"></span>{" "}
                                { this.state.confirmApproveAll ? "Confirm Approve All Winners" : "Approve All Winners"}
                            </Button>
                            <Button onClick={this.downloadDraw.bind(this)}>
                                <span className="fa fa-download"></span>{" "}
                                Download Winners
                            </Button>
                        </ButtonGroup>
                    </Colxx>
                </Row>
                <Row className="pt-4">
                    <Colxx>
                        <h3>Winners</h3>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx>
                        <Table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Mobile Number</th>
                                <th>Code</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                selectedDraw.winners.length > 0 ? selectedDraw.winners.map(row =>
                                    <WinnerRow data={row} key={row.id}/>
                                ) : <tr><td colSpan={5} className="text-center">No winners yet</td></tr>
                            }
                            </tbody>
                        </Table>
                    </Colxx>
                </Row>
                {
                    selectedDraw.is_generating !== false && <Row>
                        <Colxx className="text-center">
                            <Loading/>
                        </Colxx>
                    </Row>
                }
            </Colxx>
        </Row>
    }

    render() {
        const selectedDraw = this.state.selectedDraw && this.state.data[this.state.selectedDraw];
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Button
                                disabled={!(this.state.data && Object.keys(this.state.data).length > 0)}
                                className="float-right" color="success" onClick={this.downloadAll.bind(this)}>
                                <span className="fa fa-download"></span>{" "}
                                Download All
                            </Button>
                            <h2>Previous Draws</h2>

                        </CardTitle>

                        <Row>
                            <Colxx>
                                <Nav tabs>
                                    {Object.values(this.state.data).sort((rowA, rowB) => {
                                        if (rowA.datetime_draw > rowB.datetime_draw) {
                                            return -1
                                        }
                                        if (rowA.datetime_draw < rowB.datetime_draw) {
                                            return 1
                                        }
                                        return 0
                                    }).map(row => <NavItem >
                                        <NavLink
                                            active={this.state.selectedDraw === row.id}
                                            className={this.state.selectedDraw === row.id ? "" : "text-semi-muted"}
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                this.setState({selectedDraw: row.id})
                                            }}
                                        >
                                            <strong>{row.draw_key}</strong> <br/>
                                            <small>{moment(row.datetime_draw).fromNow()}</small>
                                        </NavLink>
                                    </NavItem>)}
                                </Nav>
                            </Colxx>
                        </Row>
                        {this.renderSelectedDraw(selectedDraw)}
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}