import React, {Component} from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Col, FormGroup, FormText, Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Select from "react-select";
import Creatable from "react-select/creatable/dist/react-select.esm";


export default class TagEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(JSON.stringify(props.data)),
            originalData: JSON.parse(JSON.stringify(props.data)),
            nameError: "",
            isCheckingName: false,
            deletedValues: []
        }
    }

    addValue() {
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                values: [
                    ...prevState.data.values,
                    {value:'', id: null}
                ]
            }
        }))
    }

    deleteValue(id) {
        this.setState(prevState => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    values: [
                        ...prevState.data.values.filter(v => v.id !== id)
                    ]
                }
            }
        })
    }

    updateValueValue(id, newValue, idx) {
        this.setState(prevState => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    values: [
                        ...prevState.data.values.map((t, mapIndex) => {
                            if ((id && t.id === id) || (!id && idx === mapIndex)) {
                                return {
                                    ...t,
                                    value: newValue
                                }
                            } else {
                                return t
                            }
                        })
                    ]
                }
            }
        })
    }

    canSave() {
        if (!this.state.data.name) {
            return false
        }
        if (this.state.isCheckingName || this.state.nameError !== '' || this.state.isCheckingName == null) {
            return false
        }
        return JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData)
    }

    checkName() {
        this.setState({nameError: "", isCheckingName: true}, () => {
            if (!this.state.data.name) {
                this.setState({nameError: "Name is required", isCheckingName: false})
            } else if (this.state.data.name !== this.state.originalData.name) {
                this.props.api.getItem('name_availability', {params: {name: this.state.data.name}}).then(response => {
                    if (response.data.exists) {
                        this.setState({nameError: "Tag name already exists"})
                    }
                }).finally(() => {
                    this.setState({isCheckingName: false})
                })
            } else {
                this.setState({isCheckingName: false})
            }
        })
    }

    render() {
        const options = [
            ...this.state.data.values.map(row => ({
                label: row.value,
                id: row.id,
                value: row.value,
            })),
            ...this.state.deletedValues.map(row => ({
                label: row.value,
                id: row.id,
                value: row.value,
                isDeleted: true
            })),

        ];

        const selectedOptions = [
            ...options.filter(o => !o.isDeleted)
        ]

        return <Modal isOpen={true} size="lg">
            <ModalHeader>
                {this.state.originalData.id ? <>{this.state.originalData.name} - Editing Tag</> : "Create new Tag"}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>
                                Name{" "}

                                {this.state.isCheckingName && <span className="fa fa-sync spin"/>}
                            </Label>
                            <Input type="text" value={this.state.data.name} onChange={e => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    data: {...prevState.data, name: e.target.value},
                                    isCheckingName: null
                                }))
                            }}
                                   onBlur={this.checkName.bind(this)}
                            />
                            <FormText color="danger">
                                {this.state.nameError}
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>
                                Values
                            </Label>
                            <Creatable

                                isMulti={true}
                                className="react-select small"
                                classNamePrefix="react-select"
                                options={options}

                                value={selectedOptions}
                                onChange={changedOptions => {
                                    const changedOptionValues = changedOptions.map(o => o.value)
                                    this.setState(prevState => {
                                        return {
                                            ...prevState,
                                            data: {
                                                ...prevState.data,
                                                values: [
                                                    ...changedOptions.map(o => ({
                                                        id: o.id,
                                                        value: o.value
                                                    }))
                                                ]
                                            },
                                            deletedValues: [
                                                ...prevState.data.values.filter(
                                                    o => changedOptionValues.indexOf(o.value) === -1
                                                )
                                            ]
                                        }
                                    })
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>



            </ModalBody>
            <ModalFooter>
                <ButtonGroup>
                    <Button
                        color={"success"}
                        onClick={() => {
                            this.props.onSave(this.state.data)
                        }}
                        disabled={!this.canSave()}
                    >
                        <span className="fa fa-save"/> Save
                    </Button>
                    <Button color={"danger"} onClick={() => {this.props.onCancel() } }>
                        Cancel
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </Modal>
    }
}