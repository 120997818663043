import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup, FormText,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import TemplateEdit from "../mail/templateEdit";
import {toast} from "react-toastify";
import ChatbotRecipientMappingScreenshot from '../../assets/img/chatbot-recipient-mapping-screenshot.png';


const DEFAULT_WEBHOOK_DATA = {
    'name': null,
    'uid': null,
    tracking_parameter: '',
}


class ApiHookPromoForm extends Component {
    state = {
        promoId: null,
        promos: [],
        entryAttribute: '',
        showNew: false
    }
    promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    hookApi = new ApiClient('hook', authenticationErrorHandler.bind(this))

    componentDidMount() {
        this.updatePromoList()
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.props.history.replace('/login/')
    }

    updatePromoList() {
        this.promoApi.list().then(response => {
            this.setState({promos: response.data.result})
        })
    }

    handleChangePromo(option) {
        this.setState({promoId: option.value})
    }

    handleEntryAttributeChange(e) {
        this.setState({
            entryAttribute: e.target.value
        })
    }

    isDataChanged() {
        const selectedPromoId = this.state.promoId ? this.state.promoId : this.props.originalData && this.props.originalData.config.id;
        const entryAttribute = this.state.entryAttribute ? this.state.entryAttribute : this.props.originalData && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME;
        if (this.props.originalData && this.props.originalData.id) {
            return (this.state.promoId && this.props.originalData.config.id !== this.state.promoId) || (this.state.entryAttribute && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME !== this.state.entryAttribute)
        } else {
            return !!this.state.promoId;
        }
        return this.state.promoId !== selectedPromoId || this.state.entryAttribute !== entryAttribute;
    }

    handleSave() {
        const id = this.props.originalData && this.props.originalData.id;
        const selectedPromoId = this.state.promoId ? this.state.promoId : this.props.originalData && this.props.originalData.config.id;
        const entryAttribute = this.state.entryAttribute ? this.state.entryAttribute : this.props.originalData && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME;
        const data = {
            "hook_type": "promo",
            "config": {
                "id": selectedPromoId,
                "ENTRY_COUNT_PARAM_NAME": entryAttribute
            }
        }
        if (id) {
            this.hookApi.patchItem(id, data).then(response => {
                this.props.handleHookSave(response.data)
            })
        } else {
            this.hookApi.create({...data, webhook: this.props.webhookUid}).then(response => {
                this.props.handleHookSave(response.data)
            })
        }


    }

    render() {
        if (!(this.props.originalData && this.props.originalData.id) && !this.state.showNew) {
            return <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <Button onClick={() => {
                                    this.setState({showNew: true})
                                }}>
                                    <span className="fa fa-trophy"/> Enable Promo Hook
                                </Button>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        }
        const promoOptions = this.state.promos.map(promo => {
            return {
                "value": promo.id,
                "label": promo.title,
                "key": "promo-" + promo.id
            }
        })
        const selectedPromoId = this.state.promoId ? this.state.promoId : this.props.originalData && this.props.originalData.config.id;
        const selectedPromoOption = selectedPromoId && promoOptions && promoOptions.filter(
            promoOption => promoOption.value === selectedPromoId
        )[0]

        const entryAttribute = this.state.entryAttribute ? this.state.entryAttribute : this.props.originalData && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME;

        return <>
            <Row className="">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h4><span className="fa fa-trophy"/> Promo Hook</h4>
                            </CardTitle>
                            <Form>
                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="promoTitle">Promo</Label>

                                            <Select
                                                components={{Input: CustomSelect}}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                name="form-field-name"
                                                value={selectedPromoOption}
                                                placeholder="Select Promo"
                                                onChange={this.handleChangePromo.bind(this)}
                                                options={promoOptions}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                </Row>
                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="promoTitle">Points Fetching Attribute</Label>
                                            <Input
                                                type="text"
                                                name="entryAttribute"
                                                id="entryAttribute"
                                                placeholder="entry_count"
                                                value={entryAttribute}
                                                onChange={this.handleEntryAttributeChange.bind(this)}
                                            />
                                        </FormGroup>
                                        <p className="text-small">
                                            This will be used to send entry count to mobile monkey. Default if not set
                                            is <code>entry_count</code>
                                        </p>
                                    </Colxx>
                                </Row>

                                <Row>
                                    <Colxx className="text-right">
                                        <Button color="success" className="mt-2" disabled={!this.isDataChanged()}
                                                onClick={this.handleSave.bind(this)}>
                                            <span className="fa fa-save"/> Save Changes
                                        </Button>
                                    </Colxx>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            {
                !this.isDataChanged() && <>
                    <Row className="pb-4">
                        <Colxx>
                            <Card>
                                <CardBody>
                                    {this.props.points_fetch && <>
                                        <br/>
                                        <Label>Points Fetch URL - saving
                                            to <code>{this.props.points_fetch.key}</code> attribute</Label>
                                        <InputGroup>
                                            <InputGroupText className="text-small">GET</InputGroupText>
                                            <Input
                                                type="text" readOnly={true} value={this.props.points_fetch.url}
                                                size="lg" style={{textOverflow: "ellipsis"}}/>
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="success" size="xs"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(this.props.points_fetch.url);
                                                        toast("Points Fetching URL copied to clipboard")
                                                    }}>
                                                    <span className="fa fa-clipboard"/> copy
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </>}


                                    {this.props.dispute && <>
                                        <br/>
                                        <Label>Dispute URL</Label>
                                        <InputGroup>
                                            <InputGroupText className="text-small">POST</InputGroupText>
                                            <Input
                                                type="text" readOnly={true} value={this.props.dispute.url}
                                                size="lg" style={{textOverflow: "ellipsis"}}/>
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="success" size="xs"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(this.props.dispute.url);
                                                        toast("Dispute URL copied to clipboard")
                                                    }}>
                                                    <span className="fa fa-clipboard"/> copy
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </>}
                                    <br/>
                                    All Promo URLs require a mapping of
                                    recipient id -> recipient_id
                                    <br/>

                                    <img className="image responsive" src={ChatbotRecipientMappingScreenshot}
                                         style={{maxWidth: "300px"}}/>
                                </CardBody>
                            </Card>
                        </Colxx>

                    </Row>
                </>
            }
            <br/>
        </>
    }
}


export default class ApiHookForm extends Component {
    state = {
        ...DEFAULT_WEBHOOK_DATA,
        promos: [],
        originalData: {...DEFAULT_WEBHOOK_DATA},
        showTrackingParameter: false
    }

    webhookApi = new ApiClient('webhook', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.props.history.replace('/login/')
    }

    componentDidMount() {
        const {match} = this.props;
        const webhookUid = match.params.webhookUid

        if (webhookUid) {
            this.webhookApi.getItem(webhookUid).then(this.processResponseData.bind(this))
        }

    }

    processResponseData(response) {

        const data = response.data
        this.setState({
            ...data,
            originalData: {...data},
            showTrackingParameter: !!data.tracking_parameter
        })
    }

    isDataChanged() {
        return this.state.originalData.name !== this.state.name || this.state.originalData.tracking_parameter !== this.state.tracking_parameter
    }

    handleNameChange(e) {
        this.setState({"name": e.target.value})
    }

    handleSave() {
        const newData = {name: this.state.name, tracking_parameter: this.state.tracking_parameter}

        const {match} = this.props;
        const webhookUid = match.params.webhookUid

        if (webhookUid) {
            this.webhookApi.updateItem(webhookUid, newData).then(this.processResponseData.bind(this))
        } else {
            this.webhookApi.create(newData).then(response => {
                const newPromoId = response.data.uid;
                this.props.history.push('/app/apiHooks/' + newPromoId + '/')
            })
        }
    }

    handleHookSave(hookData) {
        this.setState(prevState => {
            const isExistingHook = prevState.hooks.some(h => h.id === hookData.id)
            return {
                ...prevState,
                hooks: isExistingHook ? prevState.hooks.map(h => {
                    if (h.id === hookData.id) {
                        return {...h, ...hookData}
                    }
                    return h
                }) : [
                    ...prevState.hooks,
                    hookData
                ],
                originalData: {
                    ...prevState.originalData,
                    hooks: isExistingHook ? prevState.originalData.hooks.map(h => {
                        if (h.id === hookData.id) {
                            return {...h, ...hookData}
                        }
                        return h
                    }) : [
                        ...prevState.originalData.hooks,
                        hookData
                    ]
                }
            }
        })
    }

    render() {
        const promoHooks = this.state.hooks && this.state.hooks.filter(hook => hook.hook_type === 'promo')
        const promoHook = promoHooks && promoHooks[0];

        const mailHooks = this.state.hooks && this.state.hooks.filter(hook => hook.hook_type === 'mail')
        const mailHook = mailHooks && mailHooks[0]

        return <>
            <Row>
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>{
                                    this.state.originalData.name ?
                                        <span>
                                            <strong>{this.state.originalData.name}</strong> - Edit API Connection
                                        </span> :
                                        "New Webhook"
                                }</h2>
                                {
                                    this.state.originalData.uid && <small className="text-muted">
                                        ID: {this.state.originalData.uid}
                                    </small>
                                }
                            </CardTitle>
                            <Row form>
                                <Colxx md={12}>
                                    <FormGroup>
                                        <Label for="promoTitle">API Hook Name</Label>
                                        <Input
                                            type="text"
                                            name="webhookName"
                                            id="webhookName"
                                            placeholder="API Hook Name"
                                            value={this.state.name}
                                            onChange={this.handleNameChange.bind(this)}
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>


                            {
                                this.state.showTrackingParameter ? <div className="px-4 py-2 border-1px border-light">
                                    <Row form>
                                        <Colxx md={12}>
                                            <FormGroup>
                                                <Label for="trackingParameter">
                                                    <span className="fa fa-shoe-prints"/> Tracking Parameter
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="trackingParameter"
                                                    id="trackingParameter"
                                                    placeholder="Tracking Parameter"
                                                    value={this.state.tracking_parameter}
                                                    onChange={e => {
                                                        this.setState({tracking_parameter: e.target.value})
                                                    }}
                                                />
                                                <FormText>Platform will look for this parameter/attribute in the data it
                                                    receives from the Chatbot.</FormText>
                                            </FormGroup>
                                        </Colxx>
                                    </Row>
                                    <Row form>
                                        <Colxx md={12}>
                                            <Button color="danger" size="xs" onClick={() => {
                                                this.setState({
                                                    tracking_parameter: '',
                                                    showTrackingParameter: false
                                                })
                                            }}>
                                                <span className="fa fa-shoe-prints"/> Disable Tracker
                                            </Button>
                                        </Colxx>
                                    </Row>
                                </div> : <Row form>
                                    <Colxx md={12}>
                                        <Button size="xs" onClick={() => {
                                            this.setState({showTrackingParameter: true})
                                        }}>
                                            <span className="fa fa-shoe-prints"/> Enable Tracker
                                        </Button>
                                    </Colxx>
                                </Row>

                            }


                            <Row>
                                <Colxx className="text-right">

                                    <Button color="success" className="mt-2" disabled={!this.isDataChanged()}
                                            onClick={this.handleSave.bind(this)}>
                                        <span className="fa fa-save"/> Save Changes
                                    </Button>
                                </Colxx>
                            </Row>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            {
                this.state.originalData.receive_url && <Row className="mb-4">
                    <Colxx>
                        <Card>
                            <CardBody>
                                <Label>Receiver URL</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="text-small">POST</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text" readOnly={true} value={this.state.originalData.receive_url}
                                        size="lg" style={{textOverflow: "ellipsis"}}/>
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            color="success" size="xs"
                                            onClick={() => {
                                                navigator.clipboard.writeText(this.state.originalData.receive_url);
                                                toast("Receiver URL copied to clipboard")
                                            }}>
                                            <span className="fa fa-clipboard"/> copy
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            }

            {
                this.state.originalData.uid &&
                <ApiHookPromoForm
                    history={this.props.history}
                    webhookUid={this.state.originalData.uid}
                    originalData={promoHook}
                    handleHookSave={this.handleHookSave.bind(this)}
                    points_fetch={this.state.originalData.points_fetch}
                    dispute={this.state.originalData.dispute}
                />
            }


            {
                !!mailHook && <Row>
                    <Colxx>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <h4><span className="fa fa-envelope"/> Mail Template</h4>
                                </CardTitle>
                                <TemplateEdit
                                    id={mailHook.mail_uid}
                                    history={this.props.history}
                                />
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            }
        </>

    }
}