import React, {Component} from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Col, FormGroup, FormText, Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {DynamicFilterSet} from "../report/dynamicFilterSet";

export default class TagEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(JSON.stringify(props.data)),
            originalData: JSON.parse(JSON.stringify(props.data)),
            nameError: "",
            isCheckingName: false
        }
    }

    addValue() {
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                values: [
                    ...prevState.data.values,
                    {value:'', id: null, filter: {}}
                ]
            }
        }))
    }

    deleteValue(id) {
        this.setState(prevState => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    values: [
                        ...prevState.data.values.filter(v => v.id !== id)
                    ]
                }
            }
        })
    }

    updateValueFilter(id, filter) {
        this.setState(prevState => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    values: [
                        ...prevState.data.values.map(t => {
                            if (t.id === id) {
                                return {
                                    ...t,
                                    filter: filter
                                }
                            } else {
                                return t
                            }
                        })
                    ]
                }
            }
        })
    }

    updateValueValue(id, newValue, idx) {
        this.setState(prevState => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    values: [
                        ...prevState.data.values.map((t, mapIndex) => {
                            if ((id && t.id === id) || (!id && idx === mapIndex)) {
                                return {
                                    ...t,
                                    value: newValue
                                }
                            } else {
                                return t
                            }
                        })
                    ]
                }
            }
        })
    }

    canSave() {
        if (!this.state.data.name) {
            return false
        }
        if (this.state.isCheckingName || this.state.nameError !== '' || this.state.isCheckingName == null) {
            return false
        }
        return JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData)
    }

    checkName() {
        this.setState({nameError: "", isCheckingName: true}, () => {
            if (!this.state.data.name) {
                this.setState({nameError: "Name is required", isCheckingName: false})
            } else if (this.state.data.name !== this.state.originalData.name) {
                this.props.api.getItem('name_availability', {params: {name: this.state.data.name}}).then(response => {
                    if (response.data.exists) {
                        this.setState({nameError: "Tag name already exists"})
                    }
                }).finally(() => {
                    this.setState({isCheckingName: false})
                })
            } else {
                this.setState({isCheckingName: false})
            }
        })
    }

    render() {
        return <Modal isOpen={true} size="lg">
            <ModalHeader>
                {this.state.originalData.id ? <>{this.state.originalData.name} - Editing Tag</> : "Create new Tag"}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>
                                Name{" "}

                                {this.state.isCheckingName && <span className="fa fa-sync spin"/>}
                            </Label>
                            <Input type="text" value={this.state.data.name} onChange={e => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    data: {...prevState.data, name: e.target.value},
                                    isCheckingName: null
                                }))
                            }}
                                   onBlur={this.checkName.bind(this)}
                            />
                            <FormText color="danger">
                                {this.state.nameError}
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>

                {
                    this.state.data.values.map((tagValue, idx) => {
                        return <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <Row>
                                                <Col>
                                                    <Label>Value</Label>
                                                    <Input className="font-bold" type="text" value={tagValue.value} onChange={e => {
                                                        this.updateValueValue(tagValue.id, e.target.value, idx)
                                                    }}/>
                                                </Col>
                                                <Col sm={4} className="text-right">
                                                    <Button size="xs" color="danger" onClick={() => {
                                                        this.deleteValue(tagValue.id)
                                                    }}>
                                                        <span className="fa fa-trash"/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardTitle>

                                        <DynamicFilterSet
                                            mailTemplates={this.props.mailTemplates}
                                            indexes={this.props.indexes}
                                            filters={tagValue.filter}
                                            onConfigChange={(newConfig) => {
                                                this.updateValueFilter(tagValue.id, newConfig.filters)
                                            }}
                                            showWarning={false}/>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    })
                }

                <Row className="mt-4">
                    <Col className="text-center">
                        <Button onClick={this.addValue.bind(this)}>
                            Add Value
                        </Button>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <ButtonGroup>
                    <Button
                        color={"success"}
                        onClick={() => {
                            this.props.onSave(this.state.data)
                        }}
                        disabled={!this.canSave()}
                    >
                        <span className="fa fa-save"/> Save
                    </Button>
                    <Button color={"danger"} onClick={() => {this.props.onCancel() } }>
                        Cancel
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </Modal>
    }
}