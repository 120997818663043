export const ReactifyChartColors = () => {
  let rootStyle = getComputedStyle(document.body);
  return {
    ReactifyChartColor1: rootStyle
      .getPropertyValue("--reactify-theme-color-1")
      .trim(),
    ReactifyChartColor2: rootStyle
      .getPropertyValue("--reactify-theme-color-2")
      .trim(),
    ReactifyChartColor3: rootStyle
      .getPropertyValue("--reactify-theme-color-3")
      .trim(),
    ReactifyChartColor4: rootStyle
      .getPropertyValue("--reactify-theme-color-4")
      .trim(),
    ReactifyChartColor5: rootStyle
      .getPropertyValue("--reactify-theme-color-5")
      .trim(),
    ReactifyChartColor6: rootStyle
      .getPropertyValue("--reactify-theme-color-6")
      .trim(),
    ReactifyChartColor1_10: rootStyle
      .getPropertyValue("--reactify-theme-color-1-10")
      .trim(),
    ReactifyChartColor2_10: rootStyle
      .getPropertyValue("--reactify-theme-color-2-10")
      .trim(),
    ReactifyChartColor3_10: rootStyle
      .getPropertyValue("--reactify-theme-color-3-10")
      .trim(),
    ReactifyChartColor4_10: rootStyle
      .getPropertyValue("--reactify-theme-color-3-10")
      .trim(),
    ReactifyChartColor5_10: rootStyle
      .getPropertyValue("--reactify-theme-color-3-10")
      .trim(),
    ReactifyChartColor6_10: rootStyle
      .getPropertyValue("--reactify-theme-color-3-10")
      .trim(),
    primaryColor: rootStyle.getPropertyValue("--primary-color").trim(),
    foregroundColor: rootStyle
      .getPropertyValue("--reactify-foreground-color")
      .trim(),
    separatorColor: rootStyle
      .getPropertyValue("--reactify-separator-color")
      .trim()
  };
};
