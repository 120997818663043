import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    InputGroup, InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import Loading from "../../components/loading";
import Select from "react-select";

const ENTRY_FIELDS = [
    "name",
    "email_address",
    "mobile_number",
    "address",
    "city",
    "region",
    "country"
]


class PromoActionButtons extends Component {
    state = {
        showButtonGroup: null
    }
    buttonWidth = "55px";

    renderSubButton(buttonSet, mainButtonIndex, maxButtonLength) {
        const paddingLeft = [];
        const paddingRight = [];
        if (mainButtonIndex > 0) {
            for (let i = 0; i < mainButtonIndex; i++) {
                paddingLeft.push(<Button className="px-0" style={{width: this.buttonWidth}} color="light" outline
                                         disabled={true}/>)
            }
        }

        if ((buttonSet.length + mainButtonIndex + 1) < maxButtonLength) {
            for (let i = buttonSet.length + mainButtonIndex + 1; i < maxButtonLength; i++) {
                paddingRight.push(<Button className="px-0 border-0" style={{width: this.buttonWidth}} color="none"
                                          disabled={true}/>)
            }
        }
        return [
            ...paddingLeft,

            <Button className="px-0" style={{width: this.buttonWidth}} color="danger" onClick={() => {
                this.setState({showButtonGroup: null})
            }}>
                <span className="fa fa-times"/> <br/>
            </Button>,
            ...buttonSet,
            ...paddingRight
        ]
    }

    render() {
        const defaultButtons = this.defaultButtons()
        const codeButtons = this.codeButtons();
        const entriesButtons = this.entriesButtons();

        const maxButtonLength = Math.max(defaultButtons.length, codeButtons.length, entriesButtons.length)

        if (this.state.showButtonGroup === 'edit') {
            return this.editPromoButtons()
        } else if (this.state.showButtonGroup === 'codes') {
            return this.renderSubButton(
                codeButtons,
                defaultButtons.filter(row => row.name === 'codes')[0].index,
                maxButtonLength)
        } else if (this.state.showButtonGroup === 'entries') {
            return this.renderSubButton(
                entriesButtons,
                defaultButtons.filter(row => row.name === 'entries')[0].index,
                maxButtonLength
            )
        }

        // DEFAULT buttons
        return defaultButtons.map(o => o.btn)
    }

    defaultButtons() {
        const buttonList = [];
        let index = 0
        buttonList.push({
            index: index,
            name: 'edit-promo',
            btn: <Button className="px-0" style={{width: this.buttonWidth}} color="primary" onClick={() => {
                this.setState({showButtonGroup: 'edit'})
            }} disabled={!this.props.permission.edit}>
                <span className="fa fa-pen"/> <br/>
                <small>edit</small>
            </Button>

        });
        index += 1;

        buttonList.push({
            index: index,
            name: 'codes',
            btn: <Button className="px-0" style={{width: this.buttonWidth}} color="primary" onClick={() => {
                this.setState({showButtonGroup: 'codes'})
            }} disabled={!(this.props.permission.disburse_code || this.props.permission.resolve_disputes)}>
                <span className="fa fa-qrcode"/><br/>
                <small>codes</small>
            </Button>
        });
        index += 1;

        buttonList.push({
            index: index,
            name: 'entries',
            btn: <Button className="px-0" style={{width: this.buttonWidth}} color="primary" onClick={() => {
                this.setState({showButtonGroup: 'entries'})
            }} disabled={!this.props.permission.view_entries}>
                <span className="fa fa-ticket-alt"/><br/>
                <small>entry</small>
            </Button>
        });
        index += 1;
        //
        // buttonList.push({
        //     index: index,
        //     name: 'report',
        //     btn: <Button className="px-0" style={{width: this.buttonWidth}} color="primary" onClick={() => {
        //         this.props.history.push('/app/promo/' + this.props.promoId + '/report/')
        //     }}
        //                  disabled={!this.props.permission.view_report}>
        //         <span className="fa fa-chart-bar"/><br/>
        //         <small>report</small>
        //     </Button>
        // });
        // index += 1;

        buttonList.push({
            index: index,
            name: 'draw',
            btn: <Button className="px-0" style={{width: this.buttonWidth}} color="primary" onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/winners/')
            }} disabled={!this.props.permission.draw_winner && !this.props.permission.view_report}>
                <span className="fa fa-trophy"/><br/>
                <small>draw</small>
            </Button>
        });
        index += 1;

        buttonList.push({
            index: index,
            name: 'placeholder',
            btn: <Button className="px-0 border-0" style={{width: this.buttonWidth}} color="none" onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/winners/')
            }} disabled={true}>

            </Button>
        });

        return buttonList;
    }

    editPromoButtons() {
        if (!this.props.permission.edit) {
            return []
        }

        return [
            <Button className="px-0" style={{width: this.buttonWidth}} color="danger" onClick={() => {
                this.setState({showButtonGroup: null})
            }} title="Close menu">
                <span className="fa fa-times"/> <br/>
                <small>edit</small>
            </Button>,

            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/')
            }} title="Main Promo Settings">
                <span className="fa fa-pen"/> <br/>
                <small>promo</small>
            </Button>,
            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/entryMapping/')
            }} disabled={!this.props.promo.webhook_uid} title={"Entry Property Mapping"}>
                <span className="fa fa-map-signs"/> <br/>
                <small>prop. map</small>
            </Button>,

            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/codeDefinition/')
            }} title="Code Settings">
                <span className="fa fa-qrcode"/> <br/>
                <small>code</small>
            </Button>,
            // <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline disabled>
            //     <span className="fa fa-users"/> <br/>
            //     <small>users</small>
            // </Button>,
            <Button className="px-0 border-0" style={{width: this.buttonWidth}} color="none"
                    disabled={true}/>
        ]
    }

    codeButtons() {

        // <Button className="px-0" style={{width: this.buttonWidth}} color="light" outline disabled={true}>
        // </Button>
        return [
            // <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline disabled>
            //     <span className="fa fa-stream"/> <br/>
            //     <small>list</small>
            // </Button>,
            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/disputes/')
            }} title="Disputes" disabled={!this.props.permission.resolve_disputes}>
                <span className="fa fa-exclamation-triangle"/> <br/>
                <small>dispute</small>
            </Button>,
            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/disburse/')
            }} title="Disburse" disabled={!this.props.permission.disburse_code}>
                <span className="fa fa-plus"/> <br/>
                <small>disburse</small>
            </Button>,
            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/codeReport/')
            }}
                    title={"Code Usage Report"}
                    disabled={!this.props.permission.view_report}>
                <span className="fa fa-chart-bar"/><br/>
                <small>report</small>
            </Button>
        ]
    }

    entriesButtons() {
        return [

            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/entries/')
            }} title={"Entries"}>
                <span className="fa fa-ticket-alt"/> <br/>
                <small>entries</small>
            </Button>,
            <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline onClick={() => {
                this.props.history.push('/app/promo/' + this.props.promoId + '/report/')
            }}
                    title={"Entry/Profile report"}
                    disabled={!this.props.permission.view_report}>
                <span className="fa fa-chart-bar"/><br/>
                <small>report</small>
            </Button>
            // <Button className="px-0" style={{width: this.buttonWidth}} color="secondary" outline disabled>
            //     <span className="fa fa-users"/> <br/>
            //     <small>profiles</small>
            // </Button>
        ]
    }
}

class PromoList extends Component {
    state = {
        promos: [],
        showButtonGroup: null,
        isPromosLoading: true,
        searchPromo: '',
        selectedShowPromo: null,
        canAdd: false
    }

    promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    componentDidMount() {
        this.setState({isPromosLoading: true}, () => {

            this.promoApi.list().then(response => {
                const responseData = response.data
                this.setState({
                    promos: [...responseData.result],
                    isPromosLoading: false,
                    canAdd: responseData.can_add
                })
            })
        })

    }

    renderPromoList() {
        if (this.state.isPromosLoading) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }

        if (this.state.promos.length === 0) {
            return <Row>
                <Colxx className="text-center">
                    No Promos yet. Create one now!
                </Colxx>
            </Row>

        }

        const visiblePromos = this.state.promos.filter(promo => {
            if (this.state.searchPromo) {
                const searchText = this.state.searchPromo.toLowerCase();
                return promo.title.toLowerCase().indexOf(searchText) > -1 || promo.description.toLowerCase().indexOf(searchText) > -1
            }
            return true
        }).filter(promo => {
            if (this.state.selectedShowPromo) {
                return promo.duration.toLowerCase().indexOf(this.state.selectedShowPromo.value) > -1
            }
            return true
        }).sort((a, b) => {
            return b.id - a.id
        })


        if (visiblePromos.length === 0) {
            return <Row>
                <Colxx className="text-center">
                    No promos matching your filters.
                </Colxx>
            </Row>

        }

        return visiblePromos.map(promo => {

            const badges = [];
            if (promo.duration.indexOf('ongoing') > -1) {
                badges.push(<Badge color="success">ONGOING</Badge>)
            } else if (promo.duration.indexOf('ended') > -1) {
                badges.push(<Badge color="danger">ENDED</Badge>)
            } else if (promo.duration.indexOf('not started') > -1) {
                badges.push(<Badge color="danger">NOT STARTED</Badge>)
            }

            const promoDuration = promo.duration.replace('(ongoing)', '').replace('(ended)', '').replace('(not started)', '')

            return <>
                <Row key={"promoRow" + promo.id}>
                    <Colxx className="my-auto" lg={3} md={5}>
                        <span className="text-muted">#{promo.id}</span> <strong>{promo.title}</strong>
                        <div className="float-right">
                            {
                                promo.webhook_uid && <Badge
                                    color="info" title={"ChatBot enabled"}
                                    onClick={() => {
                                        // this.history.push('/app/apiHooks/' + promo.webhook_uid + '/')
                                    }}
                                >
                                    <span className="fa fa-comments"/>
                                </Badge>
                            }

                            {
                                promo.enable_promo_pages && <Badge color="info" title={"Pages enabled"}>
                                    <span className="fa fa-laptop"/>
                                </Badge>
                            }
                        </div>
                    </Colxx>
                    <Colxx className="my-auto pb-1" title={promo.date_start + " to " + promo.date_end} lg={4} md={7}>
                        <Row>
                            <Colxx sm={8} xs={8} md={12}>

                                {promoDuration}{" "}
                                <i
                                    className="fa fa-info-circle font-color-1 mr-1"
                                    title={promo.date_start + " to " + promo.date_end}
                                />
                            </Colxx>
                            <Colxx sm={4} xs={4} md={12}>
                                {badges}
                            </Colxx>
                        </Row>
                    </Colxx>
                    <Colxx className="p-0 text-center" lg={5} md={12}>
                        <ButtonGroup size="xs">
                            <PromoActionButtons permission={promo.permission} promoId={promo.id} promo={promo}
                                                history={this.props.history}/>
                        </ButtonGroup>
                    </Colxx>
                </Row>
                <hr/>

            </>
        })
    }

    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col>
                                    <h2>Promos</h2>
                                </Col>
                                <Col lg={12} xl={8}>
                                    <Row className="d-flex  align-items-end">
                                        <Col lg={4} md={6} className="mb-2">
                                            <Select
                                                options={[
                                                    {value: "ended", label: "Show expired promos"},
                                                    {value: "not started", label: "Show future promos"},
                                                    {value: "ongoing", label: "Show running promos"},
                                                ]}
                                                value={this.state.selectedShowPromo}
                                                onChange={o => {
                                                    this.setState({selectedShowPromo: o})
                                                }}
                                                placeholder="Show all promos"
                                                isClearable={true}
                                            />
                                        </Col>
                                        <Col lg={4} md={6} className="mb-2">
                                            <InputGroup>
                                                <Input type="text" size={"lg"} value={this.state.searchPromo}
                                                       onChange={e => {
                                                           this.setState({searchPromo: e.target.value})
                                                       }}/>
                                                <InputGroupAddon addonType={"prepend"}>
                                                    <InputGroupText>
                                                        <span className="fa fa-search"/>
                                                    </InputGroupText>

                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                        {
                                            this.state.canAdd && <Col className="text-right mb-2">
                                                <Button size="sm" className="" onClick={() => {
                                                    this.history.push('/app/promo/new/')
                                                }}>
                                                    <span className="fa fa-plus"/> New Promo
                                                </Button>
                                            </Col>
                                        }
                                    </Row>

                                </Col>
                            </Row>

                        </CardTitle>


                        {this.renderPromoList()}
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }


}

export default PromoList