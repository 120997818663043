import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Input,
    NavbarBrand,
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import IntlMessages from "../../util/IntlMessages";

import PerfectScrollbar from "react-perfect-scrollbar";

import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {
    setContainerClassnames,
    clickOnMobileMenu,
    // logoutUser  // FIXME: logoutUser
} from "../../redux/actions";

import {defaultStartPath, menuHiddenBreakpoint, searchPath} from "../../constants/defaultValues";

import notifications from "../../data/topbar.notifications.json";
import getMessages from "../../data/topbar.messages.json";

// const p4 = require('../../assets/img/p4.png');
// import p4 from '../../assets/img/p4.png'
import defaultProfilePhoto from '../../assets/img/user-default.png';
import {clearAuthTokens} from "axios-jwt";
import Gallery from "react-fine-uploader";
import FineUploaderTraditional from "fine-uploader-wrappers";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import ChangePassword from "../../routes/extras/changePassword";
import Loading from "../../components/loading";
import {createImageFromInitials, stringToColour} from "../../util/RenderUtils";


const uploader = new FineUploaderTraditional({
    options: {
        chunking: {
            enabled: false
        },
        deleteFile: {
            enabled: false,
            endpoint: "/uploads"
        },
        request: {
            endpoint: "/uploads"
        }
    }
});


class TopNav extends Component {
    constructor(props) {
        super(props);
        this.menuButtonClick = this.menuButtonClick.bind(this);
        this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this);
        this.state = {
            isInFullScreen: false,
            profilePhotoModal: false,

            profileName: 'Loading...',
            profilePhoto: undefined,
            profileId: undefined,

            canSaveProfilePhoto: false,
            tmpProfilePhotoFile: undefined,

            changePasswordModal: false,
            isLoaded: false
        };

        this.history = props.history;
        this.profileApi = new ApiClient('profile', authenticationErrorHandler.bind(this))

        this.tmpProfilePhotoFileInput = React.createRef()
    }

    componentDidMount() {
        this.profileApi.getItem('me').then(response => {
            this.setState({
                profileId: response.data.id,
                profileName: response.data.name,
                profilePhoto: response.data.profile_photo,
                profileUpdateUrl: response.data.profile_photo_update_url,
                isLoaded: true
            })
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 403 || error.response.status === 401) {
                    this.handleLogout()
                }
            }
        })
    }

    handleLogout = () => {
        clearAuthTokens();
        this.history.replace('/login/')
    };

    menuButtonClick(e, menuClickCount, containerClassnames) {
        e.preventDefault();

        setTimeout(() => {
            var event = document.createEvent("HTMLEvents");
            event.initEvent("resize", false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(++menuClickCount, containerClassnames);
    }

    mobileMenuButtonClick(e, containerClassnames) {
        e.preventDefault();
        this.props.clickOnMobileMenu(containerClassnames);
    }

    toggleProfilePhotoModal() {
        this.setState(prevState => ({...prevState, profilePhotoModal: !prevState.profilePhotoModal}))
    }

    toggleChangePasswordModal() {
        this.setState(prevState => ({...prevState, changePasswordModal: !prevState.changePasswordModal}))
    }

    passwordChanged() {
        this.toggleChangePasswordModal()
    }

    handleProfilePhotoFileChange(e) {
        var formData = new FormData();
        formData.append("photo", this.state.tmpProfilePhotoFile);
        this.profileApi.put(this.state.profileUpdateUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            this.setState({"profilePhoto": response.data.photo, tmpProfilePhotoFile: undefined}, () => {
                this.toggleProfilePhotoModal()
            })
        })
    }

    updateTmpProfilePhoto() {
        const selectedImage = this.tmpProfilePhotoFileInput.current.files[0];
        this.setState({tmpProfilePhotoFile: selectedImage})
    }

    render() {
        const {containerClassnames, menuClickCount} = this.props;

        const defaultProfilePhotoV2 = this.state.profileName ? createImageFromInitials(45, this.state.profileName, stringToColour(this.state.profileName)) : profilePhotoSrc;

        const profilePhotoSrc = this.state.profilePhoto ? this.state.profilePhoto : defaultProfilePhotoV2;
        const changeProfilePhotoSrc = this.state.tmpProfilePhotoFile ? URL.createObjectURL(this.state.tmpProfilePhotoFile) : defaultProfilePhotoV2;
        return (
            <React.Fragment>
                <nav className="navbar fixed-top">
                    <NavbarBrand className="navbar-brand ml-3" href={'#' + defaultStartPath}>
                        iokidigital app
                    </NavbarBrand>
                    <NavLink
                        to="#"
                        className="menu-button d-none d-md-block"
                        onClick={e =>
                            this.menuButtonClick(e, menuClickCount, containerClassnames)
                        }
                    >
                        <span className="fa fa-stream"/>
                    </NavLink>
                    <NavLink
                        to="#"
                        className="menu-button-mobile d-xs-block d-sm-block d-md-none"
                        onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
                    >
                        <span className="fa fa-stream"/>
                    </NavLink>{" "}

                    <div className="ml-auto">
                        <div className="user d-inline-block">
                            <UncontrolledDropdown className="dropdown-menu-right">
                                <DropdownToggle className="p-0" color="empty">



                                    {
                                        this.state.isLoaded ? <>
                                            <span className="name mr-1">Welcome <strong>{this.state.profileName}</strong></span>
                                            <span>
                                                <img alt="Profile Picture" src={profilePhotoSrc}/>
                                            </span>
                                        </>: <Loading/>
                                    }

                                </DropdownToggle>
                                <DropdownMenu className="mt-3" right>
                                    <DropdownItem>
                                        <div className="float-left mr-3">
                                            {
                                                this.state.isLoaded ?
                                                    <img
                                                        className="Profile Picture"
                                                        src={profilePhotoSrc}
                                                        onClick={this.toggleProfilePhotoModal.bind(this)}/>
                                                    : <Loading/>
                                            }
                                            {" "}
                                        </div>
                                        <div className="">
                                            <strong>{this.state.profileName}</strong>
                                            {/*<p><small>Administrator</small></p>*/}
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem onClick={this.toggleChangePasswordModal.bind(this)}>
                                        <span className="fa fa-key font-color-1 mr-1"/> Change Password
                                    </DropdownItem>

                                    {/*<DropdownItem>*/}
                                    {/*  <span className="fa fa-user font-color-1 mr-1" /> Profile*/}
                                    {/*</DropdownItem>*/}
                                    {/*<DropdownItem>*/}
                                    {/*  <span className="fa fa-cog font-color-1 mr-1" /> Settings*/}
                                    {/*</DropdownItem>*/}
                                    {/*<DropdownItem>*/}
                                    {/*  <span className="fa fa-edit font-color-1 mr-1" /> Tasks*/}
                                    {/*</DropdownItem>*/}
                                    <DropdownItem divider/>
                                    <DropdownItem text>
                                        <span className="fa fa-question-circle"/> <strong>Need Assistance?</strong> <br/>
                                        Kindly send us an email at <a href="mailto:help@iokidigital.com">help@iokidigital.com</a>
                                    </DropdownItem>

                                    <DropdownItem divider/>
                                    <DropdownItem onClick={() => this.handleLogout()}>
                                        <span className="fa fa-power-off font-color-1 mr-1"/> Sign out
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </nav>

                <Modal isOpen={this.state.profilePhotoModal} toggle={this.toggleProfilePhotoModal.bind(this)}>
                    <ModalHeader toggle={this.toggleProfilePhotoModal.bind(this)}>
                        Update Profile Photo
                    </ModalHeader>
                    <ModalBody className="text-center">

                        <img
                            className="Profile Picture"
                            src={changeProfilePhotoSrc} style={{maxHeight: 250, maxWidth: 250}}/>

                        <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            ref={this.tmpProfilePhotoFileInput}
                            onChange={() => {
                                this.updateTmpProfilePhoto()
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.handleProfilePhotoFileChange.bind(this)}
                            disabled={!this.state.tmpProfilePhotoFile}>
                            Save
                        </Button>{" "}
                        <Button color="secondary" onClick={this.toggleProfilePhotoModal.bind(this)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.changePasswordModal} toggle={this.toggleChangePasswordModal.bind(this)}>
                    <ModalHeader toggle={this.toggleChangePasswordModal.bind(this)}>
                        Change Password
                    </ModalHeader>
                    <ModalBody>
                        <ChangePassword passwordChanged={this.passwordChanged.bind(this)}
                                        cancel={this.toggleChangePasswordModal.bind(this)}/>
                    </ModalBody>
                </Modal>

            </React.Fragment>

        );
    }
}

const mapStateToProps = ({menu}) => {
    const {containerClassnames, menuClickCount} = menu;
    return {containerClassnames, menuClickCount};
};
export default injectIntl(
    connect(
        mapStateToProps,
        {
            setContainerClassnames,
            clickOnMobileMenu,
            // logoutUser  // FIXME
        }
    )(TopNav)
);
