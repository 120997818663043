export const defaultMenuType = "menu-sub-hidden"; //'menu-default', 'menu-hidden'
export const defaultStartPath = "/login";
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English" },
  // { id: "fr", name: "Français" }
];

export const searchPath = "/app/main/layout";

export const defaultPromoDomain = 'promo.iokidigital.com'
