import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Row, Card, CardTitle, Button } from "reactstrap";
import { NavLink } from "react-router-dom";

import { Colxx } from "../../components/CustomBootstrap";

class Error404 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }
  render() {
    return (
      <Fragment>
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="bg-404">
                    <div className="form-side mx-auto my-5">
                      <NavLink to={`/`} className="white">
                        <img
                          className="w-250-px  px-2"
                          src="/assets/img/logo-dark.png"
                        />
                      </NavLink>
                      <div className="text-center">
                        <p className="display-1 font-weight-bold mb-5">404</p>
                        <Button
                          href="/app"
                          color="primary"
                          className="btn-shadow mb-2"
                          size="lg"
                        >
                          <IntlMessages id="error.go-home" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}
export default Error404;
