import preval from 'preval.macro'
import React, {Component} from "react";


export default class BuildInfo extends Component {
    render() {
        return <span title={"Build time: " + preval`module.exports = new Date().toLocaleString();` + " Pht"}>
            IokiDigital App v{process.env.REACT_APP_VERSION} &copy;{new Date().getFullYear()}
        </span>
    }
}