import React, {Component} from "react";
import {Button, ButtonGroup, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row} from "reactstrap";
import ApexCharts from "apexcharts";
import {DynamicFilterSet} from "../../../routes/mail/report/dynamicFilterSet";


export default class TitleEdit extends Component {
    render() {
        return <Card>
            <CardBody>
                <CardTitle className="mb-0">
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Title</Label>
                                <Input type={"text"} value={this.props.title}
                                       onChange={e => this.props.onTitleChange(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col className="text-right col-auto">
                            <ButtonGroup size="sm">

                                <Button
                                    color="success"
                                    disabled={!this.props.canSave}
                                    onClick={this.props.onSave}>
                                    <span className="fa fa-save"/>{" "}
                                    Save {!this.props.can_edit && "as a Copy"}
                                </Button>

                                <Button
                                    color="danger"
                                    onClick={this.props.onCancelEdit}>
                                    Cancel
                                </Button>

                            </ButtonGroup>

                        </Col>
                    </Row>

                    {
                        this.props.can_edit && <Row>
                            <Col>
                                <ButtonGroup size="xs" className="mt-1">
                                    <Button
                                        color="danger" disabled={this.props.is_shared}
                                        onClick={this.props.onDeleteToggle}>
                                        <span className="fa fa-trash"/>
                                        {this.props.is_active ? "Delete Dashboard" : "Undo Delete"}
                                    </Button>

                                    <Button
                                        color={this.props.is_shared ? "danger" : "success"}
                                        onClick={this.props.onPublishToggle}>
                                        <span className={"fa fa-eye" + (this.props.is_shared ? '-slash' : '')}/>{" "}
                                        {this.props.is_shared ? "Unpublish" : "Publish"} Dashboard
                                    </Button>


                                    <Button color="info" onClick={this.props.onSetDefault}>
                                        <span
                                            className="fa fa-star" style={
                                            this.props.is_default ? {color: "yellow"} : {}
                                        }/>{" "}
                                        {this.props.is_default ? "Unset Default" : "Set as Default"}
                                    </Button>
                                </ButtonGroup>
                                <br/>
                                {!this.props.is_active &&
                                <small className="text-muted text-danger">Warning! After saving, this
                                    dashboard will be deleted.</small>}
                            </Col>
                        </Row>
                    }
                </CardTitle>
                <DynamicFilterSet
                    hideEmailFilters={this.props.hideEmailFilters}
                    mailTemplates={this.props.mailTemplates}
                    indexes={this.props.indexes}
                    tagNames={this.props.tagNames}
                    filters={this.props.filters}
                    onConfigChange={newConfig => {
                        this.props.onChangeFilter(newConfig.filters)
                    }}
                    showWarning={false}
                />
            </CardBody>
        </Card>
    }
}