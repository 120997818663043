import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {InfoCard} from "../../promo/report/InfoCard";
import ApiInfoSet from "../../../components/apiInfoSet";
import RegistrationChart from "../../promo/report/RegistrationChart";
import moment from "moment";


export default class Promo extends Component {
    render() {
        return <>
            <hr/>
            <ApiInfoSet
                title="Promo Quick Overview"
                history={this.props.history}
                url={this.props.url}
            />

            <Row>
                <Col>
                    <RegistrationChart
                        title={"Registrations (last 7 days)"}
                        ref={this.registrationChart}
                        showExtras={false}
                        params={{
                            datetime_registered__gte: moment().subtract(7, 'd').format('YYYY-MM-DD')
                        }}
                        history={this.props.history}
                    />
                    {/* TODO: Code Usage Chart */}
                </Col>
            </Row>
        </>
    }
}