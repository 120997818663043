import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {
    Badge,
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    InputGroup, InputGroupText,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {toast} from "react-toastify";
import Switch from "rc-switch";
import Loading from "../../components/loading";
import Select from "react-select";


const DEFAULT_DATA = {
    is_enabled: true,
    allow_static_parameters: true,
    sender: "",
    email: "",
    email_cc: "",
    tags: [],
}

const REQUIRED_FIELDS = [
    'email',
    'subject_template',
]

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export default class TemplateEdit extends Component {
    state = {
        data: {},
        originalData: {},
        isLoading: true,
        hasError: false,
        editingId: null,
        isSaving: false,
        fieldErrors: {},

        tags: [],
    }

    constructor(props) {
        super(props);
        this.api = new ApiClient('template', authenticationErrorHandler.bind(this))
    }

    loadData() {
        if (this.props.id === 'new' || !this.props.id) {
            this.setState({
                data: JSON.parse(JSON.stringify(DEFAULT_DATA)),
                originalData: JSON.parse(JSON.stringify(DEFAULT_DATA)),
                hasError: false,
                isLoading: false
            })

        } else {
            this.setState({isLoading: true, hasError: false}, () => {
                this.api.getItem(this.props.id).then(response => {
                    this.setState({
                        data: response.data,
                        originalData: response.data,
                        hasError: false,
                        isLoading: false
                    })
                }).catch(() => {
                    this.setState({
                        isLoading: false,
                        hasError: true
                    })
                })
            })
        }
    }

    saveData() {
        const data = this.state.data;
        if (!data.sender) {
            data.sender = "noreply@iokidigital.com"
        }
        this.setState({isSaving: true}, () => {
            if (!this.props.id || this.props.id === 'new') {
                this.api.create(data).then(response => {
                    toast.success("New template had been added")
                    this.props.onUpdate && this.props.onUpdate(response.data)
                }).catch(() => {
                    toast.error("Something went wrong during saving. Please refresh page and retry. If problem persists, please contact administrator");
                    this.setState({isSaving: false})
                })
            } else {

                this.api.updateItem(this.props.id, data).then(response => {
                    toast.success("Template had been successfully updated.")
                    this.props.onUpdate && this.props.onUpdate(response.data)
                }).catch(() => {
                    toast.error("Something went wrong during saving. Please refresh page and retry. If problem persists, please contact administrator");
                    this.setState({isSaving: false})
                })
            }
        })
    }

    componentDidMount() {
        this.loadData()
    }

    canSave() {
        if (REQUIRED_FIELDS.some(fieldName => !this.state.data[fieldName])) {
            return false
        }
        return JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData) && !this.state.isSaving
    }

    validateFields() {
        this.setState(prevState => {
            const invalidEmails = prevState.data.email.split(',')
                .map(email => validateEmail(email.trim()) ? undefined : email)
                .filter(email => email)
            const invalidCC = prevState.data.email_cc.split(',')
                .map(email => validateEmail(email.trim()) ? undefined : email)
                .filter(email => email)
            return {
                ...prevState,
                fieldErrors: {
                    email: invalidEmails.length > 0 ? "Following email addresses might be invalid: " + invalidEmails.join(", ") + "." : null,
                    email_cc: invalidCC.length > 0 ? "Following email addresses might be invalid: " + invalidCC.join(", ") + "." : null
                }
            }
        }, () => {
            console.log(this.state.fieldErrors)
        })
    }

    renderContent() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        return <>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="d-flex align-items-center">
                                    Receiver Email{" "}
                                    {!this.state.data.email &&
                                    <Badge color="danger" className="ml-2 mb-1">Required</Badge>}
                                    {this.state.fieldErrors.email &&
                                    <Badge color="warning" className="ml-2 mb-1">Check email</Badge>}
                                </Label>
                                <Input type="text" value={this.state.data.email} onChange={e => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            email: e.target.value
                                        }
                                    }), () => {
                                        this.validateFields()
                                    })
                                }}/>
                                <span className="text-danger">{this.state.fieldErrors.email}</span>
                                <FormText>To add multiple receivers, separate them with a comma.</FormText>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>CC Email (Carbon Copy)</Label>
                                {this.state.fieldErrors.email_cc &&
                                <Badge color="warning" className="ml-2 mb-1">Check email</Badge>}
                                <Input type="text" value={this.state.data.email_cc} onChange={e => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            email_cc: e.target.value
                                        }
                                    }), () => {
                                        this.validateFields()
                                    })
                                }}/>
                                <span className="text-danger">{this.state.fieldErrors.email_cc}</span>
                                <FormText>To add multiple receivers, separate them with a comma.</FormText>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col xl={6} lg={12}>
                            <FormGroup>
                                <Label>From Email</Label>
                                <InputGroup>

                                    <Input
                                        type="text"
                                        value={this.state.data.sender.replace(/@iokidigital.com$/, '')}
                                        onChange={e => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                data: {
                                                    ...prevState.data,
                                                    sender: e.target.value + '@iokidigital.com'
                                                }
                                            }))
                                        }}
                                        placeholder="noreply"
                                    />
                                    <InputGroupText style={{lineHeight: "0.8em", backgroundColor: "lightgray"}}>
                                        @iokidigital.com
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xl={6} lg={12}>
                            <FormGroup>
                                <Label>Friendly Sender Name</Label>
                                <Input type="text" value={this.state.data.sender_name} onChange={e => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            sender_name: e.target.value
                                        }
                                    }))
                                }}/>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>

                                <Label className="d-flex align-items-center">
                                    Subject
                                    {!this.state.data.subject_template &&
                                    <Badge color="danger" className="ml-2 mb-1">Required</Badge>}
                                </Label>
                                <Input type="text" value={this.state.data.subject_template} onChange={e => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            subject_template: e.target.value
                                        }
                                    }))
                                }}/>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Body Header</Label>
                                <Input type="textarea" value={this.state.data.body_header} rows={4} onChange={e => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            body_header: e.target.value
                                        }
                                    }))
                                }}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Body Footer</Label>
                                <Input type="textarea" value={this.state.data.body_footer} rows={4} onChange={e => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            body_footer: e.target.value
                                        }
                                    }))
                                }}/>
                            </FormGroup>
                        </Col>
                    </Row>

                </Col>
                <Col>

                    <Row>
                        <Col>

                            <FormGroup>
                                <Switch
                                    className="mr-2"
                                    checked={this.state.data.is_enabled}
                                    onChange={() => {
                                        this.setState(prevState => ({
                                            ...prevState,
                                            data: {
                                                ...prevState.data,
                                                is_enabled: !prevState.data.is_enabled
                                            }
                                        }))
                                    }}
                                />
                                <Label for="Description">Email Sending Enabled</Label>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                            <FormGroup>
                                <Switch
                                    className="mr-2"
                                    checked={this.state.data.allow_static_parameters}
                                    onChange={() => {
                                        this.setState(prevState => ({
                                            ...prevState,
                                            data: {
                                                ...prevState.data,
                                                allow_static_parameters: !prevState.data.allow_static_parameters
                                            }
                                        }))
                                    }}
                                />
                                <Label for="Description">Allow Static Parameters</Label>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h5>Notes</h5>
                            <p>If Allow static parameters is checked, webhook
                                will check the following parameters and replace parts of the email if they are set.</p>
                            <ul>
                                <li>STATIC_EMAIL_SENDER</li>
                                <li>STATIC_EMAIL_SENDER_NAME</li>
                                <li>STATIC_EMAIL_DESTINATION</li>
                                <li>STATIC_EMAIL_CC</li>
                                <li>STATIC_EMAIL_SUBJECT</li>
                                <li>STATIC_EMAIL_HEADER</li>
                                <li>STATIC_EMAIL_FOOTER</li>
                            </ul>
                            <p>
                                You can also add <code>&#123;any_variable_name&#125;</code>and if they are set in data
                                sent to the
                                webhook, they will be replaced. Variable names only allow alphanumeric characters and
                                underscores.
                            </p>
                        </Col>
                    </Row>

                    {this.renderTags()}
                </Col>
            </Row>
        </>
    }

    renderTags() {
        if (!this.props.tagOptions || this.props.tagOptions.length === 0) {
            return ""
        }
        const tagOptions = this.props.tagOptions.map(tag => {
            return {
                label: tag.__str__,
                value: tag.id
            }
        })
        const selectedOptionIds = (this.state.data && this.state.data.tags) ? this.state.data.tags : []
        const selectedOptions = tagOptions.filter(o => selectedOptionIds.indexOf(o.value) >= 0)

        return <Row>
            <Col>
                <h5>Tags</h5>
                <Select
                    className="react-select small"
                    classNamePrefix="react-select"
                    options={tagOptions}
                    isMulti={true}

                    value={selectedOptions}
                    onChange={selectedTags => {
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                data: {
                                    ...prevState.data,
                                    tags: [...selectedTags.map(t => t.value)]
                                }
                            }
                        })
                    }}
                    // onChange={this.props.onFilterChange}
                />
            </Col>
        </Row>
    }

    render() {
        return <>
            {
                this.props.displayAsModal && <ModalHeader>
                    {this.state.originalData.subject_template ? <>{this.state.originalData.subject_template}</> : "Editing Template"}
                </ModalHeader>
            }
            <ModalBody>
                {this.renderContent()}
            </ModalBody>
            <ModalFooter>
                {
                    this.props.displayAsModal && <Button color="danger" disabled={this.state.isSaving}
                                                         onClick={() => this.props.onClose()}>Close</Button>
                }
                <Button onClick={this.saveData.bind(this)} disabled={!this.canSave()}>Save Changes</Button>
            </ModalFooter>
        </>

    }
}
