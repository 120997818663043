import React, { Component } from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import TemplateList from "./templateList";
import History from "./history";
// import Report from "./report";
import Report from "./report";
import Tag from "./tag";
import Recipient from "./recipient";


export default class Mail extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { match } = this.props;

        return (
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
                <Route path={`${match.url}/list`} component={TemplateList} />
                <Route path={`${match.url}/history`} component={History} />
                <Route path={`${match.url}/report`} component={Report} />
                <Route path={`${match.url}/recipient`} component={Recipient} />
                <Route path={`${match.url}/tag`} component={Tag} />
                <Redirect to="/error" />
            </Switch>
        );
    }
}