import React, {Component} from "react";
import {Col, FormGroup, FormText, Label, Row} from "reactstrap";
import Select from "react-select";

const maxSelectionPerChart = {
    'bar': 2,
    'line': 2,
    'area': 2,
    'pie': 1,
    'table': null,
    // 'info':
    'text': 0
}
const minSelectionPerChart = {
    'bar': 1,
    'line': 2,
    'area': 2,
    'pie': 1,
    'table': 1,
    // 'info':
    'text': 0
}
const dateDimension = {
    value: 'date',
    label: 'Date',
    isFixed: true
}

export class DimensionsSelection extends Component {
    render() {

        const styles = {
            multiValue: (base, state) => {
                return state.data.isFixed ? {...base, backgroundColor: 'gray'} : base;
            },
            multiValueLabel: (base, state) => {
                return state.data.isFixed
                    ? {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
                    : base;
            },
            multiValueRemove: (base, state) => {
                return state.data.isFixed ? {...base, display: 'none'} : base;
            },
        };

        const dimensionOptions = [

            ...(!this.props.hideEmailFilters ? [
                {value: "template", label: "Mail Template", group: "System"},
                // {value: "recipient", label: "Recipient (to or cc)"},
                {value: "sender", label: "Sender", group: "System"},
                {value: "to", label: "To Recipient", group: "System"},
                {value: "cc", label: "CC Recipient", group: "System"},
                {value: "subject", label: "Sent Email Subject", group: "System"},
            ] : []),
            {value: "month", label: "Month", group: "System"},
            {value: "year", label: "Year", group: "System"},
            {value: "quarter", label: "Quarter", group: "System"},
            ...this.props.tagNames.map(i => {
                return {value: "t__" + i.id, label: i.name, group: "Email Tags"}
            }),
            ...this.props.indexes.map(i => {
                return {value: "i__" + i, label: i, group: "Data Received (Warning! Raw data from hook)"}
            }),
        ]
        if (this.props.chartType === 'table') {
            dimensionOptions.push({value: "total", label: "Total Count", group: "System"})
            dimensionOptions.push({value: 'datetime', label: "Datetime Sent", group: "System"})
        }
        const selectedDimensions = this.props.selectedDimensions.map(v => dimensionOptions.filter(o => o.value === v)[0])


        // force selection of date for area and line chart
        if (this.props.chartType === 'area' || this.props.chartType === 'line') {
            dimensionOptions.push(dateDimension)
            selectedDimensions.unshift(dateDimension)
        }
        const maxSelections = maxSelectionPerChart[this.props.chartType];
        const minSelections = minSelectionPerChart[this.props.chartType];

        if (maxSelections === 0) {
            return null
        }


        const groupedOptions = Object.values(dimensionOptions.reduce((acc, o) => {
            if (!acc.hasOwnProperty(o.group)) {
                acc[o.group] = {
                    label: o.group,
                    options: []
                }
            }
            acc[o.group].options.push(o)
            return acc
        }, {}))

        const counterOptions = [
            {label: "Count impressions", value: "id"},
            {label: "Count sessions", value: "session"},
            {label: "Count users", value: "recipient"}
        ]
        const selectedCounter = this.props.count ? counterOptions.filter(o => o.value === this.props.count)[0] : counterOptions[0]

        return <>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Dimensions</Label>

                        <Select
                            isClearable={false}
                            isMulti={maxSelections !== 1}
                            className="react-select"
                            classNamePrefix="react-select"
                            value={selectedDimensions}
                            placeholder={"Show all"}
                            styles={styles}
                            onChange={os => {
                                const newSelectedOptions = Array.isArray(os) ? os : [os]
                                const slicedSelections = os && maxSelections ? newSelectedOptions.slice(0, maxSelections) : newSelectedOptions
                                this.props.onConfigChange({
                                    dimensions: newSelectedOptions ?
                                        slicedSelections.map(o => o.value).filter(v => v !== 'date').sort((a, b) => {
                                            if (a === 'total') {
                                                return 1
                                            }
                                            if (b === 'total') {
                                                return -1
                                            }
                                            return 0
                                        })
                                        : []
                                })
                            }}
                            options={groupedOptions}
                        />
                        {maxSelections > 1 && <FormText>
                            {minSelections && <>Minimum selection: {minSelections}</>}
                            {maxSelections !== minSelections && <>, Max selections: {maxSelections}</>}
                        </FormText>}
                    </FormGroup>
                </Col>
            </Row>
            {
                this.props.hideEmailFilters && <Row>
                    <Col>
                        <FormGroup>
                            <Label>Count By</Label>

                            <Select
                                isClearable={false}
                                className="react-select"
                                classNamePrefix="react-select"
                                value={selectedCounter}
                                styles={styles}
                                onChange={o => {
                                    this.props.onConfigChange({
                                        count: o.value
                                    })
                                }}
                                options={counterOptions}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            }
        </>
    }
}