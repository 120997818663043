import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    InputGroup, InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";

import moment from "moment";
import Switch from "rc-switch";
import PromoPages from "./promoPages";
import {toast} from "react-toastify";
import {defaultPromoDomain} from "../../constants/defaultValues";
import PromoEditNavigation from "./promoEditNavigation";


const DEFAULT_PROMO_DATA = {
    "title": "",
    "description": "",
    "date_start": null,
    "date_end": null,

    "enable_promo_pages": false,

    "custom_link": "",
    "google_analytics_code": "",
    "show_email_field": true,
    "require_email_field": true,
    "require_code": true,
    "require_attachment": true,
}


class PromoForm extends Component {
    state = {
        ...DEFAULT_PROMO_DATA,
        originalData: {...DEFAULT_PROMO_DATA}
    }

    promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {match} = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            this.promoApi.getItem(promoID).then(this.processResponseData.bind(this))
        }
    }

    processResponseData(response) {
        const promoData = response.data
        this.setState({...promoData, originalData: {...promoData}})
    }

    handleChangeStartDate(date) {
        this.setState(prevState => {
            const formattedStartDate = !!date ? moment(date).format("YYYY-MM-DD") : null
            return {
                date_start: formattedStartDate,
                date_end: !formattedStartDate ? prevState.date_end : (formattedStartDate > prevState.date_end ? null : prevState.date_end)
            }
        })
    }

    handleChangeEndDate(date) {
        this.setState(prevState => ({date_end: !!date ? moment(date).format("YYYY-MM-DD") : null}))
    }

    handleTitleChange(e) {
        this.setState({"title": e.target.value})
    }

    handleDescriptionChange(e) {
        this.setState({"description": e.target.value})
    }

    toggleEnablePromoPages(e) {
        this.setState(prevState => ({"enable_promo_pages": !prevState.enable_promo_pages}))
    }
    toggleRequireCode(e) {
        this.setState(prevState => ({"require_code": !prevState.require_code}))
    }
    toggleRequireAttachment(e) {
        this.setState(prevState => ({"require_attachment": !prevState.require_attachment}))
    }

    isDataChanged() {
        const newData = {...this.state}
        const originalData = newData["originalData"]
        delete newData["originalData"]

        const isEqual = Object.keys(newData).every(k => {
            return newData[k] === originalData[k]
        })
        return !isEqual
    }

    canSave() {
        return !!this.state.title && !!this.state.date_start && !!this.state.date_end && !!this.state.description && this.isDataChanged()
    }

    handleSave() {
        const newData = Object.keys(DEFAULT_PROMO_DATA).reduce((accumulator, key) => {
            accumulator[key] = this.state[key]
            return accumulator
        }, {})

        const {match} = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            this.promoApi.patchItem(promoID, newData).then(response => {
                toast.success("Changes for promo " + newData.title + " has been saved.")
                this.processResponseData(response)
            }).catch(error => {
                toast.error("Cannot save changes at this moment. Please try again. if it persists, please contact administrator.")
            })
        } else {
            this.promoApi.create(newData).then(response => {
                const newPromoId = response.data.id;
                toast.success("Created new promo " + newData.title)
                this.props.history.push('/app/promo/' + newPromoId + '/')
            }).catch(error => {
                toast.error("Cannot create a new promo at this moment. Please try again. if it persists, please contact administrator.")
            })
        }
    }

    renderPromoPagesSettings() {
        if (this.state.originalData.enable_promo_pages) {
            return <>

                <Row form>
                    <Colxx md={6}>
                        <FormGroup>
                            <Label for="customLink">Custom Link</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="pt-2" style={{
                                        lineHeight: "0.8em",
                                        backgroundColor: "lightgray"
                                    }}>https://{defaultPromoDomain}/</InputGroupText>
                                </InputGroupAddon>

                                <Input
                                    type="text"
                                    name="customLink"
                                    id="customLink"
                                    placeholder="Custom Link"
                                    value={this.state.custom_link}
                                    onChange={e => {
                                        this.setState({custom_link: e.target.value})
                                    }}
                                />

                                <InputGroupAddon addonType="append">
                                    <Button
                                        color="success" size="xs"
                                        onClick={() => {
                                            const customUrl = "https://" + defaultPromoDomain + "/" + this.state.custom_link
                                            navigator.clipboard.writeText(customUrl);
                                            toast("Copied custom URL")
                                        }}>
                                        <span className="fa fa-clipboard"/> copy
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <p>
                                <small>Pretty URL that will be used to access the URL. Example:
                                    https://{defaultPromoDomain}/{this.state.custom_link}</small>
                            </p>
                        </FormGroup>
                    </Colxx>
                    <Colxx md={6}>
                        <FormGroup>
                            <Label for="googleAnalyticsCode">Google Analytics Code</Label>
                            <Input
                                type="text"
                                name="googleAnalyticsCode"
                                id="googleAnalyticsCode"
                                placeholder="UA-123456790-0"
                                value={this.state.google_analytics_code}
                                onChange={e => {
                                    this.setState({google_analytics_code: e.target.value})
                                }}
                            />
                            <p>
                                <small>GA will be added to the promo pages if this is defined</small>
                            </p>
                        </FormGroup>
                    </Colxx>
                </Row>


                <Row form>
                    <Colxx md={6}>
                        <FormGroup>

                            <Switch
                                className="mr-2"
                                checked={this.state.show_email_field}
                                onChange={() => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        show_email_field: !prevState.show_email_field
                                    }))
                                }}
                            />
                            <Label for="show_email">Show email input during registration</Label>
                        </FormGroup>
                    </Colxx>
                    <Colxx md={6}>
                        <FormGroup>

                            <Switch
                                disabled={!this.state.show_email_field}
                                className="mr-2"
                                checked={this.state.require_email_field && this.state.show_email_field}
                                onChange={() => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        require_email_field: !prevState.require_email_field
                                    }))
                                }}
                            />
                            <Label for="require_email">Require email</Label>
                        </FormGroup>
                    </Colxx>
                </Row>
            </>
        }
    }

    render() {
        const {match} = this.props;
        const promoID = match.params.promoID

        const startDate = !!this.state.date_start && new Date(this.state.date_start);
        const endDate = !!this.state.date_end && new Date(this.state.date_end);

        return <>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-1">
                                <h2 className="mb-0">
                                    {
                                        this.state.originalData.title ?
                                            <span><strong>{this.state.originalData.title}</strong> - Editing</span> :
                                            "New Promo"
                                    }
                                </h2>
                            </CardTitle>
                            {   <PromoEditNavigation
                                data={this.state.originalData}
                                currentPage="edit-promo"
                                history={this.props.history}
                                hasUnsavedChanges={this.isDataChanged()}
                            /> }
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            <Row className="mb-4">
                <Colxx xxs={12}>
                    <Card>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="promoTitle">
                                                Title {" "}
                                                { !this.state.title && <small className="text-danger">(Required)</small> }
                                            </Label>
                                            <Input
                                                type="text"
                                                name="promoTitle"
                                                id="promoTitle"
                                                placeholder="Promo Title"
                                                value={this.state.title}
                                                onChange={this.handleTitleChange.bind(this)}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                </Row>
                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="Description">
                                                Description {" "}
                                                { !this.state.description && <small className="text-danger">(Required)</small> }
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="Promo simple description. This will appear in the registration page."
                                                value={this.state.description}
                                                onChange={this.handleDescriptionChange.bind(this)}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Row form>
                                    <Colxx lg={6}>
                                        <FormGroup>
                                            <Label>
                                                Promo Start {" "}
                                                { !startDate && <small className="text-danger">(Required)</small> }
                                            </Label>
                                            <DatePicker
                                                selected={startDate}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={this.handleChangeStartDate.bind(this)}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </FormGroup>
                                    </Colxx>
                                    <Colxx lg={6}>
                                        <FormGroup>
                                            <Label for="exampleZip">
                                                Promo End {" "}
                                                { !endDate && <small className="text-danger">(Required)</small> }
                                            </Label>
                                            <DatePicker
                                                selected={endDate}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                onChange={this.handleChangeEndDate.bind(this)}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Switch
                                                className="mr-2"
                                                checked={this.state.require_code}
                                                onChange={() => {
                                                    this.toggleRequireCode()
                                                }}
                                            />
                                            <Label for="Description">Require Code</Label>
                                            <p>
                                                <small>
                                                    If code is required:<br/>
                                                    1) for <strong>ChatBot</strong> promos, entries received without promo codes will be accepted but marked as invalid.<br/>
                                                    2) for <strong>Promo Pages</strong>, code input will be visible and required.
                                                </small>
                                            </p>
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Switch
                                                className="mr-2"
                                                checked={this.state.require_attachment}
                                                onChange={() => {
                                                    this.toggleRequireAttachment()
                                                }}
                                            />
                                            <Label for="Description">Require Attachment</Label>
                                            <p>
                                                <small>
                                                    If user has not submitted an attachment through ChatBot, it will be marked invalid. For promo page registrations, it will show the Proof of Entry field.
                                                </small>
                                            </p>
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Switch
                                                className="mr-2"
                                                checked={this.state.enable_promo_pages}
                                                onChange={() => {
                                                    this.toggleEnablePromoPages()
                                                }}
                                            />
                                            <Label for="Description">Enable Promo pages</Label>
                                            <p>
                                                <small>Enabling promo pages will allow traditional entry registration
                                                    using a webpage.</small>
                                            </p>
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                {this.renderPromoPagesSettings()}


                                <Button color="primary" className="mt-2" disabled={!this.isDataChanged() || !this.canSave()}
                                        onClick={this.handleSave.bind(this)}>
                                    Save
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            { this.state.originalData.enable_promo_pages && <PromoPages promoId={promoID} key={promoID.toString() + this.state.originalData.custom_link}/> }
        </>
    }
}

export default injectIntl(PromoForm);