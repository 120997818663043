import React, {Component} from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";


export default  class TablePagination extends Component{
    render() {
        // if (!this.props.hasNext && !this.props.hasPrevious) {
        //     return <></>
        // }

        const pageCount = Math.ceil(this.props.totalCount / this.props.limit);
        const visiblePages = [];

        let startPageCounting = this.props.page - 2 > 0 ? this.props.page - 2 : 1;
        let endPageCounting = startPageCounting + 5 <= pageCount ? startPageCounting + 5 : pageCount;
        if (endPageCounting - startPageCounting < 5) {
            startPageCounting = endPageCounting - 5 > 0 ? endPageCounting - 5 : 1
        }

        while (startPageCounting <= endPageCounting) {
            visiblePages.push(startPageCounting)
            startPageCounting++;
        }

        return <Pagination size="sm" aria-label="Page navigation example" className="justify-content-center">
            <PaginationItem disabled={!this.props.hasPrevious} onClick={() => {
                this.props.hasPrevious && this.props.changePageHandler(1)
            }}>
                <PaginationLink
                    className="fa fa-angle-double-left"
                />
            </PaginationItem>
            <PaginationItem disabled={!this.props.hasPrevious} onClick={() => {
                this.props.hasPrevious && this.props.changePageHandler(this.props.page - 1)
            }}>
                <PaginationLink className="fa fa-angle-left"/>
            </PaginationItem>
            {
                visiblePages.map(
                    p => <PaginationItem
                        key={"page-" + p} active={p === this.props.page}
                        onClick={() => {
                            p !== this.props.page && this.props.changePageHandler(p)
                        }}
                    >
                        <PaginationLink>{p}</PaginationLink>
                    </PaginationItem>
                )
            }
            <PaginationItem disabled={!this.props.hasNext} onClick={() => {
                this.props.hasNext && this.props.changePageHandler(this.props.page + 1)
            }}>
                <PaginationLink
                    className="fa fa-angle-right"
                />
            </PaginationItem>
            <PaginationItem disabled={!this.props.hasNext} onClick={() => {
                this.props.hasNext && this.props.changePageHandler(pageCount)
            }}>
                <PaginationLink
                    className="fa fa-angle-double-right"
                />
            </PaginationItem>
        </Pagination>
    }
}