import React, {Component} from "react";
import {Badge, Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faEnvelope, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";

export default class RecipientRow extends Component {
    state = {
        confirmReset: false,
        isResetting: false
    }

    constructor(props) {
        super(props);

        this.api = new ApiClient('email', authenticationErrorHandler.bind(this))
    }

    reset() {
        if (this.state.confirmReset) {
            this.setState({isResetting: true}, () => {
                this.api.postItemAction(this.props.data.id, 'reset').then(response => {
                    this.setState({confirmReset: false, isResetting: false}, () => {
                        this.props.onReset(response.data)
                    })
                })
            })
        } else {
            this.setState({confirmReset: true})
        }
    }

    render() {
        const recipient = this.props.data;

        const valid = <span className="fa fa-check-circle text-success"/>
        const invalid = <span className="fa fa-times-circle text-danger"/>
        const warning = <span className="fa fa-exclamation-triangle text-warning"/>

        return <div
            className={"border-1px p-3 " + (recipient.can_receive ? (recipient.has_bounce ? 'border-warning' : '') : 'border-danger')}
            style={{height: "10em"}}>
            <Row>
                <Col>
                    <strong title= {recipient.email} className="" style={{width: "calc(100% - 8em)", display: "inline-block", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>

                        {recipient.can_receive ? (recipient.has_bounce ? warning : valid) : invalid}{" "}
                        {recipient.email}
                    </strong>

                    {
                        (!recipient.can_receive || recipient.has_bounce) &&
                        <Button
                            className="danger float-right"
                            color="danger"
                            outline={this.state.confirmReset}
                            disabled={this.state.isResetting}
                            size="xs"
                            style={{width: "7em", height: "3em"}}
                            onClick={this.reset.bind(this)}
                            onBlur={() => {this.setState({confirmReset: false})} }
                        >
                            {this.state.confirmReset ? "Confirm?" : <>Reset<br/>Status</>}
                        </Button>
                    }
                    {
                        recipient.is_complaint && <Badge color="outline-danger mr-2">
                            <FontAwesomeIcon
                                icon={faExclamation}
                                mask={faComment}
                                className={"text-danger"}
                                transform="shrink-7 up-.5"/>{" "}
                            Recipient Complained
                        </Badge>
                    }

                    {
                        recipient.is_bounce && <Badge color="outline-danger mr-2">
                            <FontAwesomeIcon
                                icon={faTimes}
                                mask={faEnvelope}
                                className={"text-danger"}
                                transform=""/>{" "}
                            Email Bounced
                        </Badge>
                    }

                    {
                        !recipient.is_bounce && recipient.has_bounce && <Badge color="outline-warning mr-2">
                            <FontAwesomeIcon
                                icon={faTimes}
                                mask={faEnvelope}
                                className={"text-warning"}
                                transform=""/>{" "}
                            Email Bounced
                        </Badge>
                    }
                </Col>
            </Row>
            <Row>
                <Col>

                </Col>
            </Row>

            <Row>
                <Col className="text-small pt-2">
                    {
                        recipient.is_complaint && <span className="text-muted">
                                            Recipient has submitted a complaint on the email received.
                                        </span>
                    }
                    {
                        recipient.bounce_clears_on && recipient.bounce_clears_on !== true && <span className="text-muted">
                                            Suspension will be cleared {moment(recipient.bounce_clears_on).fromNow()}.
                                        </span>
                    }
                    {
                        !recipient.bounce_clears_on && <span className="text-muted">
                                            Email sent to this recipient has permanently bounced!
                                        </span>
                    }

                    {
                        !recipient.is_bounce  && recipient.has_bounce && <span className="text-muted">
                                            Email sent has bounced. Further bounced emails can prevent sending to recipient.
                                        </span>
                    }
                    {
                        !recipient.has_bounce && !recipient.is_complaint && <span className="text-muted">
                                            Recipient status is now clear.
                                        </span>
                    }

                    { !recipient.can_receive && <strong className="text-danger"> Email sending is suspended.</strong> }
                </Col>
            </Row>
        </div>
    }
}