import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, CustomInput, Input, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import DatePicker from "react-datepicker";


const COL_SIZE = 3;

export class EntryFilter extends Component {
    state = {
        filterOptions: {},
        filterValues: {}
    }

    constructor(props) {
        super(props);

        this.entryApi = new ApiClient('entry', authenticationErrorHandler.bind(this))
    }

    componentDidMount() {
        this.fetchFilters()
    }

    fetchFilters() {
        const params = {}
        if (this.props.promoID) {
            params["promo_id"] = this.props.promoID
        }

        this.entryApi.getItem('filters', {params: params}).then(response => {
            this.setState({filterOptions: response.data})
        })
    }

    handleChangeFilterValue(fullFilterKey, filterOption) {
        this.setState(prevState => {
            return {
                ...prevState,
                filterValues: {
                    ...prevState.filterValues,
                    [fullFilterKey]: filterOption ? filterOption.value : undefined
                }
            }
        })
    }

    renderFilter(filterDefinition, filterValues) {
        const filterKey = filterDefinition.key
        const fullFilterKey = (filterDefinition.is_raw ? 'raw_data__' : '') + filterKey

        const selectedValue = filterValues[fullFilterKey]
        const selectedOption = selectedValue ? filterDefinition.values.filter(options => options.value === selectedValue)[0] : null
        const displayKey = filterKey.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());  // titleCase

        return <Colxx key={"filter-" + filterKey} md={COL_SIZE} className="mt-3">
            <Label>{displayKey}</Label>
            <Select
                isClearable={true}
                components={{Input: CustomSelect}}
                className="react-select small"
                classNamePrefix="react-select"
                name={"filter-" + filterKey}
                value={selectedOption}
                placeholder={"Select " + displayKey}
                onChange={newSelection => this.handleChangeFilterValue(fullFilterKey, newSelection)}
                options={filterDefinition.values}
            />
        </Colxx>
    }

    render() {
        const filterValues = this.state.filterValues;
        const maxDate = new Date();

        const originalStartDate = filterValues.datetime_registered__gte ? filterValues.datetime_registered__gte : null
        const originalEndDate = filterValues.datetime_registered__lte ? filterValues.datetime_registered__lte : null

        return <>
            {
                this.props.showInvalidEntryToggle && <Row>
                    <Colxx>
                        <CustomInput
                            type="checkbox"
                            id="CustomInlineCheckbox"
                            label={"Include invalid entries"}
                            checked={this.state.filterValues.include_invalid}
                            onChange={() => this.setState(
                                prevState => ({
                                    ...prevState,
                                    filterValues: {
                                        ...prevState.filterValues,
                                        include_invalid: !prevState.filterValues.include_invalid
                                    }
                                })
                            )}
                            inline
                        />
                    </Colxx>
                </Row>
            }
            <Row className="align-items-end d-flex">
                {
                    Object.keys(this.state.filterOptions).length > 0 &&
                    this.state.filterOptions.map(filterOptionDefinition => this.renderFilter(filterOptionDefinition, filterValues))
                }
                <Colxx md={COL_SIZE} className="mt-3">
                    <Label>Entry Date Range</Label>
                    <DatePicker
                        key="entry_date_range"
                        selectsRange
                        selected={originalStartDate}
                        startDate={originalStartDate}
                        endDate={originalEndDate}
                        onChange={dates => {
                            const [start, end] = dates;
                            if (originalStartDate !== start) {
                                this.handleChangeFilterValue(
                                    'datetime_registered__gte', {value: start})
                            }
                            if (originalEndDate !== end) {
                                this.handleChangeFilterValue(
                                    'datetime_registered__lte', {value: end})
                            }

                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="All time"
                        isClearable={true}
                        maxDate={maxDate}
                        onCalendarClose={() => {
                            if (!originalEndDate) {
                                this.handleChangeFilterValue('datetime_registered__gte', {value: null})
                            }
                        }}
                    />
                </Colxx>
                <Colxx md={COL_SIZE} className="mt-3">
                    <Label>Search</Label>
                    {/*<InputGroup>*/}
                    <Input size="lg" placeholder="Search" onChange={e => {
                        this.handleChangeFilterValue('search', e.target)
                    }} value={filterValues.search}/>
                    {/*    <InputGroupAddon addonType={"append"}>*/}
                    {/*        <Button size="sm" onClick={() => {*/}
                    {/*            this.props.applyHandler()*/}
                    {/*        }}>*/}
                    {/*            <span className="fa fa-search"/>*/}
                    {/*        </Button>*/}
                    {/*    </InputGroupAddon>*/}
                    {/*</InputGroup>*/}
                </Colxx>

                <Colxx className="text-right mt-2">
                    <Button onClick={() => {
                        this.props.applyHandler(JSON.parse(JSON.stringify(this.state.filterValues)))
                    }}>
                        Apply Filters
                    </Button>
                </Colxx>
            </Row>
        </>
    }
}