import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {InfoCard} from "../../promo/report/InfoCard";
import ApiInfoSet from "../../../components/apiInfoSet";

export default class Connections extends Component {
    render() {
        return <>
            <hr/>
            <ApiInfoSet
                title="API Connections Quick Overview"
                history={this.props.history}
                url={this.props.url}
            />

            <Row>
                <Col>
                    Quick charts will go here.
                    <br/> Hook usage (except tracking hooks)
                </Col>
            </Row>

        </>
    }
}