import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import Loading from "../../../components/loading";
import RecipientRow from "./recipientRow";

export default class Recipient extends Component {
    state = {
        isLoading: true,
        hasError: false,
        data: [],
    }

    constructor(props) {
        super(props);

        this.api = new ApiClient('email', authenticationErrorHandler.bind(this))
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.api.list().then(response => {
                this.setState({isLoading: false, data: response.data})
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    renderData() {
        if (this.state.data.length === 0) {

            return <p className="text-center">There are no recipients with issues as of the moment</p>
        }

        return <Row>
            {
                this.state.data.map(recipient => {
                    return <Col xl={6} lg={6} md={12} sm={12} className={"mb-1 p-2"}>
                        <RecipientRow data={recipient} key={recipient.id} onReset={newData => {
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    data: [
                                        ...prevState.data.map(r => {
                                            if (r.id === newData.id) {
                                                return newData
                                            }
                                            return r
                                        }),

                                    ]
                                }
                            })
                        }}/>
                    </Col>
                })
            }

        </Row>
    }

    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }

        if (this.state.hasError) {
            return <Row>
                <Col className="text-center">
                    Something went wrong while loading data. Please try again. If it persists, contact administrator.
                </Col>
            </Row>
        }


        return <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h1>
                                Recipients
                            </h1>
                        </CardTitle>

                        <p>
                            This page contains recipients that had issues or had complained.
                        </p>
                        <p>
                            Resetting status will allow sending of new emails.
                            Undelivered emails will not be resent to the recipient.
                        </p>
                        {this.renderData()}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    }

}