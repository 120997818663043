// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
import React, {Component} from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import Select from "react-select";
import {DimensionsSelection} from "./dimensionsSelection";
import {DynamicFilterSet} from "./dynamicFilterSet";
import Loading from "../../../components/loading";
import AreaChart from "../../../components/ApexCharts/AreaChart";
import DonutChart from "../../../components/ApexCharts/DonutChart";
import LineChart from "../../../components/ApexCharts/LineChart";
import BarChart from "../../../components/ApexCharts/BarChart";
import {titleCase} from "../../../util/RenderUtils";
import DataTable from "react-data-table-component";
import {downloadCSV} from "../../../util/Utils";

const chartChoices = [
    {value: "bar", label: "Bar Chart"},
    {value: "line", label: "Line Chart"},
    {value: "area", label: "Area Chart"},
    {value: "pie", label: "Pie Chart"},
    {value: "table", label: "Data Table"},
    // {value: "info", label: "Info Set"},
    {value: "text", label: "Text"},
]

export class CustomizableChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            searchText: '',
            hasError: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        if (!this.props.edit && this.props.config.chart_type !== 'text') {
            const params = {
                dimensions: [...this.props.config.dimensions],
                filters: {
                    ...this.props.filters,
                    ...this.props.config.filters
                },
                count: this.props.config.count ? this.props.config.count : 'id'
            }

            const chartType = this.props.config.chart_type;
            if (chartType === 'line' || chartType === 'area') {
                params['by_date'] = 1
            } else if (chartType === 'table' || chartType === 'bar') {
                params['raw'] = 1
                if (chartType === 'bar') {
                    params.dimensions.push('total')
                }
            }
            this.setState({hasError: false}, () => {
                this.props.dataApi.getItem('report', {
                    params: params
                }).then(response => {
                    this.setState({data: response.data})
                }).catch(error => {
                    this.setState({hasError: true})
                })
            })
        }
    }

    renderEdit() {

        const selectedChart = chartChoices.filter(o => o.value === this.props.config.chart_type)[0]
        const currentHeight = this.props.config.height ? this.props.config.height : 1

        return <>
            <CardTitle>
                <Row>
                    <Col xl={6} lg={12}>
                        <FormGroup>
                            <Label>Title</Label>
                            <Input type={"text"} value={this.props.config.title} onChange={e => {
                                this.props.onConfigChange({title: e.target.value})
                            }}/>
                        </FormGroup>
                    </Col>

                    <Col xl={this.props.edit ? 6 : 12} lg={12} className="text-right">
                        <ButtonGroup>
                            <Button size="xs" disabled={this.props.config.row_position <= 0} onClick={() => {
                                this.props.onConfigChange({row_position: this.props.config.row_position - 2})
                            }
                            }>
                                <span className="fa fa-arrow-left"/>
                            </Button>
                            <Button size="xs" onClick={() => {
                                this.props.onConfigChange({row_position: this.props.config.row_position + 2})
                            }
                            }>
                                <span className="fa fa-arrow-right"/>
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup className="ml-1">

                            <Button size="xs" disabled={this.props.config.width >= 12} onClick={() => {
                                this.props.onConfigChange({width: this.props.config.width + 1})
                            }
                            }>
                                |<span className="fa fa-arrow-left"/><span className="fa fa-arrow-right"/>|
                            </Button>

                            <Button size="xs" disabled={this.props.config.width <= 3} onClick={() => {
                                this.props.onConfigChange({width: this.props.config.width - 1})
                            }
                            }>
                                <span className="fa fa-arrow-right"/>
                                <span className="fa fa-grip-lines-vertical"/>
                                <span className="fa fa-arrow-left"/>
                            </Button>
                        </ButtonGroup>


                        <ButtonGroup className="ml-1">

                            <Button size="xs" disabled={currentHeight >= 4} onClick={() => {
                                this.props.onConfigChange({height: currentHeight + 1})
                            }}>
                                <span className="fa fa-arrow-up"/>
                                <br/>
                                <span className="fa fa-arrow-down"/>
                            </Button>

                            <Button size="xs" disabled={currentHeight <= 1} onClick={() => {
                                this.props.onConfigChange({height: currentHeight - 1})
                            }}>
                                <span className="fa fa-arrow-down"/>

                                <br/>
                                <span className="fa fa-grip-lines"/>
                                <br/>

                                <span className="fa fa-arrow-up"/>
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup className="ml-1">

                            <Button size="xs" color="danger" onClick={() => {
                                this.props.onDelete()
                            }}>
                                <span className="fa fa-trash"/> DELETE
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardTitle>

            <Row>
                <Col>
                    <strong>Chart Display Options</strong>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Chart Type</Label>

                        <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            value={selectedChart}
                            placeholder={"Show all"}
                            onChange={o => this.props.onConfigChange({chart_type: o ? o.value : null})}
                            options={chartChoices}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {
                this.props.config.chart_type === 'text' ? <>
                    <Input type={"textarea"} value={this.props.config.value} onChange={e => {
                        this.props.onConfigChange({value: e.target.value})
                    }}/>
                </> : <>

                    <DimensionsSelection
                        hideEmailFilters={this.props.hideEmailFilters}
                        count={this.props.config.count}
                        indexes={this.props.indexes}
                        tagNames={this.props.tagNames}
                        chartType={this.props.config.chart_type}
                        onConfigChange={this.props.onConfigChange}
                        selectedDimensions={this.props.config.dimensions}
                    />
                    <DynamicFilterSet
                        hideEmailFilters={this.props.hideEmailFilters}
                        mailTemplates={this.props.mailTemplates}
                        indexes={this.props.indexes}
                        tagNames={this.props.tagNames}
                        filters={this.props.config.filters}
                        onConfigChange={this.props.onConfigChange}
                        showWarning={true}
                    />

                </>
            }
            <Row>
                <Col>
                    <span className="text-muted small">
                        Row: {this.props.config.row} Index: {this.props.config.row_position}
                    </span>
                </Col>
            </Row>
        </>
    }

    renderContent() {

        const currentHeight = this.props.config.height ? this.props.config.height : 1
        const chartType = this.props.config.chart_type
        return <>
            {
                ["pie", "line", "bar", "area"].indexOf(chartType) === -1 && <CardTitle className="mb-0">
                    <h4>{this.props.config.title}</h4>
                </CardTitle>
            }

            <Row className={"mt-2"} style={chartType === 'text' ? {} : {minHeight: (currentHeight * 200).toString() + "px"}}>
                <Col>
                    {this.renderChart()}
                </Col>
            </Row>
        </>
    }

    renderChart() {
        const chartType = this.props.config.chart_type;
        if (chartType != 'text') {
            if (!this.state.data) {
                return <div className="text-center">
                    <Loading/>
                </div>
            } else if (Object.values(this.state.data).length === 0) {
                return <div className="text-center">
                    No data
                </div>
            } else if (this.state.hasError) {
                return <div className="text-center">
                    Something went wrong while loading chart <strong>{this.props.config.title}</strong>.
                    Please try again. If it persists, please contact administrator.
                </div>
            }
        }
        if (chartType === 'area') {
            return <AreaChart id={this.props.id} series={this.state.data} title={this.props.config.title}/>
        } else if (chartType === 'pie') {
            return <DonutChart id={this.props.id} data={this.state.data} title={this.props.config.title} legendPosition={this.props.config.width === 12 ? 'right': 'bottom'}/>
        } else if (chartType === 'line') {
            return <LineChart id={this.props.id} series={this.state.data} title={this.props.config.title}/>
        } else if (chartType === 'bar') {
            const keys = this.state.data.length > 0 ? this.props.config.dimensions.filter(k => k !== 'total') : [];
            let seriesData = {}
            if (keys.length === 1) {
                const primaryKey = keys[0]

                const seriesRow = {
                    name: 'total',
                    data: this.state.data.map(row => {
                        return {x: row[primaryKey] === null ? "" : row[primaryKey], y: row['total']}
                    })
                }
                seriesData[primaryKey] = seriesRow
            } else {
                const primaryKey = keys[0]
                const secondaryKey = keys[1]
                const primaryValues = [...new Set(this.state.data.map(row => row[primaryKey]))]
                const secondaryValues = [...new Set(this.state.data.map(row => row[secondaryKey]))]

                const dictOfDict = primaryValues.reduce((acc, primaryValue) => {
                    acc[primaryValue] = secondaryValues.reduce((sAcc, secondaryValue) => {
                        sAcc[secondaryValue] = 0
                        return sAcc
                    }, {})
                    return acc
                }, {})
                this.state.data.forEach(row => {
                    dictOfDict[row[primaryKey]][row[secondaryKey]] = row['total']
                })

                seriesData = primaryValues.reduce((acc, primaryValue) => {
                    acc[primaryValue] = {
                        name: primaryValue === null ? "" : primaryValue,
                        data: secondaryValues.map(secondaryValue => {
                            return {x: secondaryValue === null ? "" : secondaryValue, y: dictOfDict[primaryValue][secondaryValue]}
                        })
                    }
                    return acc
                }, {})
            }

            return <BarChart
                id={this.props.id}
                series={Object.values(seriesData)}
                title={this.props.config.title}
            />
        } else if (chartType === 'table') {
            const Export = ({onExport}) => <Button onClick={e => onExport(e.target.value)}>Export CSV</Button>;

            const filteredData = this.state.searchText ?
                this.state.data.filter(row => JSON.stringify(row).indexOf(this.state.searchText) > -1) :
                this.state.data

            let columns = []
            let displayData = []

            const tagNameMap = this.props.tagNames.reduce((acc, row) => {
                acc[row.id] = row.name
                return acc
            }, {})

            if (this.props.config.dimensions.length === 3 && this.props.config.dimensions.indexOf('total') === 2) {
                // if the data has only 2 dimensions, with the total as the third dimension
                // we can automatically pivot it to have a more meaningful table

                let rowHeaderKey = this.props.config.dimensions[0]
                const columnHeaderKey = this.props.config.dimensions[1]
                let columnHeaders = [];
                if (columnHeaderKey === 'month') {
                    columnHeaders = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                } else {
                    columnHeaders = new Set(filteredData.map(row => row[columnHeaderKey]))
                }
                const newData = filteredData.reduce((acc, row) => {
                    const rowKey = row[rowHeaderKey]
                    const colKey = row[columnHeaderKey]
                    if (!acc.hasOwnProperty(rowKey)) {
                        acc[rowKey] = {
                            row: rowKey
                        }
                    }
                    acc[rowKey][colKey] = row['total']
                    return acc
                }, {})

                if (rowHeaderKey.indexOf('t__') === 0) {
                    rowHeaderKey = tagNameMap[rowHeaderKey.replace('t__', '')]
                }

                columns = [
                    {
                        name: rowHeaderKey && titleCase(rowHeaderKey.replace('i__', '')),
                        selector: row => row['row'],
                        sortable: true
                    },
                    ...[...columnHeaders].map(header => ({
                        name: header ? titleCase(header.toString()) : <i className="text-muted">BLANK</i>,
                        selector: row => row.hasOwnProperty(header) ? row[header] :
                            <span className="text-muted">0</span>,
                        sortable: true
                    }))
                ]
                displayData = Object.values(newData)
            } else {
                // Default handling of data
                displayData = filteredData
                columns = this.props.config.dimensions.map(key => {
                    const displayKey = key.indexOf('t__') === 0 ? tagNameMap[key.replace('t__', '')] : key;
                    return {
                        name: titleCase(displayKey.replace('i__', '')),
                        selector: row => row[key] === 0 ? <span className="text-muted">0</span> : row[key],
                        sortable: true
                    }
                })
            }

            return <DataTable
                columns={columns}
                data={displayData}
                actions={<Export onExport={() => downloadCSV(displayData)}/>}
                subHeader
                subHeaderComponent={<Row>
                    <Col className="align-content-end">
                        <InputGroup>

                            <Input
                                type="text"
                                value={this.state.searchText}
                                onChange={
                                    e => {
                                        this.setState({searchText: e.target.value})
                                    }
                                }
                            />
                            <InputGroupAddon addonType={"prepend"}>
                                <InputGroupText>
                                    <span className="fa fa-search"/>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>}
            />
        } else if (chartType === 'text') {
            return <p>
                {this.props.config.value}
            </p>
        }
    }


    render() {
        return <Card>
            <CardBody>

                {this.props.edit ? this.renderEdit() : this.renderContent()}
            </CardBody>
        </Card>
    }
}