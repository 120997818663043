import React, {Component} from "react";
import {Badge, Button, Card, CardBody, CardTitle, Col, Label, Row} from "reactstrap";
import ClassicText from "../../components/RichEditor/classicEditor";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {Colxx} from "../../components/CustomBootstrap";
import Loading from "../../components/loading";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {toast} from "react-toastify";


class PromoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {...props.data},
            originalData: {...props.data},
            isLoading: false,
            hasError: false,
            isExpanded: false,
        }

        this.promoAPI = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    save() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoAPI.patchItemAction(
                this.props.promoId,
                'pages',
                {[this.props.dataKey]: this.state.data}
            ).then(response => {
                const newData = response.data[this.props.dataKey]
                this.setState({data: {...newData}, originalData: {...newData}, isLoading: false, hasError: false})
                toast.success("Updated " + this.props.title + ".")
            }).catch(() => {
                this.setState({hasError: true, isLoading: false})
                toast.error("Cannot save " + this.props.title + ". Please try again. If problem persists, contact administrator.")
            })
        })
    }

    hasUnsavedChanges() {
        return JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData)
    }

    renderContent() {
        if (!this.state.isExpanded) {
            return null
        }
        const linkPositions = [
            {"label": "Link at top of page", "value": "top"},
            {"label": "Link at bottom of page", "value": "bottom"},
            {"label": "Do not show page links", "value": "none"}
        ]
        const selectedLinkPosition = this.state.data.link_position ? linkPositions.filter(options => options.value === this.state.data.link_position)[0] : linkPositions[0]
        return <Row>
            <Col lg={6} md={12}>
                <ClassicText value={this.state.data.body} onEditorChange={newContent => {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                body: newContent
                            }
                        }
                    })
                }}/>
            </Col>
            <Col lg={6} md={12}>
                <Row>
                    <Col>
                        Page CSS
                        <textarea
                            style={{height: '320px'}}
                            className="form-control"
                            value={this.state.data.css}
                            onChange={e => {
                                this.setState(prevState => {
                                    return {
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            css: e.target.value
                                        }
                                    }
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>

                        <Label>Link Position</Label>
                        <Select
                            isClearable={false}
                            components={{Input: CustomSelect}}
                            className="react-select small"
                            classNamePrefix="react-select"
                            value={selectedLinkPosition}
                            onChange={newSelection => {
                                this.setState(prevState => {
                                    return {
                                        ...prevState,
                                        data: {
                                            ...prevState.data,
                                            link_position: newSelection.value
                                        }
                                    }
                                })
                            }}
                            options={linkPositions}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col className="text-right">
                        {
                            this.state.data.is_working &&
                            <a href={this.state.data.url} className="btn-link pr-4" target="_blank">
                                Preview <span className="fa fa-external-link-alt"></span>
                            </a>
                        }
                        <Button color="success" disabled={!this.hasUnsavedChanges()} onClick={() => {
                            this.save()
                        }}>
                            <span className="fa fa-save"></span> Save Changes
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    render() {
        //
        // pageContent={this.state.data.registration_page.body}
        // pageContentChanged={ }
        // cssContent={this.state.data.registration_page.css}
        // linkPosition={this.state.data.registration_page.link_position}

        let badge = null;
        if (this.state.data.is_existing) {
            badge = <Badge color="success" style={{width: "12em"}}><span className="fa fa-check"/> Configured</Badge>;
        } else if (this.state.data.is_optional) {
            badge = <Badge color="warning" style={{width: "12em"}}>Optional - No setup</Badge>;
        } else if (!this.state.data.is_working) {
            badge = <Badge color="danger" style={{width: "12em"}}><span className="fa fa-exclamation"/> Needs Setup</Badge>;
        } else {
            badge = <Badge color="warning" style={{width: "12em"}}>Working - No setup</Badge>;
        }
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle className={this.state.isExpanded ? 'mb-1': 'mb-0'}>
                            <Row>
                                <Col lg={5} md={12} className="text-lg-left text-center">
                                    <h4  onClick={() => {
                                        this.setState(prevState => {
                                            return {
                                                ...prevState,
                                                isExpanded: !prevState.isExpanded
                                            }
                                        })
                                    }}>{this.props.title}</h4>
                                </Col>
                                <Col className="text-center" lg={2} md={6}>
                                    <h4  onClick={() => {
                                        this.setState(prevState => {
                                            return {
                                                ...prevState,
                                                isExpanded: !prevState.isExpanded
                                            }
                                        })
                                    }}>
                                        {badge}
                                    </h4>
                                </Col>
                                <Col className="text-right" lg={5} md={6}>
                                    <h6>
                                        <Button className="py-1" color={null} onClick={() => {
                                            this.setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    isExpanded: !prevState.isExpanded
                                                }
                                            })
                                        }}>
                                            {this.state.isExpanded ? 'Hide': 'Show'} Page Setup {" "}
                                            <span className={"ml-4 fa fa-chevron-" + (this.state.isExpanded ? 'down': 'right')}/>
                                        </Button>
                                    </h6>
                                </Col>
                            </Row>
                        </CardTitle>
                        {this.renderContent()}
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}

export default class PromoPages extends Component {
    state = {
        data: {},
        isLoading: true,
        hasError: false
    }

    constructor(props) {
        super(props);
        this.promoAPI = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    loadPageData() {
        this.setState({isLoading: true, hasError: false}, () => {

            this.promoAPI.getItemAction(this.props.promoId, 'pages').then(response => {
                this.setState({data: response.data, isLoading: false, hasError: false})
            }).catch(e => {
                this.setState({hasError: true, isLoading: false})
            })
        })
    }

    componentDidMount() {
        this.loadPageData()
    }

    render() {
        if (this.state.isLoading) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        } else if (this.state.hasError) {
            return <Row>
                <Colxx className="text-center">
                    Something went wrong. Please contact administrator.
                </Colxx>
            </Row>
        }

        return <>
            <PromoPage
                title={"Entry Registration Page"}
                data={this.state.data.registration_page}
                key={"registration-editor"}
                promoId={this.props.promoId}
                dataKey="registration_page"
            />

            <PromoPage
                title={"Success Registration Page"}
                data={this.state.data.success_page}
                key={"success-editor"}
                promoId={this.props.promoId}
                dataKey="success_page"
            />

            <PromoPage
                title={"Dispute Page"}
                data={this.state.data.dispute_page}
                key={"dispute-editor"}
                promoId={this.props.promoId}
                dataKey="dispute_page"
            />

            <PromoPage
                title={"Winners Page"}
                data={this.state.data.winners_page}
                key={"winners-editor"}
                promoId={this.props.promoId}
                dataKey="winners_page"
            />
            <PromoPage
                title={"Mechanics Page"}
                data={this.state.data.mechanics_page}
                key={"mechanics-editor"}
                promoId={this.props.promoId}
                dataKey="mechanics_page"
            />
            <PromoPage
                title={"Terms Page"}
                data={this.state.data.terms_page}
                key={"terms-editor"}
                promoId={this.props.promoId}
                dataKey="terms_page"
            />
            <div className="mb-4 clearfix">&nbsp;</div>
        </>
    }
}