import React from "react";
import { Provider } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import App from "./containers/App";
import { configureStore } from "./redux/store";
import './assets/css/sass/themes/reactify.light.purple.scss'
import './assets/fonts/simple-line-icons/css/simple-line-icons.css';
import './assets/fonts/iconsmind/style.css';
import 'react-datepicker/dist/react-datepicker.min.css'

const MainApp = () => (
  <Provider store={configureStore()}>
    <Router>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider>
);

export default MainApp;
