import React, {Component} from "react";
import {Colxx} from "../../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody, CardImg,
    CardTitle, CustomInput, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import PromoList from "../promoList";
import "../../../assets/css/bootstrap.extra.css";
import Select from "react-select";
import Switch from "rc-switch";
import PreviousWinners from "./previousWinners";
import DrawWinners from "./drawWinners";
import {toast} from "react-toastify";


class PromoDrawContainer extends Component {
    state = {
        promoTitle: ""
    }

    constructor(props) {
        super(props);

        this.previousWinners = React.createRef()
        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    componentDidMount() {
        const {match} = this.props;
        const promoID = match.params.promoID

        this.promoApi.getItem(promoID).then(response => {
            this.setState({promoTitle: response.data.title})
        })
    }

    handleNewDraw(drawInfo) {
        toast.success("Created new draw with draw key \"" + drawInfo.draw_key + "\"")
        this.previousWinners.current.addNewDraw(drawInfo)
    }

    render() {

        const {match} = this.props;
        const promoID = match.params.promoID

        return <>
            <DrawWinners
                history={this.props.history}
                promoID={promoID}
                promoTitle={this.state.promoTitle}
                generateDrawComplete={this.handleNewDraw.bind(this)}
            />
            <PreviousWinners
                history={this.props.history}
                promoID={promoID}
                promoTitle={this.state.promoTitle}
                ref={this.previousWinners}
            />
        </>
    }
}

export default PromoDrawContainer