// import { addLocaleData } from "react-intl";
import enLang from "./entries/en-US";
import esLang from "./entries/es-FR";

const AppLocale = {
  en: enLang,
  fr: esLang
};
// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.fr.data);

export default AppLocale;
