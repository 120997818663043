import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import UserList from "./userList";
import UserForm from "./userForm";


export default class Ui extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { match } = this.props;

        return (
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
                <Route path={`${match.url}/list`} component={UserList} />
                <Route key="editUser" path={`${match.url}/:userID/edit`} component={UserForm} />
                <Route key="newUser" path={`${match.url}/new`} component={UserForm} />


                <Redirect to="/error" />
            </Switch>
        );
    }
}