import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Col, Row} from "reactstrap";
import {toast} from "react-toastify";
import Loading from "../../../components/loading";
import DashboardSelection, {deriveSelectedDashboard} from "../../../components/Dashboard/dashboardSelection";
import {Dashboard} from "../../mail/report/dashboard";


const DUMMY_DASHBOARD_DATA = [{
    "id": 1,
    "row": 1,
    "title": "Brand Impressions",
    "width": 12,
    "height": 1,
    "filters": {},
    "chart_type": "area",
    "dimensions": ["t__1"],
    "row_position": 0
}, {
    "id": 2,
    "row": 2,
    "title": "Impressions by Brand",
    "width": 4,
    "height": 1,
    "filters": {},
    "chart_type": "pie",
    "dimensions": ["t__1"],
    "row_position": 0
}, {
    "id": 5,
    "row": 0,
    "title": "Default Dashboard",
    "value": "This is the default dashboard. You can make your own (click + New Dashboard) or copy this dashboard as a basis (click Edit)",
    "width": 12,
    "height": 1,
    "filters": {},
    "chart_type": "text",
    "dimensions": [],
    "row_position": 0
}, {
    "id": 3,
    "row": 2,
    "count": "session",
    "title": "Sessions by Brand",
    "width": 4,
    "height": 1,
    "filters": {},
    "chart_type": "pie",
    "dimensions": ["t__1"],
    "row_position": 1
}, {
    "id": 4,
    "row": 2,
    "count": "recipient",
    "title": "Users by Brand",
    "width": 4,
    "height": 1,
    "filters": {},
    "chart_type": "pie",
    "dimensions": ["t__1"],
    "row_position": 2
}]


const DEFAULT_DASHBOARD = {
    id: -1,
    title: "Default Report",
    chart_config: DUMMY_DASHBOARD_DATA,
    filters: {"date_relative": "last30days"},
    can_edit: false,
    is_active: true,
    dashboard_type: "dialogue"
}

const EMPTY_DASHBOARD = {
    title: "New Report",
    chat_config: [],
    filters: {},
    is_active: true,
    dashboard_type: "dialogue"
}

export default class Report extends Component {
    constructor(props) {
        super(props);
        this.api = new ApiClient('dashboard', authenticationErrorHandler.bind(this))
        this.state = {
            dashboards: [],
            selectedDashboard: null,
            isLoading: true,
            hasError: false
        }
    }

    componentDidMount() {
        this.loadDashboards()
    }

    loadDashboards() {
        this.setState({
            isLoading: true,
            hasError: false
        }, () => {
            this.api.list({params: {type: "dialogue"}}).then(response => {
                this.setState({
                    isLoading: false,
                    dashboards: response.data
                })
            }).catch(() => {
                this.setState({
                    isLoading: false,
                    hasError: true
                })
            })
        })
    }

    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center mt-4">
                    <Loading/>
                </Col>
            </Row>
        }
        if (this.state.hasError) {
            return <Row>
                <Col className="text-center mt-4">
                    Something went wrong while loading the dashboards. Please refresh. If problem persists, contact
                    administrator.
                </Col>
            </Row>
        }

        const displayDashboard = deriveSelectedDashboard(
            this.state.selectedDashboard,
            this.state.dashboards,
            DEFAULT_DASHBOARD
        )

        return <>
            <DashboardSelection
                api={this.api}
                dashboards={this.state.dashboards}
                activeDashboardId={this.state.selectedDashboard}
                defaultDashboard={DEFAULT_DASHBOARD}
                newDashboardDefaults={EMPTY_DASHBOARD}
                onChangeDashboard={(dashboardId) => {
                    this.setState({selectedDashboard: dashboardId})
                }}
                onCreateDashboard={
                    data => {
                        this.setState(prevState => {
                            return {
                                selectedDashboard: data.id,
                                dashboards: [
                                    ...prevState.dashboards,
                                    data
                                ]
                            }
                        })
                    }
                }
            />
            <hr/>
            <Dashboard
                key={displayDashboard.id}
                hideEmailFilters={true}
                reportApiObject={'recipient_tracking'}
                history={this.props.history}
                config={displayDashboard}
                can_edit={displayDashboard.can_edit}
                onSetDefault={dashboardId => {
                    this.api.postItemAction(dashboardId, 'make_default').then(() => {

                        this.setState(prevState => {
                            return {
                                ...prevState,
                                dashboards: [
                                    ...prevState.dashboards.map(dashboard => {
                                        if (dashboard.id === dashboardId) {
                                            return {
                                                ...dashboard,
                                                is_default: true
                                            }
                                        } else {
                                            return {
                                                ...dashboard,
                                                is_default: false
                                            }
                                        }
                                    })
                                ]
                            }
                        }, () => {
                            toast.success("You have updated your default dashboard.")
                        })
                    })
                }}
                onCopy={data => {
                    const newData = JSON.parse(JSON.stringify(data))
                    if (data['id'] !== -1 && displayDashboard.title === newData.title) {
                        newData.title = newData.title + " (copy)"
                    }
                    delete newData['id']
                    this.api.create(newData).then(response => {
                        this.setState(prevState => {
                            return {
                                selectedDashboard: response.data.id,
                                dashboards: [
                                    ...prevState.dashboards,
                                    response.data
                                ]
                            }
                        }, () => {
                            toast.success("Saved a copy of dashboard " + response.data.title)
                        })
                    })
                }}
                onSave={(data, cb) => {
                    this.api.updateItem(displayDashboard.id, data).then(response => {
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                dashboards: prevState.dashboards.map(dashboard => {
                                    if (dashboard.id === response.data.id) {
                                        return response.data
                                    } else if (response.data.is_default) {
                                        return {
                                            ...dashboard,
                                            is_default: false
                                        }
                                    } else {
                                        return dashboard
                                    }
                                })
                            }
                        }, () => {
                            if (cb) {
                                cb(response.data)
                            }
                            if (response.data.is_active) {
                                toast.success("Updated dashboard " + response.data.title)
                            } else {
                                toast.error("Deleted dashboard " + response.data.title)
                            }

                        })
                    })
                }}
            />
        </>
    }
}
