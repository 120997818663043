import React, {Component} from "react";
import {Button, Col, Label, Row} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import {toast} from "react-toastify";

export default class DashboardSelection extends Component {
    render() {
        const dashboardOptions = [
            {value: -1, label: "Default Dashboard", group: "System"},
            ...this.props.dashboards
                .filter(dashboard => dashboard.is_active)
                .map(dashboard => {
                    let groupName = '';
                    if (dashboard.can_edit) {
                        if (dashboard.is_shared) {
                            groupName = "My Shared Dashboards"
                        } else {
                            groupName = "My Personal Dashboards"
                        }
                    } else {
                        groupName = "Shared Dashboards from other users"
                    }
                    return {
                        value: dashboard.id,
                        label: <>
                            {dashboard.is_default && dashboard.can_edit && <span className="fa fa-star" style={{color: "yellow"}}/>}{" "}
                            {dashboard.title + (dashboard.can_edit ? "" : " (shared)")}
                        </>,
                        group: groupName
                    }
                })
        ]
        const groupedDashboardOptions = [
            ...Object.values(
                dashboardOptions.reduce((acc, option) => {
                    if (!acc.hasOwnProperty(option.group)) {
                        acc[option.group] = {
                            label: option.group,
                            options: []
                        }
                    }
                    acc[option.group].options.push(option)
                    return acc
                }, {})
            )
        ]

        const displayDashboard = deriveSelectedDashboard(this.props.activeDashboardId, this.props.dashboards, this.props.defaultDashboard)
        const selectedDashboardOption = displayDashboard.id
            && dashboardOptions.filter(o => o.value === displayDashboard.id)


        return <Row className="mb-4 align-items-end">
            <Col>
                <Label>Select Saved Dashboard</Label>
                <Select
                    className="react-select small"
                    classNamePrefix="react-select"
                    options={groupedDashboardOptions}

                    value={selectedDashboardOption}
                    onChange={o => {
                        this.props.onChangeDashboard(o.value)
                    }}
                />
            </Col>
            <Col className="text-right col-md-auto col-lg-auto col-xl-auto col-sm-12 col-xs-12">
                <Button
                    size="sm"
                    className="mb-1"
                    onClick={() => {
                        this.props.api.create({
                            ...this.props.newDashboardDefaults,
                            title: this.props.newDashboardDefaults.title + " - " + moment().format("ll")
                        }).then(response => {
                            this.props.onCreateDashboard(response.data)
                            toast.success("Created new dashboard " + response.data.title)
                        })
                    }}>
                    <span className="fa fa-plus"/> New Dashboard
                </Button>
            </Col>
        </Row>
    }
}

export const deriveSelectedDashboard = (activeDashboardId, dashboards, systemDefaultDashboard) => {
    const activeDashboards = [
        ...dashboards.filter(
        dashboard => dashboard.is_active
    ), systemDefaultDashboard
        ]
    const selectedDashboard = activeDashboardId !== null && activeDashboards.length > 0 &&  activeDashboards.filter(
        dashboard => dashboard.id === activeDashboardId
    )[0]
    console.log(selectedDashboard)
    const defaultDashboards = dashboards.filter(dashboard => dashboard.is_default && dashboard.can_edit)
    const defaultDashboard = defaultDashboards.length > 0 ? defaultDashboards[0] : systemDefaultDashboard
    const displayDashboard = (selectedDashboard && selectedDashboard.is_active) ? selectedDashboard : defaultDashboard
    return displayDashboard
}
