import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import {InfoCard} from "../../promo/report/InfoCard";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import Loading from "../../../components/loading";
import ApiInfoSet from "../../../components/apiInfoSet";
import {createImageFromInitials, getRandomColor, stringToColour} from "../../../util/RenderUtils";


const maxUsersDisplay = 5;

const renderCountUsers = (count, users) => {
    let displayUsers = []
    const moreCount = users.length - maxUsersDisplay;

    if (users.length > maxUsersDisplay) {
        displayUsers = users.slice(0, maxUsersDisplay)
    } else {
        displayUsers = users.slice()
    }
    return <>
        {
            displayUsers.map(username => {
                return <img alt={username} title={username} key={username}
                            src={createImageFromInitials(30, username, stringToColour(username))}
                            style={{borderRadius: "30px"}} className="mx-1"/>
            })
        }
        { moreCount > 0 && <small>{" + " + moreCount.toString() + " users"}</small>}
    </>
}

export default class Users extends Component {
    render() {
        return <>
            <hr/>
            <ApiInfoSet
                title="Users Quick Overview"
                history={this.props.history}
                valueRenderer={(data) => {
                    if (typeof (data) === "object") {
                        if (data.hasOwnProperty('value')) {
                            if (data.hasOwnProperty('users') && data.users.length > 0) {
                                return renderCountUsers(data.value, data.users)
                            } else {
                                return data.value
                            }
                        }
                    } else {
                        return data
                    }
                }}
                url={this.props.url}
            />
        </>
    }
}