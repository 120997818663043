import React, {Component} from "react";
import {Colxx} from "./CustomBootstrap";
import Loading from "./loading";
import {Row} from "reactstrap";

export default class ImgLoading extends Component {
    state = {
        isLoaded: false,
        hasError: false
    }

    render() {
        return <>
            {
                !this.state.isLoaded && this.props.src && <Row className="p-4 m-4">
                    <Colxx className="text-center">
                        <Loading/>
                    </Colxx>
                </Row>
            }

            {
                (this.state.hasError || !this.props.src) && <Row className="p-4 m-4">
                    <Colxx className="text-center">
                        Error loading image. <br/>
                        <small>URL: {this.props.src ? this.props.src : 'null'}</small>
                    </Colxx>
                </Row>
            }
            <div style={{display: this.state.isLoaded ? "block" : "none"}} className="text-center">
                <img src={this.props.src} className="img-fluid" onLoad={() => {
                    this.setState({isLoaded: true})
                }} onError={() => {
                    this.setState({hasError: false, isLoaded: true})
                }}/>
            </div>
        </>
    }
}