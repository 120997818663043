import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row
} from "reactstrap";
import {Colxx} from "../../components/CustomBootstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import Loading from "../../components/loading";
import Switch from "rc-switch";
import {calculateMaxPermutations, getHasher} from "../../util/Utils";
import Hashids from "hashids";
import PromoEditNavigation from "./promoEditNavigation";
import {CodeOverallInfoSet} from "./codeReport";


const distinctFilter = (value, index, self) => {
    return self.indexOf(value) === index;
}


export default class PromoCodeDefinition extends Component {
    state = {
        data: {},
        originalData: {},
        isLoading: true,
        hasError: false,
        newPrefix: '',
        title: '',
        promoData: {}
    }

    constructor(props) {
        super(props);
        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))

        const {match} = this.props;
        this.promoId = match.params.promoID
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.promoApi.getItem(this.promoId).then(response => {
            this.setState({title: response.data.title, promoData: response.data})
        })
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.getItemAction(this.promoId, 'code_definition').then(response => {
                this.setState({
                    isLoading: false,
                    hasError: false,
                    data: {...response.data},
                    originalData: {...response.data},
                })
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    save() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.patchItemAction(this.promoId, 'code_definition', this.state.data).then(response => {
                this.setState({
                    isLoading: false,
                    hasError: false,
                    data: {...response.data},
                    originalData: {...response.data},
                })
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    canAddPrefix() {
        if (!this.state.newPrefix) {
            return false
        }
        if (this.state.data.prefixes.map(i => i.toLowerCase()).indexOf(this.state.newPrefix.toLowerCase()) >= 0) {
            return false
        }
        return true
    }

    isHashIdSettingsValid() {
        return this.state.data.code_allowed_characters.length >= 16 && this.state.data.code_length >=5 && this.state.data.code_length <= 20;
    }

    isSettingsValid() {
        if (!this.isHashIdSettingsValid()) {
            return false
        }

        const maxPermutations = calculateMaxPermutations(this.state.data.code_length, this.state.data.code_allowed_characters)
        const maxTargetCodeCount = maxPermutations * (this.state.data.prefixes.length > 0 ? this.state.data.prefixes.length: 1)

        return this.state.data.target_count <= maxTargetCodeCount;
    }

    hasDataChanged() {
        return JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData)
    }

    render() {


        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        const maxPermutations = this.isHashIdSettingsValid() ? calculateMaxPermutations(this.state.data.code_length, this.state.data.code_allowed_characters) : 0
        const maxTargetCodeCount = maxPermutations * (this.state.data.prefixes.length > 0 ? this.state.data.prefixes.length: 1)
        return <>
            <Row className="mb-4">
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-1">
                                <h2 className="mb-0">
                                    {this.state.title && <><strong>{this.state.title}</strong> - </>}
                                    Code Settings
                                </h2>

                            </CardTitle>

                            {   <PromoEditNavigation
                                data={this.state.promoData}
                                currentPage="edit-code"
                                history={this.props.history}
                                hasUnsavedChanges={this.hasDataChanged()}
                            /> }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>

                            <Row className="">
                                <Col>
                                    <h4>
                                        Code Generation Settings
                                    </h4>
                                    <small className="text-muted">
                                        These settings will be used to generate valid codes.<br/>
                                        The valid codes generated can be used for entries from <strong>ChatBot</strong> or <strong>Promo Pages</strong><
                                        /small>
                                </Col>
                            </Row>
                            <Row form>
                                <Colxx md={4} sm={12}>
                                    <FormGroup>
                                        <Label for="code_allowed_characters">Allowed Characters</Label>
                                        <Input
                                            type="text"
                                            name="code_allowed_characters"
                                            id="code_allowed_characters"
                                            value={this.state.data.code_allowed_characters}
                                            onChange={e => {
                                                this.setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        data: {
                                                            ...prevState.data,
                                                            code_allowed_characters: e.target.value.toUpperCase()
                                                        }
                                                    }
                                                })
                                            }}
                                            onBlur={ () => {
                                                this.setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        data: {
                                                            ...prevState.data,
                                                            code_allowed_characters: prevState.data.code_allowed_characters.toUpperCase().split("").filter(distinctFilter).sort().join('')
                                                        }

                                                    }
                                                })
                                            }}
                                        />
                                        <small>Allowed characters in the codes generated. Minimum of 16 characters.</small>
                                    </FormGroup>
                                </Colxx>

                                <Colxx md={4} sm={12}>
                                    <FormGroup>
                                        <Label for="code_length">Code Length</Label>
                                        <Input
                                            type="number"
                                            min={5}
                                            max={20}
                                            name="code_length"
                                            id="code_length"
                                            value={this.state.data.code_length}
                                            onChange={e => {
                                                this.setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        data: {
                                                            ...prevState.data,
                                                            code_length: parseInt(e.target.value)
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                        <small>Number of characters in a code, excluding prefixes if any. 5 to 20.</small>
                                    </FormGroup>
                                </Colxx>
                                <Colxx md={4} sm={12}>
                                    <FormGroup>
                                        <Label for="target_count">Target Code Count</Label>
                                        <Input
                                            type="number"
                                            min={0}
                                            max={maxTargetCodeCount}
                                            name="target_count"
                                            id="target_count"
                                            value={this.state.data.target_count}
                                            onChange={e => {
                                                this.setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        data: {
                                                            ...prevState.data,
                                                            target_count: parseInt(e.target.value)
                                                        }
                                                    }
                                                })
                                            }}
                                            onBlur={() => {
                                                if (this.state.data.target_count > maxTargetCodeCount) {
                                                    this.setState(prevState => {
                                                        return {
                                                            ...prevState,
                                                            data: {
                                                                ...prevState.data,
                                                                target_count: maxTargetCodeCount
                                                            }
                                                        }
                                                    })
                                                }
                                            }}
                                        />
                                        <small>Max target count: <strong>{maxTargetCodeCount.toLocaleString()}</strong></small>
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Row>
                                <Colxx>
                                    <h4>Prefixes</h4>
                                    {
                                        this.state.data.prefixes.length > 0 ? this.state.data.prefixes.map(val => {
                                            return <div className="m-1 d-inline">
                                                <span key={val} className="p-2 badge badge-light"
                                                      style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>
                                                    {val}
                                                </span>
                                                <span
                                                    className="p-2 badge badge-danger"
                                                    style={{
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        this.setState(prevState => {
                                                            return {
                                                                ...prevState,
                                                                data: {
                                                                    ...prevState.data,
                                                                    prefixes: [
                                                                        ...prevState.data.prefixes.filter(i => i !== val)

                                                                    ]
                                                                }
                                                            }
                                                        })

                                                    }}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </span>
                                            </div>
                                        }) : "No prefixes yet. (Optional)"
                                    }
                                    <Form inline={true} className="mt-2">
                                        <InputGroup>
                                            <Input type="text" value={this.state.newPrefix} onChange={e => {
                                                this.setState({newPrefix: e.target.value.toUpperCase()})
                                            }}/>
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    size="xs"
                                                    className="pt-2"
                                                    disabled={!this.canAddPrefix()}
                                                    onClick={() => {
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            data: {
                                                                ...prevState.data,
                                                                prefixes: [
                                                                    ...prevState.data.prefixes,
                                                                    prevState.newPrefix
                                                                ]
                                                            },
                                                            newPrefix: '',
                                                        }))
                                                    }}
                                                >Add Prefix</Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Form>
                                </Colxx>
                            </Row>

                            <Row className="pt-4">
                                <Col>{this.renderWarnings()}</Col>
                            </Row>
                            <Row className="">
                                <Col>{this.renderSettingsValidation()}</Col>
                            </Row>


                            <hr/>


                            <Row>
                                <Col>

                                    <FormGroup>

                                        <Switch
                                            className="mr-2"
                                            checked={this.state.data.allow_add_codes}
                                            onChange={() => {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    data: {
                                                        ...prevState.data,
                                                        allow_add_codes: !prevState.data.allow_add_codes
                                                    }
                                                }))
                                            }}
                                        />
                                        <Label for="allow_add_codes">
                                            <strong>
                                                Allow adding new codes from ChatBot.
                                            </strong>{" "}
                                            Allows any code input without validation.{" "}
                                            This is useful for non-system generated codes like using Receipt numbers as codes.
                                        </Label>

                                    </FormGroup>

                                </Col>
                            </Row>

                            <Row className="pt-4">
                                <Col className={"text-right"}>
                                    <Button disabled={!this.isSettingsValid() || !this.hasDataChanged()} onClick={this.save.bind(this)}>Save Changes</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {this.renderSampleCodes()}

            <br/>
            <CodeOverallInfoSet promoId={this.promoId} showDigestOnly={true} key={this.state.data.id}/>

        </>
    }

    renderSettingsValidation() {
        if (this.state.data.code_allowed_characters.length < 16) {
            // && this.state.data.code_length >=5 && this.state.data.code_length <= 20) {
            return <div className="alert alert-danger">
                Settings error! Allowed characters should be 16 characters or more.
            </div>
        }
        if (!(this.state.data.code_length >=5 && this.state.data.code_length <= 20)) {
            return <div className="alert alert-danger">
                Settings error! Code length must be 5 or more, but not greater than 20.
            </div>
        }

        const maxPermutations = this.isHashIdSettingsValid() ? calculateMaxPermutations(this.state.data.code_length, this.state.data.code_allowed_characters) : 0
        const maxTargetCodeCount = maxPermutations * (this.state.data.prefixes.length > 0 ? this.state.data.prefixes.length: 1)

        if (this.state.data.target_count > maxTargetCodeCount) {
            return <div className="alert alert-danger">
                Settings error! Target count must not be larger than max target count <strong>{maxTargetCodeCount.toLocaleString()}</strong>.
            </div>
        }
        return <>
            Max permutations of {this.state.data.code_allowed_characters} with output length{" "}
            {this.state.data.code_length} = <strong>{maxPermutations.toLocaleString()}</strong>
            <br/>
            Max target code count with {this.state.data.prefixes.length} prefixes ={" "}
            <strong>{maxTargetCodeCount.toLocaleString()}</strong><br/>
            { this.state.data.prefixes.length > 1 ? <>Maximum number of codes for each prefix is <strong>{maxPermutations.toLocaleString()}</strong></> : "" }
        </>
    }

    renderWarnings() {
        const warnings = []
        const invalidatingChanges = []
        if (this.state.data.code_allowed_characters !== this.state.originalData.code_allowed_characters) {
            invalidatingChanges.push('Allowed Characters')
        }
        if (this.state.data.code_length !== this.state.originalData.code_length) {
            invalidatingChanges.push('Code Length')
        }
        if (invalidatingChanges.length > 0) {
            warnings.push(invalidatingChanges.join(" and ") + " has changed. Previously generated/disbursed codes can no longer be used. Entries that has already used the old codes are still valid.")
        }
        if (this.state.data.target_count < this.state.originalData.target_count) {
            warnings.push("Target count has decreased. If the count of already generated codes are greater than the new target count, system will no longer be able to generate new codes.")
        }

        const removedPrefixes = this.state.originalData.prefixes.filter(p => this.state.data.prefixes.indexOf(p) < 0)
        if (removedPrefixes.length > 0) {
            warnings.push("Prefix(es) " + removedPrefixes.join(", ") + " had been removed. Generated/disbursed codes from those prefixes can no longer be used. Entries that has already used those codes would remain valid.")
        }

        return warnings.map(w => <div className="alert alert-danger">{w}</div> )

    }

    renderSampleCodes() {
        if (!this.isHashIdSettingsValid()) {
            return <></>
        }

        const prefixes = this.state.data.prefixes.length > 0 ? this.state.data.prefixes : [""]
        const maxPermutations = calculateMaxPermutations(this.state.data.code_length, this.state.data.code_allowed_characters)
        const maxUsable = maxPermutations > 50000 ? 50000 : maxPermutations
        const topNumber = maxUsable

        const sampleCodeLength = 20;
        let prefixIndex = 0;
        const sampleCodes = []

        while (sampleCodeLength > sampleCodes.length) {
            const prefix = prefixes[prefixIndex]
            const hasher = new Hashids(prefix, this.state.data.code_length, this.state.data.code_allowed_characters)
            sampleCodes.push(prefix + hasher.encode(topNumber - sampleCodes.length))
            prefixIndex += 1
            if (prefixIndex >= prefixes.length) {
                prefixIndex = 0;
            }
        }

        return <Row className="mt-4">
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle><h5>Sample Codes</h5></CardTitle>
                        <Row>
                            <Col>
                                <p>Code preview were randomly generated from all possible codes. They may or may not be included in the production code list.</p>
                                {sampleCodes.map(s => <code className="px-4 text-nowrap">{s}</code> )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    }
}