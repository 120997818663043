import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, ButtonGroup, Card, CardBody, CardTitle, CustomInput, Row, Table} from "reactstrap";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import moment from "moment";
import Loading from "../../components/loading";

const ENTRY_FIELDS = [
    "name",
    "email_address",
    "mobile_number",
    "address",
    "city",
    "region",
    "country"
]

class UserList extends Component {
    state = {
        users: [],
        showButtonGroup: null,
        isUsersLoading: true
    }

    userApi = new ApiClient('user', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    componentDidMount() {
        this.userApi.list().then(response => {
            const responseData = response.data
            this.setState({
                users: [...responseData],
                isUsersLoading: false
            })
        })

    }

    renderUserRow(userData) {
        return <tr key={'user-' + userData.id}>
            <td>{userData.username}</td>
            <td>{userData.email}</td>
            <td>{userData.profile && userData.profile.description}</td>
            <td>
                <span class={"fa fa-" + (userData.is_active ? "check-circle text-success" : "times-circle text-danger")}/>
            </td>
            <td>
                {userData.additional_permission_display.join(', ')}
            </td>
            <td title={moment(userData.date_joined).format('LLLL')}>
                {moment(userData.date_joined).fromNow()}
            </td>
            <td title={userData.last_login && moment(userData.last_login).format('LLLL')}>
                {userData.last_login && moment(userData.last_login).fromNow()}
            </td>
            <td>
                <ButtonGroup size="xs">
                    <Button onClick={() => {
                        this.history.push('/app/users/' + userData.id + '/edit/')
                    }} disabled={!userData.can_edit}>
                        <span className="fa fa-pen"/>
                    </Button>
                </ButtonGroup>
            </td>
        </tr>
    }

    renderTable() {
        if (this.state.isUsersLoading) {
            return <Row>
                <Colxx className="text-center">
                    <Loading/>
                </Colxx>
            </Row>
        }
        return <Row>
            <Colxx  style={{overflow: "scroll"}}>

                <Table hover striped>
                    <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email Address</th>
                        <th>Description</th>
                        <th>Is Active</th>
                        <th>Permissions</th>
                        <th>Date Joined</th>
                        <th>Last Login</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {this.state.users.map(user => this.renderUserRow(user))}
                    </tbody>
                </Table>
            </Colxx>
        </Row>
    }

    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Button size="sm" className="float-right"  onClick={() => {
                                this.history.push('/app/users/new/')
                            }}>
                                <span className="fa fa-plus"/> New User
                            </Button>
                            <h2>Users</h2>
                        </CardTitle>

                        { this.renderTable() }
                    </CardBody>

                </Card>
            </Colxx>
        </Row>
    }
}

export default UserList