import React, {Component} from "react";
import TitleEdit from "./titleEdit";
import TitleView from "./titleView";

export default class Title extends Component {
    render() {
        if (this.props.isEditing) {
            return <TitleEdit
                title={this.props.title}
                can_edit={this.props.can_edit}
                is_shared={this.props.is_shared}
                is_active={this.props.is_active}
                is_default={this.props.is_default}
                filters={this.props.filters}

                mailTemplates={this.props.mailTemplates}
                tagNames={this.props.tagNames}
                indexes={this.props.indexes}
                canSave={this.props.canSave}

                onTitleChange={this.props.onTitleChange}
                onChangeFilter={this.props.onChangeFilter}
                onDeleteToggle={() => this.props.onToggle('is_active')}
                onPublishToggle={() => this.props.onToggle('is_shared')}
                onDefaultToggle={() => this.props.onToggle('is_default')}
                onSave={this.props.onSave}
                onCancelEdit={this.props.onCancelEdit}
                hideEmailFilters={this.props.hideEmailFilters}
                onSetDefault={this.props.onSetDefault}
            />
        } else {
            return <TitleView
                title={this.props.title}
                can_edit={this.props.can_edit}
                is_shared={this.props.is_shared}
                is_active={this.props.is_active}
                is_default={this.props.is_default}
                filters={this.props.filters}

                mailTemplates={this.props.mailTemplates}
                tagNames={this.props.tagNames}
                indexes={this.props.indexes}
                canSave={this.props.canSave}

                apexCharts={this.props.apexCharts}
                onCopy={this.props.onCopy}
                onEdit={this.props.onEdit}
                onSetDefault={this.props.onSetDefault}
            />
        }
    }
}
