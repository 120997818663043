import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../../api/ApiClient";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import Loading from "../../../components/loading";
import Chart from "react-apexcharts";
import {ReactifyChartColors} from "../../../util/ReactifyChartColors";
import {InfoCard} from "../report/InfoCard";
import ApiInfoSet from "../../../components/apiInfoSet";


const colors = ReactifyChartColors();
const perc2string = (perc) => {
    return perc > 1 ? perc.toFixed(2).toString() + "%" : "< 1%"
}
const perc2color = (perc, higherIsBetter) => {
    if (higherIsBetter) {
        if (perc > 75) {
            return "success"
        } else if (perc < 25) {
            return "warning"
        } else if (perc < 10) {
            return "danger"
        }
    } else {
        if (perc > 90) {
            return "danger"
        } else if (perc > 55) {
            return "warning"
        } else if (perc < 25) {
            return "success"
        }
    }
}

class CodePrefixUsage extends Component {
    state = {
        isLoading: true,
        hasError: false,
        data: [],
    }

    constructor(props) {
        super(props);

        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.getItemAction(this.props.promoId, 'report_prefix_breakdown').then(response => {
                this.setState({isLoading: false, hasError: false, data: response.data.prefixes})
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    renderPrefixDisbursedUsedBar(prefixes, prefixColors, prefixValues) {

        const used_codes = prefixes.map(prefix => prefixValues[prefix].count_codes_used)
        const disbursed_unused = prefixes.map(prefix => prefixValues[prefix].count_disbursed_unused)

        const options = {
            colors: prefixColors,
            chart: {
                id: "basic-line",
                type: 'bar',
                stacked: true
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: prefixes,
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: true
                            }
                        }
                    }
                }
            ]
        }
        return <Chart
            options={options}
            series={[
                {
                    name: "Disbursed but unused codes",
                    data: disbursed_unused,
                },
                {
                    name: "Used codes",
                    data: used_codes,
                }
            ]}
            type="bar"
            width="100%"
        />
    }

    renderPrefixUsedPie(prefixes, prefixColors, prefixValues) {
        const options = {
            colors: prefixColors,
            labels: prefixes,

            legend: {
                show: true
            }
        }
        const series = prefixes.map(prefix => prefixValues[prefix].count_codes_used)
        if (series.reduce((acc, val) => { acc = acc + val; return acc}, 0) === 0) {
            return <center>No used codes yet</center>
        }
        return  <Chart
            options={options}
            series={series}
            type="donut"
            width="100%"
            height="280px"
        />
    }

    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        if (this.state.data.length === 0) {
            return null
        }

        const prefixes = this.state.data.map(row => row.value)
        const prefixColors = prefixes.map((prefix, index) => {
            return colors['ReactifyChartColor' + (index + 1).toString()]
        })

        const prefixValues = this.state.data.reduce((accumulator, row) => {
            accumulator[row.value] = row
            return accumulator
        }, {})

        return <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>Prefix Breakdown</h2>
                        </CardTitle>

                        <Row>
                            <Col md={6}>
                                <h4>Prefix used vs undisbursed</h4>
                                {this.renderPrefixDisbursedUsedBar(prefixes, prefixColors, prefixValues)}
                            </Col>

                            <Col md={6}>
                                <h4>Prefix Used Codes</h4>
                                {this.renderPrefixUsedPie(prefixes, prefixColors, prefixValues)}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    }
}

export class CodeOverallInfoSet extends Component {

    state = {
        isLoading: true,
        hasError: false,
        data: {},
    }

    constructor(props) {
        super(props);

        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.setState({isLoading: true, hasError: false}, () => {
            this.promoApi.getItemAction(this.props.promoId, 'report_code_usage').then(response => {
                this.setState({isLoading: false, hasError: false, data: response.data})
            }).catch(() => {
                this.setState({isLoading: false, hasError: true})
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }
    render() {
        if (this.state.isLoading) {
            return <Row>
                <Col className="text-center">
                    <Loading/>
                </Col>
            </Row>
        }
        const countTotalDisbursed = this.state.data.count_used + this.state.data.count_disbursed_unused;
        const countTotalCodes = countTotalDisbursed + this.state.data.count_undisbursed;
        const usedCodePercentage = this.state.data.count_used/countTotalDisbursed * 100
        const usedCodePercentageString = perc2string(usedCodePercentage)
        const disbursedPercentage = this.state.data.count_disbursed_unused / countTotalCodes * 100;
        const undisbursedPercentage = this.state.data.count_undisbursed / countTotalCodes * 100

        if (this.props.showDigestOnly) {
            return <ApiInfoSet data={{
                'Used Codes': <>
                    {this.state.data.count_used.toLocaleString()}
                </>,
                "Disbursed unused codes": <>
                    {this.state.data.count_disbursed_unused.toLocaleString()}
                </>,
                "Available for disbursement": <>
                    {this.state.data.count_undisbursed.toLocaleString()}
                </>,
            }}/>
        }
        return <ApiInfoSet data={{
            'Used Codes\n(% of disbursed)': <>
                {this.state.data.count_used.toLocaleString()}
                <br/><small className={"text-" + perc2color(usedCodePercentageString, false)}>({usedCodePercentageString})</small>
            </>,
            "Disbursed unused codes\n(% of total codes)": <>
                {this.state.data.count_disbursed_unused.toLocaleString()}
                <br/><small >({perc2string(disbursedPercentage)})</small>
            </>,
            "Available for disbursement\n(% of total codes)": <>
                {this.state.data.count_undisbursed.toLocaleString()}
                <br/><small className={"text-" + perc2color(undisbursedPercentage, true)}>({perc2string(undisbursedPercentage)})</small>
            </>,
            "Added freeform codes": this.state.data.count_added,
            "Used codes from old or invalid prefix or code definitions": this.state.data.count_used_old_codes
        }}/>
    }
}

export default class CodeReport extends Component {
    state = {
        title: ''
    }

    constructor(props) {
        super(props);

        const {match} = this.props;
        this.promoId = match.params.promoID

        this.promoApi = new ApiClient('promo', authenticationErrorHandler.bind(this))
    }

    loadData() {
        this.promoApi.getItem(this.promoId).then(response => {
            this.setState({title: response.data.title})
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return <>

            <h1>{this.state.title} Code Report</h1>
            <CodeOverallInfoSet promoId={this.promoId}/>
            <CodePrefixUsage promoId={this.promoId}/>
        </>
    }


}