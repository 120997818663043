import React, {Component} from "react";
import {Button, Card, Col, Row} from "reactstrap";
import {CustomizableChart} from "../../../routes/mail/report/customizableChart";
import Loading from "../../loading";

export default class ChartRow extends Component {
    renderChart(config) {
        return <Col lg={config.width} className="my-2 d-flex">
            <Card className="flex-fill">
                <CustomizableChart
                    hideEmailFilters={this.props.hideEmailFilters}
                    id={config.id}
                    key={config.id.toString() + "|" + (this.props.isEditing ? 'edit' : 'view')}

                    mailTemplates={this.props.mailTemplates}
                    tagNames={this.props.tagNames}
                    indexes={this.props.indexes}

                    config={config}
                    onConfigChange={updatedChartConfig => this.props.onChartChange(config.id, updatedChartConfig)}
                    onDelete={() => {
                        this.props.onDeleteChart(config.id)
                    }}
                    edit={this.props.isEditing}
                    dataApi={this.props.api}
                    filters={this.props.filters}
                />
            </Card>
        </Col>
    }

    renderRowContent() {
        if (this.props.isLoading) {
            return <Col className={"text-center"}>
                <Loading/>
            </Col>
        }
        const row = this.props.row
        const occupiedWidth = row.reduce((partialSum, a) => partialSum + a.width, 0)

        const currentMaxRowPosition = Math.max(...row.map(chart => chart.row_position))
        const rowChartRenders = row.sort((a, b) => {
            if (a.row_position < b.row_position) {
                return -1
            }
            if (a.row_position > b.row_positions) {
                return 1
            }
            return 0
        }).map(item => this.renderChart(item))

        if (this.props.isEditing) {
            return <>
                {rowChartRenders}
                {
                    occupiedWidth < 12 &&
                    <Col className="my-2 d-flex text-center align-items-center justify-content-center">
                        <Button onClick={() => this.props.onAddChart(currentMaxRowPosition)}>
                            <span className="fa fa-plus-circle"/>{" "}
                            Add Chart
                        </Button>
                    </Col>
                }

            </>
        } else {
            return rowChartRenders
        }
    }

    render() {
        const row = this.props.row;


        if (this.props.isEditing) {

            return <>
                <Row className='border-1px p-2 my-2 border-primary'>
                    <Col xs={12} sm={12} className="d-flex text-center align-items-center justify-content-center">
                        {
                            row[0].row !== 0 && <Button
                                size="xs"
                                className="w-50"
                                onClick={this.props.onMoveUp}>
                                <span className="fa fa-arrow-up"/> Move Up
                            </Button>
                        }
                    </Col>

                    {this.renderRowContent(row)}

                    <Col xs={12} sm={12} className="d-flex text-center align-items-center justify-content-center">
                        {
                            row[0].row !== this.props.maxRow && <Button
                                size="xs"
                                className="w-50"
                                onClick={this.props.onMoveDown}>
                                <span className="fa fa-arrow-down"/> Move Down
                            </Button>
                        }
                    </Col>
                </Row>
                <Row className='border-1px p-2 my-2'>
                    <Col className="my-2 d-flex text-center align-items-center justify-content-center">
                        <Button onClick={this.props.onInsertRowAfter}>
                            <span className="fa fa-plus-circle"/>{" "}
                            Insert Chart
                        </Button>
                    </Col>
                </Row>
            </>

        } else {

            return <Row>
                {this.renderRowContent(row)}
            </Row>
        }
    }
}