import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import layout from "./layout";

export default ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/layout`} />
    <Route path={`${match.url}/layout`} component={layout} />
    <Redirect to="/error" />
  </Switch>
);
