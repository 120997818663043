import React, {Component} from "react";
import {ReactifyChartColors} from "../../util/ReactifyChartColors";
import Chart from "react-apexcharts";

const colors = ReactifyChartColors();

class AreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: props.id,
                    stacked: true,
                    toolbar: {
                        export: {
                            svg: {
                                filename: props.title
                            },
                            png: {
                                filename: props.title
                            }
                        }
                    }
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        datetimeUTC: false
                    }
                },
                legend: {
                    show: true,
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                    showForSingleSeries: true,
                    formatter: (seriesName, opts) => {
                        return [
                            seriesName,
                            " - ",
                            opts.w.globals.series[opts.seriesIndex].reduce((acc, v) => {acc += v; return acc;}, 0).toLocaleString()
                        ]
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        }
                    }
                }
            ],
            series: [
                {
                    data: [
                        {
                            x: "11-15-2021 Pht",
                            y: 2,
                        },
                        {
                            x: "11-16-2021 Pht",
                            y: 1
                        },
                        {
                            x: "11-17-2021 Pht",
                            y: 1
                        },
                        {
                            x: "02-05-2022 Pht",
                            y: 2
                        },
                        {
                            x: "02-09-2022 Pht",
                            y: 4
                        },
                        {
                            x: "02-18-2022 Pht",
                            y: 1
                        }
                    ],
                    id: 1,
                    name: "Test Promo"
                },

                {
                    data: [
                        {
                            x: "11-15-2021 Pht",
                            y: 2,
                        },
                        {
                            x: "11-16-2021 Pht",
                            y: 1
                        },
                        {
                            x: "11-17-2021 Pht",
                            y: 1
                        },
                        {
                            x: "02-05-2022 Pht",
                            y: 2,
                            z: 10
                        },
                        {
                            x: "02-09-2022 Pht",
                            y: 6
                        },
                        {
                            x: "02-18-2022 Pht",
                            y: 5
                        }
                    ],
                    id: 1,
                    name: "X"
                }
            ]
        };
    }

    render() {
        const seriesData = this.props.series ? this.props.series : this.state.series
        const allSeriesXPoints = seriesData.map(series => series.data.map(point => point.x))
            .reduce((acc, val) => acc.concat(val), [])  // flatten
            .sort()
        const filledSeriesData = seriesData.map(series => {
            const seriesXMap = series.data.reduce((acc, point) => {
                acc[point.x] = point.y
                return acc
            }, {})

            return {
                ...series,
                data: [
                    ...allSeriesXPoints.map(x => {
                        return {x: x, y: seriesXMap.hasOwnProperty(x) ? seriesXMap[x] : 0}
                    })
                ]
            }
        })

        return (
            <Chart
                options={{
                    ...this.state.options,

                    noData: {
                        text: this.props.hasError ? 'Error loading!' : (this.props.isLoading ? 'Loading ...' : 'No Data')
                    },
                    title: {
                        text: this.props.title
                    }
                }}
                series={filledSeriesData}
                type="area"
                width="100%"
                height="100%"
            />
        );
    }
}

export default AreaChart;
