import React, { Component } from "react";
import { LineChart, Line, Tooltip, XAxis } from "recharts";
const data = [
  { name: "Visitors & Clicks", Visitors: 4000, Clicks: 400 },
  { name: "Visitors & Clicks", Visitors: 3000, Clicks: 398 },
  { name: "Visitors & Clicks", Visitors: 2000, Clicks: 100 },
  { name: "Visitors & Clicks", Visitors: 2780, Clicks: 908 },
  { name: "Visitors & Clicks", Visitors: 1890, Clicks: 800 },
  { name: "Visitors & Clicks", Visitors: 2390, Clicks: 800 },
  { name: "Visitors & Clicks", Visitors: 3490, Clicks: 900 }
];

class TinyLineChart extends Component {
  state = {};
  render() {
    return (
      <div>
        <LineChart width={130} height={70} data={data}>
          <Line
            type="monotone"
            dataKey="Visitors"
            strokeWidth={2}
            dot={false}
            activeDot={false}
            name={NumberMask}
          />
          <XAxis dataKey="name" hide />
          <Tooltip formatter={NumberMask} />
          <Line
            type="monotone"
            dataKey="Clicks"
            stroke="#82ca9d"
            strokeWidth={2}
            dot={false}
            activeDot={false}
            name={NumberMask}
          />
        </LineChart>
      </div>
    );
  }
}

const NumberMask = value => (
  <div>
    <div>
      <span className="fa fa-street-view" /> {value}
    </div>
  </div>
);

export default TinyLineChart;
