import React, {Component} from "react";
import {Card, CardBody} from "reactstrap";
import Loading from "../../../components/loading";

export class InfoCard extends Component {
    renderValue() {
        if (this.props.hasError) {
            return <p className="text-muted mb-2 text-danger" style={{lineHeight: "2.5rem"}}>Error!</p>
        } else {
            return <p className="lead text-muted bold">
                {typeof (this.props.value) === "object" ? this.props.value : this.props.value.toLocaleString()}
            </p>
        }
    }

    renderIcon() {
        const color = this.props.iconColor ? this.props.iconColor : 'text-success'
        if (this.props.icon) {
            return <i className={"float-left fa fa-" + this.props.icon + " " + color + " mt-1 font18px"}/>
        }
    }

    render() {
        return <Card className="mb-4 text-center flex-fill">
            <CardBody>
                {this.renderIcon()}

                <div className={"float-right position-absolute"} style={{right: '1em', top: '1em'}}>
                    {this.props.isLoading && <Loading/>}
                </div>
                    {this.renderValue()}
                    <p className="text-muted font-weight-semibold mb-2">
                        {
                            this.props.title.split('\n')
                                .map((titleItem, i, arr) => <>{titleItem} {arr.length > i + 1 ? <br/> : null}</>)
                        }
                    </p>
            </CardBody>
        </Card>
    }
}
