import React, {Component} from "react";
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import TrackingApiValue from "./trackingApiValue";


export default class TrackingApi extends Component {
    state = {
        showHidden: false
    }
    render() {
        const points = this.props.points.sort((a,b) => {
            if (a.value > b.value) return 1
            if (a.value < b.value) return -1
            return 0
        })
        const hiddenPoints = points.filter(point => !point.is_active)
        const untaggedPoints = points.filter(point => point.is_active && point.tag_ids.length === 0)
        const taggedPoints = points.filter(point => point.is_active && point.tag_ids.length > 0)
        return <Row className="mt-4">
            <Col>
            <Card>
                <CardBody>
                    <CardTitle>
                        <h3>API: {this.props.name}</h3>
                        <small className="text-muted">UID: {this.props.uid}</small>
                    </CardTitle>


                    {
                        untaggedPoints.length > 0 && <h6 className="mt-4">Untagged Tracking Points</h6>
                    }
                    {
                        untaggedPoints.map(point => <TrackingApiValue
                            api={this.props.api}
                            key={point.id}
                            id={point.id}
                            value={point.value}
                            isActive={point.is_active}
                            tags={point.tag_ids}
                            tagDefinitions={this.props.tagDefinitions}
                            onUpdatePoint={this.props.onUpdatePoint}
                        />)
                    }

                    {
                        taggedPoints.length > 0 && <h6 className="mt-4">Tagged Tracking Points</h6>
                    }
                    {
                        taggedPoints.map(point => <TrackingApiValue
                            api={this.props.api}
                            key={point.id}
                            id={point.id}
                            value={point.value}
                            isActive={point.is_active}
                            tags={point.tag_ids}
                            tagDefinitions={this.props.tagDefinitions}
                            onUpdatePoint={this.props.onUpdatePoint}
                        />)
                    }


                    {
                        hiddenPoints.length > 0 && <h6 className="mt-4">
                            Hidden Tracking Points <span className="text-muted">({hiddenPoints.length})</span>
                            <Button size="xs" color="outline-info" className="float-right" onClick={() => {
                                this.setState(prevState => ({...prevState, showHidden: !prevState.showHidden}))
                            }}>
                                {this.state.showHidden ? "Close" : "View"} Hidden Tracking Points
                            </Button>
                        </h6>
                    }
                    {
                        this.state.showHidden && hiddenPoints.map(point => <TrackingApiValue
                            api={this.props.api}
                            key={point.id}
                            id={point.id}
                            value={point.value}
                            isActive={point.is_active}
                            tags={point.tag_ids}
                            tagDefinitions={this.props.tagDefinitions}
                            onUpdatePoint={this.props.onUpdatePoint}
                        />)
                    }
                </CardBody>
            </Card>
            </Col>
        </Row>
    }
}