import React, {Component} from "react";
import {Button, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {toast} from "react-toastify";

export default class InputCopy extends Component {
    render() {
        return <InputGroup>
            {
                this.props.prepend && <InputGroupAddon addonType="prepend">
                    <InputGroupText className="text-small">{this.props.prepend}</InputGroupText>
                </InputGroupAddon>
            }
            <Input
                type="text" readOnly={true} value={this.props.value}
                size="lg" style={{textOverflow: "ellipsis"}}/>
            <InputGroupAddon addonType="append">
                <Button
                    color="success" size="xs"
                    onClick={() => {
                        navigator.clipboard.writeText(this.props.value);
                        toast(<>
                            {this.props.toastText ? this.props.toastText : "Copied to clipboard"}
                            <br/>
                            {this.props.value}
                        </>)
                    }}>
                    <span className="fa fa-clipboard"/> copy
                </Button>
            </InputGroupAddon>
        </InputGroup>
    }
}