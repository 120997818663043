import React, {Component} from "react";
import ApiClient, {authenticationErrorHandler} from "../../api/ApiClient";
import {Row} from "reactstrap";
import {Colxx} from "../CustomBootstrap";
import moment from "moment";


export default class DownloadToast extends Component {
    downloadApi = new ApiClient('download', authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = {
                ...props.data,
                intervalId: null
            }
        } else {
            this.state = {
                progress: 0,
                is_complete: false,
                url: "",
                intervalId: null
            }
        }
    }

    componentDidMount() {
        if (!this.state.is_complete) {
            const intervalId = window.setInterval(this.fetchStatus.bind(this), 2000)
            this.setState({intervalId: intervalId})
        }
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId)
        }
    }

    fetchStatus() {
        console.log("FetchStatus", this.props.downloadId)
        if (this.props.downloadId) {
            this.downloadApi.getItem(this.props.downloadId).then(response => {
                if (response.data.is_complete && this.state.intervalId) {
                    window.clearInterval(this.state.intervalId);
                    this.setState({intervalId: null})
                }
                this.setState({...response.data})
            })
        }
    }

    downloadFile() {
        this.downloadApi.get(this.state.url).then(response => {
            window.open(response.data.url, '_blank')
        })
    }

    renderStatus() {
        if (this.state.is_complete && this.state.url) {
            return <a onClick={() => {
                // window.open(this.state.url, '_blank')
                // this.fetchStatus()
                this.downloadFile()
            }} href="javascript:void(0);">
                Generation complete.{" "}

                {this.state.download_count <= 0 ? <>
                    <strong>Download file now</strong> <i className="fa fa-external-link-alt"></i>.
                </> : <>
                    Download again? <i className="fa fa-external-link-alt"></i>
                </>}
            </a>
        }

        return <span>
            Generating entry archive for download. {!!this.state.progress && this.state.progress.toString() + "% complete"}
        </span>
    }

    render() {
        if (this.props.showDetails) {
            return <>
                <Row>
                    <Colxx>
                        Download for {this.state.total_count} entries requested.{" "}
                        {/*<Colxx>*/}
                        {/*    {this.state.download_count ? <span>Downloaded {this.state.download_count} times</span>: <i>Not downloaded yet.</i>}*/}
                        {/*</Colxx>*/}
                        {this.renderStatus()}
                    </Colxx>
                    <Colxx lg={2} md={12} className="text-right">
                        <small>{moment(this.state.datetime_generated).fromNow()}</small>
                    </Colxx>
                </Row>
            </>
        }
        return this.renderStatus()
    }
}